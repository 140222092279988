import React, { useRef, useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as purchaseOrderActions from '../../../../../../../actions/purchaseOrderActions';
import { 
        Button,
        Typography,
        Paper,
        Box,
        Grid,
        TextField,
        Autocomplete,
        Alert,
        Tooltip,
        CircularProgress,
        InputAdornment
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {HelpCenterTwoTone} from '@mui/icons-material';
import NumberFormat from 'react-number-format';
import Variant from './Variant';


const QuantityFormat = React.forwardRef((props, ref) => (
    <NumberFormat
        {...props.other}
        onValueChange={(values) => {
          props.onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        customInput={TextField}
        placeholder={props.placeholder}
        value={props.value}
        helperText={props.helperText}
        fullWidth={props.fullWidth}
        error={props.error}
        thousandSeparator={"."}
        decimalSeparator={","}
        allowNegative={false}
        decimalScale={2}
        isNumericString
        InputProps={{
            ...props.InputProps, // Merge existing InputProps from props
            // startAdornment: (
            //   <InputAdornment position="start">
            //     <Box fontWeight={700}>Rp</Box>
            //   </InputAdornment>
            // ),
            inputProps: {
              ...props.InputProps?.inputProps, // Merge existing inputProps if present
              style: {
                ...(props.InputProps?.inputProps?.style || {}), // Merge existing styles
                textAlign: 'right', // Add or override the text alignment
              },
            },
        }}
      />
));
function ProductItem({options, item, onChange, onRemove, index, error}) {
    const [open, setOpen] = React.useState(false);

    const productOption = options.find(product => product.sku === item.sku);
    const handleClose = () => {
      setOpen(false);
    };
  
    const handleOpen = () => {
      setOpen(true);
    };

    function itemOptions(options) {
        return (
            <Box>
                <Typography fontWeight={700}>
                    Produk Opsi
                </Typography>
                {options.map((option, index) => {
                    return (
                        <Box key={index} sx={{display: 'flex'}}>
                            <Typography fontWeight={500}>
                                {option.name} 
                            </Typography>
                            <Typography sx={{marginLeft: '5px'}}>
                                {option.unit ? `(${option.unit})` : null}
                            </Typography>
                        </Box>
                    )
                })}
            </Box>
        )
    }

    function hasOptions() {
        if (productOption && productOption.product_options) {
            var optionsKeys = [];
            productOption.product_options.sort((a, b) => {
                const nameA = a.sort_order;
                const nameB = b.sort_order;
                if (nameA < nameB) {
                return -1;
                }
                if (nameA > nameB) {
                return 1;
                }
            
                // names must be equal
                return 0;
            }).map((option, index) => {
                if (!optionsKeys.includes(option.uom.toLowerCase())) {
                    optionsKeys.push(option.uom.toLowerCase());
                }
            })

            var options = [];
            optionsKeys.map((key, index)  => {
                const option = productOption.product_options.find(opt => opt.sort_order === index);
                if (index === 0) {
                    options.push({name: option.uom, unit: ""});
                } else {
                    const firstOption = productOption.product_options.find(opt => opt.sort_order === 0);
                    options.push({name: option.uom, unit: option.unit + " " + firstOption.uom});
                }
            })

            if (options.length >= 2) {
                return (
                    <Tooltip sx={{position: 'absolute', right: 10, top: 32}} open={open} onClose={handleClose} onOpen={handleOpen} title={itemOptions(options)}>
                        <HelpCenterTwoTone color="primary" />
                    </Tooltip>
                )
            }
            
            return null;
        }

        return null;
    }

    return (
        <Grid item container spacing={2}>
            <Grid item xs sx={{position: 'relative'}}>
                <Autocomplete
                    options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.name}
                    blurOnSelect
                    freeSolo
                    disabled
                    fullWidth
                    inputValue={item.name}
                    disableClearable
                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                />
                {hasOptions()}
            </Grid>
            <Grid item xs={2}>
                <TextField
                    fullWidth
                    value={item.in_stock}
                    disabled
                    variant="outlined"
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{item.uom}</InputAdornment>,
                        inputProps: {
                            style: {
                              textAlign: 'right', // Align text to the right
                            },
                        },
                    }}
                />
            </Grid>
            <Grid item xs={2}>
                <QuantityFormat
                    fullWidth
                    error={!!error(`purchase_order_items[${index}].requested_qty`)}
                    helperText={error(`purchase_order_items[${index}].requested_qty`)}
                    name="requested_qty"
                    value={item.requested_qty}
                    variant="outlined"
                    onChange={onChange}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{item.uom}</InputAdornment>,
                        inputProps: {
                            style: {
                              textAlign: 'right', // Align text to the right
                            },
                        },
                    }}
                />
            </Grid>
            <Grid item container xs={3} spacing={2}>
                <Grid item xs>
                    <TextField
                        fullWidth
                        value={Number(item.in_stock) + Number(item.requested_qty)}
                        disabled
                        variant="outlined"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{item.uom}</InputAdornment>,
                            inputProps: {
                                style: {
                                  textAlign: 'right', // Align text to the right
                                },
                            },
                        }}
                    />
                </Grid>
                <Grid item>
                    <ClearButton 
                        variant="contained"
                        size="large"
                        disableElevation
                        onClick={onRemove}
                        >
                        Hapus
                    </ClearButton>
                </Grid>
                
            </Grid>
        </Grid>
    )
}

export default function Product() {
    const selectRef = useRef();
    const dispatch = useDispatch();
    const {purchase_order, products, initial_products, inventories, errors, selectedProduct, productsFetching} = useSelector(state => ({
        ...state.purchase_order
    }), shallowEqual); 

    const { purchase_order_items } = purchase_order;

    useEffect(() => {
        if (selectedProduct) {
            if (selectedProduct.product_variants) {
                dispatch(purchaseOrderActions.onHandleVariants(selectedProduct._id));
            } else if (selectedProduct.product_options) {
                const inventory_product = inventories.find(i => i.sku.toString() === selectedProduct.sku.toString());
                let newOrderItems = [...purchase_order_items];
                const primaryOption = selectedProduct.product_options.find(opt => Number(opt.sort_order) === 0);
                if (inventory_product) {
                    const in_stock = inventory_product.in_stock;
                    if (purchase_order_items[0].product_id && purchase_order_items.length >= 1) {
                        newOrderItems = [...newOrderItems, {product_id: selectedProduct._id, name: selectedProduct.name, sku: selectedProduct.sku, uom: primaryOption.uom, in_stock, requested_qty: 0}]
                    } else {
                        newOrderItems[0] = {...newOrderItems[0], product_id: selectedProduct._id, name: selectedProduct.name, sku: selectedProduct.sku, uom: primaryOption.uom, in_stock, requested_qty: 0 }
                    }
                } else {
                    if (purchase_order_items[0].product_id && purchase_order_items.length >= 1) {
                        newOrderItems = [...newOrderItems, {product_id: selectedProduct._id, name: selectedProduct.name, sku: selectedProduct.sku, uom: primaryOption.uom, in_stock: 0, requested_qty: 0 }]
                    } else {
                        newOrderItems[0] = {...newOrderItems[0], product_id: selectedProduct._id, name: selectedProduct.name, sku: selectedProduct.sku, uom: primaryOption.uom, in_stock: 0, requested_qty: 0 }
                    }
                }
                dispatch(purchaseOrderActions.onSelectedProductOptions(newOrderItems, selectedProduct._id));
            } else {
                const inventory_product = inventories.find(i => i.sku.toString() === selectedProduct.sku.toString());
                let newOrderItems = [...purchase_order_items];
                if (inventory_product) {
                    const in_stock = inventory_product.in_stock;
                    if (purchase_order_items[0].product_id && purchase_order_items.length >= 1) {
                        newOrderItems = [...newOrderItems, {product_id: selectedProduct._id, name: selectedProduct.name, sku: selectedProduct.sku, uom: selectedProduct.uom, in_stock, requested_qty: 0}]
                    } else {
                        newOrderItems[0] = {...newOrderItems[0], product_id: selectedProduct._id, name: selectedProduct.name, sku: selectedProduct.sku, uom: selectedProduct.uom, in_stock, requested_qty: 0 }
                    }
                } else {
                    if (purchase_order_items[0].product_id && purchase_order_items.length >= 1) {
                        newOrderItems = [...newOrderItems, {product_id: selectedProduct._id, name: selectedProduct.name, sku: selectedProduct.sku, uom: selectedProduct.uom, in_stock: 0, requested_qty: 0 }]
                    } else {
                        newOrderItems[0] = {...newOrderItems[0], product_id: selectedProduct._id, name: selectedProduct.name, sku: selectedProduct.sku, uom: selectedProduct.uom, in_stock: 0, requested_qty: 0 }
                    }
                }
                dispatch(purchaseOrderActions.onSelectedProductOptions(newOrderItems, selectedProduct._id));
            }
        }
    },[selectedProduct, inventories, purchase_order_items]);

    function onSelectProduct(value) {
        const product = products.find(item => item._id === value);
        if (product.loaded) {
            if (product.product_variants) {
                dispatch(purchaseOrderActions.onHandleVariants(value));
            } else if (product.product_options) {
                const inventory_product = inventories.find(i => i.sku.toString() === product.sku.toString());
                let newOrderItems = [...purchase_order_items];
                const primaryOption = product.product_options.find(opt => Number(opt.sort_order) === 0);
                if (inventory_product) {
                    const in_stock = inventory_product.in_stock;
                    if (purchase_order_items[0].product_id && purchase_order_items.length >= 1) {
                        newOrderItems = [...newOrderItems, {product_id: value, name: product.name, sku: product.sku, uom: primaryOption.uom, in_stock, requested_qty: 0}]
                    } else {
                        newOrderItems[0] = {...newOrderItems[0], product_id: value, name: product.name, sku: product.sku, uom: primaryOption.uom, in_stock, requested_qty: 0 }
                    }
                } else {
                    if (purchase_order_items[0].product_id && purchase_order_items.length >= 1) {
                        newOrderItems = [...newOrderItems, {product_id: value, name: product.name, sku: product.sku, uom: primaryOption.uom, in_stock: 0, requested_qty: 0 }]
                    } else {
                        newOrderItems[0] = {...newOrderItems[0], product_id: value, name: product.name, sku: product.sku, uom: primaryOption.uom, in_stock: 0, requested_qty: 0 }
                    }
                }
                dispatch(purchaseOrderActions.onSelectedProductOptions(newOrderItems, value));
            } else {
                const inventory_product = inventories.find(i => i.sku.toString() === product.sku.toString());
                let newOrderItems = [...purchase_order_items];
                if (inventory_product) {
                    const in_stock = inventory_product.in_stock;
                    if (purchase_order_items[0].product_id && purchase_order_items.length >= 1) {
                        newOrderItems = [...newOrderItems, {product_id: value, name: product.name, sku: product.sku, uom: product.uom, in_stock, requested_qty: 0}]
                    } else {
                        newOrderItems[0] = {...newOrderItems[0], product_id: value, name: product.name, sku: product.sku, uom: product.uom, in_stock, requested_qty: 0 }
                    }
                } else {
                    if (purchase_order_items[0].product_id && purchase_order_items.length >= 1) {
                        newOrderItems = [...newOrderItems, {product_id: value, name: product.name, sku: product.sku, uom: product.uom, in_stock: 0, requested_qty: 0 }]
                    } else {
                        newOrderItems[0] = {...newOrderItems[0], product_id: value, name: product.name, sku: product.sku, uom: product.uom, in_stock: 0, requested_qty: 0 }
                    }
                }
                dispatch(purchaseOrderActions.onSelectedProductOptions(newOrderItems, value));
            }
        } else {
            dispatch(purchaseOrderActions.onSelectProduct(value));
        }
    }


    function onUpdateField(index, event) {
		const key = event.target.name;
        const value = event.target.value;
        const newPurchaseOrder = {...purchase_order};
        newPurchaseOrder.purchase_order_items[index][key] = value;
        dispatch(purchaseOrderActions.onUpdatePurchaseOrder(newPurchaseOrder));
    }

    function onRemoveProduct(item, index) {
        const newPurchaseOrder = {...purchase_order};
        newPurchaseOrder.purchase_order_items.splice(index, 1)
    	if (item && item.product_id) {
            dispatch(purchaseOrderActions.onRestoreProductOptions(products, item.product_id, item.sku));
    	}
    	if (purchase_order_items.length === 0) {
    		newPurchaseOrder.purchase_order_items = [...newPurchaseOrder.purchase_order_items, { product_id: '', name: '', sku: '', in_stock: 0, requested_qty: 0 }]
    		dispatch(purchaseOrderActions.onUpdatePurchaseOrder(newPurchaseOrder));
    	}
    	
    }

    function error(key) {
        if (errors != null && errors[`purchase_order.${key}`] != null) {
            return errors[`purchase_order.${key}`].msg
        }
        return null;
    }

    const options = products.map((option) => {
        const firstLetter = option.name[0].toUpperCase();
        return {
          firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
          ...option,
        };
    });

    const initialOptions = initial_products.map((option) => {
        const firstLetter = option.name[0].toUpperCase();
        return {
          firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
          ...option,
        };
    });
   
    return (
        <ContentPaper elevation={3}>
            <Grid container spacing={6} direction="column">
                <Grid item container>
                    <Grid item xs>
                        <Typography fontWeight={700} variant="h6" lineheight="normal">
                            Informasi Produk
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Alert severity="error">
                            Dibutuhkan
                        </Alert>
                    </Grid>
                </Grid>
                <Grid item container direction="column" spacing={3}>
                    <Grid item container spacing={2}>
                        <Grid item xs>
                            <Typography lineheight="normal" component="div">
                                <Box fontSize={14} fontWeight={500}>
                                    Nama Produk
                                </Box>
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography lineheight="normal" textAlign={"right"} component="div">
                                <Box fontSize={14} fontWeight={500}>
                                    Stok Awal
                                </Box>
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography lineheight="normal" textAlign={"right"} component="div">
                                <Box fontSize={14} fontWeight={500}>
                                    Qty Pembelian
                                </Box>
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography lineheight="normal" textAlign={"right"} component="div">
                                <Box fontSize={14} fontWeight={500}>
                                    Stok Akhir (Est.)
                                </Box>
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                        </Grid>
                    </Grid>
                    {
                        purchase_order_items.map((item, index) => {
                            if (item.product_id) {
                                return (
                                    <ProductItem
                                        key={index}
                                        options={initialOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                                        item={item}
                                        onChange={onUpdateField.bind(this, index)}
                                        onRemove={onRemoveProduct.bind(this, item, index)}
                                        index={index}
                                        error={error}
                                        />
                                )
                            }
                            return null;
                        })
                    }
                    <Grid item container spacing={2}>
                        <Grid item xs>
                            <Autocomplete
                                options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                                groupBy={(option) => option.firstLetter}
                                getOptionLabel={(option) => option.name}
                                blurOnSelect
                                disabled={productsFetching}
                                fullWidth
                                value={null}
                                disableClearable
                                getOptionDisabled={(option) =>
                                    !option.status
                                }
                                onChange={(event, option) => {
                                    onSelectProduct(option._id)
                                }}
                                renderInput={(params) => <TextField {...params} 
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                    <React.Fragment>
                                        {productsFetching ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                    ),
                                }}
                                error={!purchase_order_items[0].product_id && !!error(`purchase_order_items[0].product_id`)} helperText={!purchase_order_items[0].product_id && error(`purchase_order_items[0].product_id`)} ref={selectRef} placeholder="Pilih Produk" variant="outlined" />}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                name="in_stock"
                                value={0}
                                disabled
                                variant="outlined"
                                InputProps={{
                                    inputProps: {
                                      style: {
                                        textAlign: 'right', // Align text to the right
                                      },
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                name="in_stock"
                                value={0}
                                disabled
                                error={!purchase_order_items[0].product_id && !!error(`purchase_order_items[0].requested_qty`)}
                                helperText={!purchase_order_items[0].product_id && error(`purchase_order_items[0].requested_qty`)}
                                variant="outlined"
                                InputProps={{
                                    inputProps: {
                                      style: {
                                        textAlign: 'right', // Align text to the right
                                      },
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item container xs={3} spacing={2}>
                            <Grid item xs>
                                <TextField
                                    fullWidth
                                    name="in_stock"
                                    value={0}
                                    disabled
                                    variant="outlined"
                                    InputProps={{
                                        inputProps: {
                                          style: {
                                            textAlign: 'right', // Align text to the right
                                          },
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <ClearButton 
                                    variant="contained"
                                    size="large"
                                    disableElevation
                                    disabled
                                    >
                                    Hapus
                                </ClearButton>
                            </Grid>
                            
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Variant/>
        </ContentPaper>
    );
}


const ContentPaper = styled(Paper)(({theme}) => ({
    padding: 35,
    paddingTop: 32,
    marginBottom: 25
}));

const ClearButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: `1px solid #ddd`,
    height: 56
}));
