import React from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as purchaseOrderActions from '../../../../../../actions/purchaseOrderActions';
import { 
        Typography,
        Box,
        Paper,
        Grid,
        TextField,
        Divider
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { PublishedWithChangesTwoTone, AccessTimeTwoTone, LocalShippingTwoTone } from '@mui/icons-material';
import { green, red, grey } from '@mui/material/colors';
import moment from 'moment';
import 'moment/locale/id' 

export default function Information({error}) {    
    const dispatch = useDispatch();
    const {purchase_order} = useSelector(state => ({
        ...state.purchase_order
    }), shallowEqual); 
    const { increment_id, 
            invoice_id, 
            status, 
            status_title,
            store_name, 
            created_at, 
            due_date,
            supplier_name } = purchase_order;


    function onUpdateField(event) {
		const key = event.target.name;
        const value = event.target.value;
        const newPurchaseOrder = {...purchase_order};
        newPurchaseOrder[key] = value;
        dispatch(purchaseOrderActions.onUpdatePurchaseOrder(newPurchaseOrder));
    }

    const onSelectDate = (date) => {
        const newPurchaseOrder = {...purchase_order};
        newPurchaseOrder.due_date = date;
        dispatch(purchaseOrderActions.onUpdatePurchaseOrder(newPurchaseOrder));
    };

    function onShowActivities() {
        dispatch(purchaseOrderActions.onShowActivities());
    }

    function Invoice() {
        if (status === 'ordered') {
            return (
                <TextField
                    fullWidth
                    error={!!error('invoice_id')}
                    helperText={error('invoice_id')}
                    name="invoice_id"
                    value={invoice_id}
                    variant="outlined"
                    placeholder="No. Invoice"
                    onChange={onUpdateField}
                />
            )
        }

        return (
            <Box>
                {invoice_id ? invoice_id : "-"}
            </Box>
          
        )
    }

    function dueDays() {
        if (Math.round((new Date(due_date).setHours(0,0,0,0) - new Date().setHours(0,0,0,0))) === 0) {
            return `(Tunai)`
        } else if (due_date) {
           return `(${Math.round((new Date(due_date).setHours(0,0,0,0) - new Date().setHours(0,0,0,0)) / (1000 * 60 * 60 * 24))} Hari)`
        }

        return null;
    }

    function DueDate() {
        if (status === 'ordered' ) {
            return (
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                        openTo="year"
                        disablePast
                        value={due_date}
                        format="dd/MM/yyyy"
                        onChange={onSelectDate}
                        renderInput={(params) => <TextField fullWidth {...params} />}
                    />
                </LocalizationProvider>
            )
        }

        return (
            <Box>
                {due_date ? moment(due_date).format('LL') : "-"}
            </Box>
            
        )
    }
    return (
        <ContentPaper elevation={3}>
            <Box sx={{display: 'flex', padding: '35px', paddingTop: '25px', paddingBottom: '25px'}}>
                <Typography fontWeight={700} sx={{display: 'flex'}}>
                <PublishedWithChangesTwoTone style={{ fontSize: 18, marginRight: '5px', marginTop: '3px' }} color="primary" /> {status_title}
                </Typography>
                <Slash>
                    /
                </Slash>
                <Typography fontWeight={700} color="primary">
                    {increment_id}
                </Typography>
                 <Slash>
                    /
                </Slash>
                <Typography fontWeight={700}>
                    {supplier_name}
                </Typography>
                <Slash>
                    /
                </Slash>
                <Typography sx={{display: 'flex'}}>
                    <AccessTimeTwoTone style={{ fontSize: 18, marginRight: '5px', marginTop: '3px' }} color="primary" /> {moment(created_at).format('LL')}, {moment(created_at).format('LT')} WIB
                </Typography>
            </Box>
            <Box>
                <Divider />
            </Box>
            <Box sx={{padding: '35px'}}>
                <Grid container spacing={3}>
                    <Grid item xs={3} container direction="column">
                        <Grid item>
                            <Typography color={grey[500]} fontWeight={500} fontSize={13} variant="body1">
                                 No. Invoice
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography fontWeight={700} fontSize={16} variant="h6">
                                {Invoice()}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={3} container direction="column">
                        <Grid item>
                            <Typography color={grey[500]} fontWeight={500} fontSize={13} variant="body1">
                                Jatuh Tempo {status === 'ordered' || status === 'received' ? dueDays() : null}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography fontWeight={700} fontSize={16} variant="h6">
                                {DueDate()}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={3} container direction="column">
                        <Grid item>
                            <Typography color={grey[500]} fontWeight={500} fontSize={13} variant="body1">
                                Cabang
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography fontWeight={700} sx={{display: 'flex'}}>
                                {store_name}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={3} container direction="column">
                        <Grid item>
                            <Typography color={grey[500]} fontWeight={500} fontSize={13} variant="body1">
                                Aktivitas
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography gutterBottom variant="h6" color="primary" fontSize={14} style={{textTransform: 'capitalize', cursor: 'pointer'}}>
                                Lihat Aktivitas
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </ContentPaper>
    )
}

const ContentPaper = styled(Paper)(({theme}) => ({
    marginBottom: 25
}));


const Slash = styled(Typography)(({theme}) => ({
    margin: '0 6px',
    color: 'var(--N75, #E5E7E9)'
}));