import React from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as productActions from '../../../../../../../actions/productActions';
import { 
    Dialog,
    Slide,
    CircularProgress,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useHistory, useParams } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Confirm() {
    let { id } = useParams();
    const dispatch = useDispatch();
    const {onEditConfirm, product, inProgress} = useSelector(state => ({
        ...state.product
    }), shallowEqual);
   

    function onHide() {
        dispatch(productActions.onHideConfirm());
    }

    function onSubmit() {
        const newProduct = {...product, validated: true}
        dispatch(productActions.onEdit(id, newProduct)); 
    }
    return (
        <Dialog
            open={!!onEditConfirm}
            maxWidth="xs"
            TransitionComponent={Transition}
            scroll={'paper'}>
            <DialogTitle>Ubah Produk</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Perubahan produk ini akan menyebabkan penghapusan informasi persedian dikarenakan perbedaan SKU.
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{padding: 25, justifyContent: 'space-between'}}>
                <CancelButton size="large" variant="contained" disabled={inProgress} disableElevation onClick={onHide}>
                    Batal
                </CancelButton>
                <Button sx={{minWidth: '165px'}} size="large" variant="contained" disabled={inProgress} disableElevation onClick={onSubmit} color="primary">
                    {inProgress ? <CircularProgress size={26} /> : 'Proses'}
                </Button>
            </DialogActions>    
        </Dialog>
    )
}

const CancelButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    minWidth: 165,
    border: `1px solid #ddd`
}));
