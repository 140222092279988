import React from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as orderActions from '../../../../../../../actions/orderActions';
import { 
    Dialog,
    Slide,
    CircularProgress,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useHistory, useParams } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Confirm() {
    let { id } = useParams();
    const dispatch = useDispatch();
    const {onOrderConfirm, order, actionType, inProgress} = useSelector(state => ({
        ...state.order
    }), shallowEqual);
   

    function onHide() {
        dispatch(orderActions.onHideConfirm());
    }

   
    function Title() {
        // if (actionType === 'canceled') {
        //     return (
        //         <DialogTitle>{"Batalkan PO?"}</DialogTitle>
        //     )
        // }

        // if (actionType === 'ordered') {
        //     return (
        //         <DialogTitle>{"Proses PO?"}</DialogTitle>
        //     )
        // }

        // if (actionType === 'received') {
        //     return (
        //         <DialogTitle>{"Terima PO?"}</DialogTitle>
        //     )
        // }

        // if (actionType === 'approved') {
        //     return (
        //         <DialogTitle>{"Validasi PO?"}</DialogTitle>
        //     )
        // }

        // if (actionType === 'updated') {
        //     return (
        //         <DialogTitle>{"Perbaharui PO?"}</DialogTitle>
        //     )
        // }

        if (actionType === 'update') {
            return (
                <DialogTitle>{"Perbaharui Pesanan?"}</DialogTitle>
            )
        }
        return null;
    }

    function ContentText() {
        if (actionType === 'update') {
            return (
                <DialogContentText>
                    Order dengan ID <span style={{fontWeight: 'bold'}}>#{order.increment_id}</span> akan diperbaharui.
                </DialogContentText>
            )
        }

        return null;
    }

    function onSubmit() {
        if (actionType === 'update') {
            dispatch(orderActions.onUpdate(id, order));
        }

        // if (actionType === 'ordered') {
        //     dispatch(orderActions.onOrdered(id, order));
        // }

        // if (actionType === 'received') {
        //     dispatch(orderActions.onReceived(id, order));
        // }

        // if (actionType === 'approved') {
        //     dispatch(orderActions.onApproved(id, order));
        // }

        // if (actionType === 'updated') {
        //     dispatch(orderActions.onUpdate(id, order));
        // }
    }

    return (
        <Dialog
            open={onOrderConfirm}
            maxWidth="xs"
            TransitionComponent={Transition}
            scroll={'paper'}>
            {Title()}
            <DialogContent>
                {ContentText()}
            </DialogContent>
            <DialogActions style={{padding: 25, justifyContent: 'space-between'}}>
                <CancelButton size="large" variant="contained" disabled={inProgress} disableElevation onClick={onHide}>
                    Batal
                </CancelButton>
                <Button sx={{minWidth: '165px'}} size="large" variant="contained" disabled={inProgress} disableElevation onClick={onSubmit} color="primary">
                    {inProgress ? <CircularProgress size={26} /> : 'Proses'}
                </Button>
            </DialogActions>    
        </Dialog>
    )
}

const CancelButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    minWidth: 165,
    border: `1px solid #ddd`
}));
