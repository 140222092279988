import * as types from './actionTypes';
import { axiosClient } from '../api';
import { validate } from '../components/Helper/Validation';

export const onLoadInventories = (query, progressCallback) => {
	const fetch = axiosClient.get(`/inventories`,
	{
		params: query,
		onDownloadProgress: progressCallback,
	});
	return {
		type: types.INVENTORIES_LOADED,
		payload: fetch
	}
}

export const onSearchInventories = (query) => {
	const fetch = axiosClient.get(`/inventories`,
	{
		params: query
	});
	return {
		type: types.INVENTORIES_SEARCHED,
		payload: fetch
	}
}

export const onLoadInventoriesSummary = (query) => {
	const fetch = axiosClient.get(`/inventories-summary`,
	{
		params: query
	});
	return {
		type: types.INVENTORIES_SUMMARY_LOADED,
		payload: fetch
	}
}

export const onCollapseInventories = (inventories) => {
	return {
		type: types.INVENTORIES_COLLAPSED, 
		inventories
	}
}

export const onShowPurchaseOrderHistories = (inventory) => {
	return {
		type: types.PURCHASE_ORDER_HISTORIES_SHOWED,
		inventory
	}
}

export const onHidePurchaseOrderHistories = () => {
	return {
		type: types.PURCHASE_ORDER_HISTORIES_HIDED
	}
}


export const onShowInventoryHistories = (inventory, store_id) => {
	return {
		type: types.INVENTORY_HISTORIES_SHOWED,
		inventory, store_id
	}
}

export const onHideInventoryHistories = () => {
	return {
		type: types.INVENTORY_HISTORIES_HIDED
	}
}


export const onLoadInventoryHistories = (id, query) => {
	const fetch = axiosClient.get(`/inventories/${id}/inventory-histories`,
	{
		params: query
	});
	return {
		type: types.INVENTORY_HISTORIES_LOADED, 
		payload: fetch
	}
}

export const onLoadPurchaseOrderHistories = (id, query) => {
	const fetch = axiosClient.get(`/inventories/${id}/purchase-order-histories`,
	{
		params: query
	});
	return {
		type: types.PURCHASE_ORDER_HISTORIES_LOADED, 
		payload: fetch
	}
}