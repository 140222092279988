import React, { useRef, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as orderActions from '../../../../../../actions/orderActions';
import { 
        InputBase,
        Typography,
        Box,
        Paper,
        Grid,
        TextField,
        InputAdornment,
        Alert,
        Divider,
        Button,
        Tooltip,
        Switch,
        Table,
        TableHead,
        TableBody,
        TableRow,
        TableCell
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { MoveDownTwoTone, ReceiptTwoTone, LocalShippingTwoTone, HelpTwoTone, PercentTwoTone, NumbersTwoTone } from '@mui/icons-material';
import NumberFormat from 'react-number-format';
import { tooltipClasses } from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';

const CurrencyFormat = React.forwardRef((props, ref) => (
    <NumberFormat
      {...props.other}
      onValueChange={(values) => {
        props.onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      customInput={TextField}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Box fontWeight={700}>Rp</Box>
          </InputAdornment>
        ),
        inputProps: {
          style: {
            textAlign: 'right', // Align text to the right
          },
        },
      }}
      placeholder="0"
      disabled={props.disabled}
      helperText={props.helperText}
      error={props.error}
      value={props.value}
      onFocus={props.onFocus}
      decimalScale={2}
      fullWidth={props.fullWidth}
      onBlur={props.onBlur}
      thousandSeparator="."
      decimalSeparator=","
      allowNegative={false}
      allowedDecimalSeparators={false}
      isNumericString
    />
  ));

export default function Product({error}) {    
    const theme = useTheme();
    const dispatch = useDispatch();
    const {order, errors} = useSelector(state => ({
        ...state.order
    }), shallowEqual); 
    const [totalOpen, setTotalOpen] = React.useState(false);
    const [currentFocus, setCurrentFocus] = useState("");

    const {
        gross_profit,
        shipping_cost, 
        subtotal, 
        grand_total, 
        order_items, 
        has_payments,
        has_canceleds,
        has_deliveries,
        order_payments
    } = order;

    const handleTotalClose = () => {
        setTotalOpen(false);
    };
    
    const handleTotalOpen = () => {
        setTotalOpen(true);
    };

    function onUpdatePriceField(index, event) {
        if (currentFocus === "cogs") {
            const key = event.target.name;
            const value = event.target.value;
            const newOrder = {...order};
            const currentItem = newOrder.order_items[index];
            currentItem[key] = Number(value);
            currentItem.row_gross_profit = (Number(currentItem.net_price) - Number(currentItem.cogs)) * Number(currentItem.quantity);
            const gross_profit = order_items.reduce((prev,next) => Number(prev) + Number(next.row_gross_profit),0);
            newOrder.gross_profit = gross_profit
            dispatch(orderActions.onUpdateOrder(newOrder));
        }
    }

    function onShowPayments() {
        dispatch(orderActions.onShowPayments());
    }

    function onShowCanceleds() {
        dispatch(orderActions.onShowCanceleds());
    }

    function onShowDeliveries() {
        dispatch(orderActions.onShowOrderDeliveries());
    }

    function onFocus(key) {
        setCurrentFocus(key)
    }

    function onBlur(key) {
        setCurrentFocus(null)
    }


    function BasePrice(item, index) {
        return (
            <CurrencyFormat
                name="cogs"
                fullWidth
                error={!!error(`order_items[${index}].cogs`)}
                helperText={error(`order_items[${index}].cogs`)}
                value={item.cogs}
                inputProps={{min: 0, style: { textAlign: 'right' }}}
                variant="outlined"
                onFocus={onFocus.bind(this, "cogs")}
                onBlur={onBlur.bind(this)}
                onChange={onUpdatePriceField.bind(this, index)}
            />
        )
    }

    function total() {
        return (
            <Box>
                <Grid container spacing={1} direction="column">
                    <Grid item container justifyContent="flex-end">
                        <Grid item xs>
                            <Typography fontWeight={500}>
                                Subtotal
                            </Typography>
                        </Grid>
                        <Grid item>
                            <NumberFormat
                                value={subtotal}
                                displayType="text"
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix="Rp "
                                isNumericString
                                decimalScale={2}
                                renderText={(value) => <Typography fontWeight={500}>
                                {value}
                            </Typography>}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container justifyContent="flex-end">
                        <Grid item xs>
                            <Typography fontWeight={500}>
                                Diskon
                            </Typography>
                        </Grid>
                        <Grid item>
                            <NumberFormat
                                value={0}
                                displayType="text"
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix="Rp "
                                isNumericString
                                decimalScale={2}
                                renderText={(value) => <Typography fontWeight={500}>
                                {value}
                            </Typography>}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container justifyContent="flex-end">
                        <Grid item xs>
                            <Typography fontWeight={500}>
                                Biaya Kirim
                            </Typography>
                        </Grid>
                        <Grid item>
                            <NumberFormat
                                value={shipping_cost}
                                displayType="text"
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix="Rp "
                                isNumericString
                                decimalScale={2}
                                renderText={(value) => <Typography fontWeight={500}>
                                {value}
                            </Typography>}
                            />
                        </Grid>
                    </Grid>
                    <Grid item >
                        <Divider />
                    </Grid>
                    <Grid item container justifyContent="flex-end">
                        <Grid item xs>
                            <Typography fontWeight={700}>
                                Total
                            </Typography>
                        </Grid>
                        <Grid item>
                            <NumberFormat
                                value={grand_total}
                                displayType="text"
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix="Rp "
                                isNumericString
                                decimalScale={2}
                                renderText={(value) => <Typography fontWeight={700}>
                                {value}
                            </Typography>}
                            />
                        </Grid>
                    </Grid>
                    {order_payments && (
                        <>
                            {/* Map through `order_payment_methods` */}
                            {order_payments.order_payment_methods.map((item, index) => (
                                <Grid key={index} item container justifyContent="flex-end">
                                    <Grid item xs>
                                    <Typography fontWeight={500}>
                                            {item.payment_title}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <NumberFormat
                                            value={item.payment_amount}
                                            displayType="text"
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            prefix="Rp "
                                            isNumericString
                                            decimalScale={2}
                                            renderText={(value) => (
                                                <Typography fontWeight={500}>{value}</Typography>
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            ))}
                            <Grid item >
                                <Divider />
                            </Grid>
                            {/* Payment Total */}
                            <Grid key="total" item container justifyContent="flex-end">
                                <Grid item xs>
                                    <Typography fontWeight={700}>
                                        Total Bayar
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <NumberFormat
                                        value={order_payments.payment_amount}
                                        displayType="text"
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        prefix="Rp "
                                        isNumericString
                                        decimalScale={2}
                                        renderText={(value) => (
                                            <Typography fontWeight={700}>{value}</Typography>
                                        )}
                                    />
                                </Grid>
                            </Grid>

                            {/* Change (Kembali) */}
                            {order_payments.change !== 0 && order_payments.change !== 0.0 && (
                                <Grid key="change" item container justifyContent="flex-end">
                                    <Grid item xs>
                                        <Typography fontWeight={700}>Kembali</Typography>
                                    </Grid>
                                    <Grid item>
                                        <NumberFormat
                                            value={order_payments.change}
                                            displayType="text"
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            prefix="Rp "
                                            isNumericString
                                            decimalScale={2}
                                            renderText={(value) => (
                                                <Typography fontWeight={700}>{value}</Typography>
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </>
                    )}
                    <Grid item >
                        <Divider />
                    </Grid>
                    <Grid item container justifyContent="flex-end">
                        <Grid item xs>
                            <Typography fontWeight={700}>
                                Laba Kotor
                            </Typography>
                        </Grid>
                        <Grid item>
                            <NumberFormat
                                value={gross_profit}
                                displayType="text"
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix="Rp "
                                isNumericString
                                decimalScale={2}
                                renderText={(value) => <Typography color={theme.palette.success.main} fontWeight={700}>
                                {value}
                            </Typography>}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        )
    }


    function Footer() {
        return (
            <Grid container spacing={3} justifyContent="flex-end">
                {has_payments && <Grid item>
                    <Button variant="outlined" onClick={onShowPayments} startIcon={<ReceiptTwoTone />}>
                        Pembayaran
                    </Button>
                </Grid>}
                {has_canceleds && <Grid item>
                    <Button variant="outlined" onClick={onShowCanceleds} startIcon={<MoveDownTwoTone />}>
                        Pembatalan
                    </Button>
                </Grid>}
                {has_deliveries &&<Grid item>
                    <Button variant="outlined"  onClick={onShowDeliveries} startIcon={<LocalShippingTwoTone />}>
                        Pengiriman
                    </Button>
                </Grid>}
                {(has_deliveries || has_canceleds || has_payments) && <Grid item>
                    <Divider orientation="vertical" />
                </Grid>}
                <Grid item container xs spacing={3} alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <Typography sx={{display: 'flex'}} lineheight="normal" fontSize={16} fontWeight={700}>
                            Total Pesanan 
                            <LightTooltip open={totalOpen} placement='right' onClose={handleTotalClose} onOpen={handleTotalOpen} title={total()}>
                                <HelpTwoTone color="primary" />
                            </LightTooltip>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <NumberFormat
                            value={grand_total}
                            displayType="text"
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix="Rp "
                            isNumericString
                            decimalScale={2}
                            renderText={(value) => <Typography lineheight="normal" fontSize={16} fontWeight={700}>
                            {value}
                        </Typography>}
                        />
                    </Grid>
                </Grid>
            </Grid>
        )
    }

   

    let titleAlign = 'right'
    let tableGrid = '30% 12% 12% 12% 15% 14% 13% 14% 16%'
    let maxName = 35
    return (
        <ContentPaper elevation={3}> 
            <Grid container spacing={3} direction="column">
                <Grid item container>
                    <Grid item xs>
                        <Typography fontWeight={700} variant="h6" lineheight="normal">
                            Informasi Produk
                        </Typography>
                    </Grid>
                    <Grid item>
                    </Grid>
                </Grid>
                <Grid item>
                    <Table sx={{
                        display: 'grid',
                        overflow: 'auto hidden',
                        gridTemplateColumns: tableGrid,
                        }}>
                        <TableHead sx={{display: 'contents'}}>
                            <TableRow sx={{display: 'contents'}}>
                                <TableCell sx={{
                                    paddingLeft: 0,
                                    position: 'sticky',
                                    zIndex: 1,
                                    left: 0,
                                    background: '#fff',
                                    }}>
                                Nama Barang
                            </TableCell>
                            <TableCell align={titleAlign}>
                                    Dipesan
                                </TableCell>
                            <TableCell align={titleAlign}>
                                    Dikirim
                                </TableCell>
                            <TableCell align={titleAlign}>
                                    Dibatalkan
                                </TableCell>
                            <TableCell align={titleAlign}>
                                Harga Pokok
                            </TableCell>
                            <TableCell align={titleAlign}>
                                    Harga Jual
                                </TableCell>
                            <TableCell align={titleAlign}>
                                    Diskon
                                </TableCell>
                            <TableCell align={titleAlign}>
                                Harga Netto
                            </TableCell>
                            <TableCell align={titleAlign} sx={{paddingRight: 0}}>
                                Subtotal
                            </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{display: 'contents'}}>
                        {
                            order_items.map((item, index) => {
                                return (
                                    <TableRow key={index} sx={{display: 'contents'}}>
                                        <TableCell sx={{
                                            paddingLeft: 0,
                                            position: 'sticky',
                                            zIndex: 1,
                                            display: 'flex',
                                            alignItems: 'center',
                                            left: 0,
                                            background: '#fff'
                                            }}
                                            tabIndex={-1}
                                            >
                                            <Tooltip
                                                title={item.name}
                                                arrow
                                            >
                                                <Box>
                                                    <Typography sx={{ fontWeight: 700 }} fontSize={16} lineHeight="normal">
                                                        {item.name.length > maxName
                                                            ? `${item.name.substring(0, maxName)}...`
                                                            : item.name}
                                                    </Typography>
                                                    <Typography fontSize={11} lineHeight="normal" sx={{ color: '#999' }}>
                                                        SKU #{item.sku}
                                                    </Typography>
                                                </Box>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align={titleAlign}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}>
                                            <NumberFormat
                                                value={item.quantity}
                                                displayType="text"
                                                thousandSeparator={"."}
                                                decimalSeparator={","}
                                                isNumericString
                                                decimalScale={2}
                                                renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                                                {value} {item.uom}
                                                </Typography>}
                                            />
                                        </TableCell>
                                        <TableCell align={titleAlign}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}>
                                            <NumberFormat
                                                value={item.delivered_qty}
                                                displayType="text"
                                                thousandSeparator={"."}
                                                decimalSeparator={","}
                                                isNumericString
                                                decimalScale={2}
                                                renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                                                {value ? value : 0} {item.uom}
                                                </Typography>}
                                            />
                                        </TableCell>
                                        <TableCell align={titleAlign}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}>
                                            <NumberFormat
                                                value={item.canceled_qty}
                                                displayType="text"
                                                thousandSeparator={"."}
                                                decimalSeparator={","}
                                                isNumericString
                                                decimalScale={2}
                                                renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                                                {value ? value : 0} {item.uom}
                                                </Typography>}
                                            />
                                        </TableCell>
                                        <TableCell align={titleAlign}>
                                            {BasePrice(item, index)}
                                        </TableCell>
                                        <TableCell align={titleAlign}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}>
                                            <NumberFormat
                                                value={item.unit_price}
                                                displayType="text"
                                                thousandSeparator={"."}
                                                decimalSeparator={","}
                                                prefix="Rp "
                                                isNumericString
                                                decimalScale={2}
                                                renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                                                {value}
                                                </Typography>}
                                            />
                                        </TableCell>
                                        <TableCell align={titleAlign}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}>
                                            <NumberFormat
                                                value={item.discount_type === 'percent' ? item.discount_percent : item.discount_amount}
                                                displayType="text"
                                                thousandSeparator={"."}
                                                decimalSeparator={","}
                                                prefix={item.discount_type === 'percent' ? "" : "Rp "}
                                                suffix={item.discount_type === 'percent' ? "%" : ""}
                                                isNumericString
                                                decimalScale={2}
                                                renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                                                {value}
                                                </Typography>}
                                            />
                                        </TableCell>
                                        <TableCell align={titleAlign}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}>
                                            <NumberFormat
                                                value={item.net_price}
                                                displayType="text"
                                                thousandSeparator={"."}
                                                decimalSeparator={","}
                                                prefix="Rp "
                                                isNumericString
                                                decimalScale={2}
                                                renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                                                {value}
                                                </Typography>}
                                            />
                                        </TableCell>
                                        <TableCell align={titleAlign} 
                                            sx={{
                                                paddingRight: 0,
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}>
                                            <NumberFormat
                                                value={item.row_total}
                                                displayType="text"
                                                thousandSeparator={"."}
                                                decimalSeparator={","}
                                                prefix="Rp "
                                                isNumericString
                                                decimalScale={2}
                                                renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                                                {value}
                                                </Typography>}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        }
                        </TableBody>
                    </Table>
                
                </Grid>
            </Grid>
            <Divider sx={{marginTop: '25px', marginBottom: '30px'}} />
            {Footer()}
        </ContentPaper>
    )
}

const ContentPaper = styled(Paper)(({theme}) => ({
    padding: 35,
    paddingTop: 32,
    marginBottom: 25
}));

const CommentField = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    // [theme.breakpoints.up('sm')]: {
    //   marginLeft: theme.spacing(1),
    //   width: 'auto',
    // },
}));


  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} sx={{marginLeft: '5px'}} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      width: 325,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
      padding: 15
    },
  }));
