import React, { useRef, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as storeAdjustmentActions from '../../../../../../actions/storeAdjustmentActions';
import { 
        InputBase,
        Typography,
        Box,
        Paper,
        Grid,
        TextField,
        InputAdornment,
        Alert,
        Divider,
        Tooltip,
        Switch,
        Table,
        TableHead,
        TableBody,
        TableRow,
        TableCell
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { MoveDownTwoTone, ReceiptTwoTone, StickyNote2TwoTone, HelpCenterTwoTone, HelpTwoTone, PercentTwoTone, NumbersTwoTone } from '@mui/icons-material';
import NumberFormat from 'react-number-format';
import { tooltipClasses } from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';

const QuantityFormat = React.forwardRef((props, ref) => (
    <NumberFormat
        {...props.other}
        onValueChange={(values) => {
          props.onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        disabled={props.disabled}
        customInput={TextField}
        placeholder={props.placeholder}
        value={props.value}
        helperText={props.helperText}
        error={props.error}
        onFocus={props.onFocus}
        decimalScale={2}
        fullWidth={props.fullWidth}
        onBlur={props.onBlur}
        thousandSeparator={"."}
        decimalSeparator={","}
        allowNegative={false}
        allowedDecimalSeparators={false}
        isNumericString
        InputProps={props.InputProps}
      />
));


export default function Product() { 
    const dispatch = useDispatch();
    const theme = useTheme();
    const {store_adjustment, errors} = useSelector(state => ({
        ...state.store_adjustment
    }), shallowEqual); 
    const [totalOpen, setTotalOpen] = React.useState(false);
    const [currentFocus, setCurrentFocus] = useState("");
   
    const { _id, store_adjustment_items, status, note, subtotal, discount, grand_total, grand_total_return, has_returns, has_payments, grand_total_payment, due_total } = store_adjustment;



    const handleTotalClose = () => {
        setTotalOpen(false);
    };
    
    const handleTotalOpen = () => {
        setTotalOpen(true);
    };


    function onUpdateCheckedField(index, event) {
        if (currentFocus === "checked") {
            console.log(index)
            const key = event.target.name;
            const value = event.target.value;
            const newStoreAdjustment = {...store_adjustment};
            const currentItem = newStoreAdjustment.store_adjustment_items[index];
            currentItem[key] = value;
            dispatch(storeAdjustmentActions.onUpdateStoreAdjustment(newStoreAdjustment));
        }
    }

    function onUpdateNoteField(event) {
		const key = event.target.name;
        const value = event.target.value;
        const newStoreAdjustment = {...store_adjustment};
        newStoreAdjustment[key] = value;
        dispatch(storeAdjustmentActions.onUpdateStoreAdjustment(newStoreAdjustment));
    }
  
    function onFocus(key) {
        setCurrentFocus(key)
    }

    function onBlur(key) {
        setCurrentFocus(null)
    }

    function InStock(item) {
        return (
            <NumberFormat
                value={item.in_stock}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator={","}
                isNumericString
                decimalScale={2}
                renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                {value} {item.uom}
                </Typography>}
            />
        )
    }


    function InStockEpt(item) {
        return (
            <NumberFormat
                value={item.expected_qty}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator={","}
                isNumericString
                decimalScale={2}
                renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                {value} {item.uom}
                </Typography>}
            />
        )
    }

    function Requested(item) {
        if (status === 'requested') {
            return (
                <QuantityFormat
                    fullWidth
                    name="requested_qty"
                    value={item.requested_qty}
                    disabled
                    variant="outlined"
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{item.uom}</InputAdornment>,
                        inputProps: {
                          style: {
                            textAlign: 'right', // Align text to the right
                          },
                        },
                    }}
                />
            )
        }

        return (
            <NumberFormat
                value={item.requested_qty}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator={","}
                isNumericString
                decimalScale={2}
                renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                {value} {item.uom}
                </Typography>}
            />
        )
    }

    function Checked(item, index) {
        if (status === 'requested' ) {
            return (
                <QuantityFormat
                    fullWidth
                    // error={!!error(`purchase_order_items[${index}].received_qty`)}
                    // helperText={error(`purchase_order_items[${index}].received_qty`)}
                    name="checked_qty"
                    value={item.checked_qty}
                    onFocus={onFocus.bind(this, "checked")}
                    onBlur={onBlur.bind(this)}
                    variant="outlined"
                    onChange={onUpdateCheckedField.bind(this, index)}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{item.uom}</InputAdornment>,
                        inputProps: {
                          style: {
                            textAlign: 'right', // Align text to the right
                          },
                        },
                    }}
                />
            )
        }

        return (
            <NumberFormat
                value={item.checked_qty}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator={","}
                isNumericString
                decimalScale={2}
                renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                {value} {item.uom}
                </Typography>}
            />
        )
    }

    function Adjustment(item) {
        return (
            <NumberFormat
                value={item.adjustment_type === "gain" ? item.adjustment_qty_gain : item.adjustment_qty_loss}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator={","}
                isNumericString
                decimalScale={2}
                renderText={(value) => (
                    <Typography textAlign="right" fontWeight={500} fontSize={16}
                        color={item.adjustment_type === "gain" ? theme.palette.success.main : theme.palette.error.main}>
                        {value} {item.uom}
                    </Typography>
                    
                )}
            />
        )
    }

    function NetPrice(item) {
        return (
            <NumberFormat
                value={item.net_price}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix="Rp "
                isNumericString
                decimalScale={2}
                renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                {value}
                </Typography>}
            />
        )
    }

    function Subtotal(item) {
        return (
            <NumberFormat
                value={item.row_total}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix="Rp "
                isNumericString
                decimalScale={2}
                renderText={(value) => (
                    <Typography textAlign="right" fontSize={16} fontWeight={500} color={item.adjustment_type === "gain" ? theme.palette.success.main : theme.palette.error.main}>
                    {value}
                    </Typography>
                )}
            />
        )
    }

    function hasOptions(item) {

        if (item && item.product_options) {
            var optionsKeys = [];
            item.product_options.sort((a, b) => {
                const nameA = a.sort_order;
                const nameB = b.sort_order;
                if (nameA < nameB) {
                return -1;
                }
                if (nameA > nameB) {
                return 1;
                }
            
                // names must be equal
                return 0;
            }).map((option) => {
                if (!optionsKeys.includes(option.uom.toLowerCase())) {
                    optionsKeys.push(option.uom.toLowerCase());
                }
            })

            var options = [];
            optionsKeys.map((key, index)  => {
                const option = item.product_options.find(opt => opt.sort_order === index);
                if (index === 0) {
                    options.push({name: option.uom, unit: ""});
                } else {
                    const firstOption = item.product_options.find(opt => opt.sort_order === 0);
                    options.push({name: option.uom, unit: option.unit + " " + firstOption.uom});
                }
            })

            if (options.length >= 2) {
                return (
                    <Tooltip
                        title={itemOptions(options)} >
                        <HelpCenterTwoTone color="primary" sx={{ marginLeft: '10px', marginBottom: '13px' }} />
                    </Tooltip>
                )
            }
            
            return null;
        }

        return null;
    }
    
    function itemOptions(options) {
        return (
            <Box>
                <Typography fontWeight={700}>
                    Produk Opsi
                </Typography>
                {options.map((option, index) => {
                    return (
                        <Box key={index} sx={{display: 'flex'}}>
                            <Typography fontWeight={500}>
                                {option.name} 
                            </Typography>
                            <Typography sx={{marginLeft: '5px'}}>
                                {option.unit ? `(${option.unit})` : null}
                            </Typography>
                        </Box>
                    )
                })}
            </Box>
        )
    }

    function Footer() {
            return (
                <Grid container spacing={3} >  
                    <Grid item>
                        <CommentField
                            value={note}
                            onChange={onUpdateNoteField}
                            >
                            <IconWrapper>
                                <StickyNote2TwoTone />
                            </IconWrapper>
                            <StyledInputBase
                                name="note"
                                disabled={status === 'canceled' || status === 'paid'}
                                placeholder="Note…"/>
                        </CommentField>
                    </Grid>
                </Grid>
            )
    }

   

    let titleAlign = 'right'
    let checkedStatus = status === 'checked';
    let requestedStatus = status === 'requested';
    let canceledStatus = status === 'canceled';
    let tableGrid = '30% 14% 18% 14% 14% 14% 16% 16%'
    let maxName = 35
    if (status === 'requested') {
        maxName = 40
        tableGrid = '46% 18% 18% 18%'
    } 
    return (
        <ContentPaper elevation={3}> 
            <Grid container spacing={3} direction="column">
                <Grid item container>
                    <Grid item xs>
                        <Typography fontWeight={700} variant="h6" lineheight="normal">
                            Informasi Produk
                        </Typography>
                    </Grid>
                    <Grid item>
                        {/* {requestedStatus || orderedStatus && <Grid item>
                            <Alert severity="error">
                                Dibutuhkan
                            </Alert>
                        </Grid>} */}
                    </Grid>
                </Grid>
                <Grid item>
                    <Table sx={{
                        display: 'grid',
                        overflow: 'auto hidden',
                        gridTemplateColumns: tableGrid,
                        }}>
                        <TableHead sx={{display: 'contents'}}>
                            <TableRow sx={{display: 'contents'}}>
                                <TableCell sx={{
                                    paddingLeft: 0,
                                    position: 'sticky',
                                    zIndex: 1,
                                    left: 0,
                                    background: '#fff',
                                    }}>
                                   Nama Barang
                            </TableCell>
                            {(checkedStatus || canceledStatus) && (
                                <TableCell align={titleAlign}>
                                    Stok Awal
                                </TableCell>
                            )}
                             {(checkedStatus || canceledStatus) && (
                                <TableCell align={titleAlign}>
                                    Stok Awal(Ept.)
                                </TableCell>
                            )}
                            {(checkedStatus || canceledStatus) && (
                                <TableCell align={titleAlign}>
                                    Diajukan
                                </TableCell>
                            )}
                            {(requestedStatus || checkedStatus || canceledStatus) && (
                                <TableCell align={titleAlign}>
                                    Diperiksa
                                </TableCell>
                            )}
                            {(checkedStatus || canceledStatus) && (
                                <TableCell align={titleAlign}>
                                    Penyesuaian
                                </TableCell>
                            )}
                            {(checkedStatus || canceledStatus) && (
                              
                                <TableCell align={titleAlign}>
                                    Harga Netto
                                </TableCell>
                            )}
                            {(checkedStatus || canceledStatus) && (
                                <TableCell align={titleAlign} sx={{paddingRight: 0}}>
                                    Subtotal
                                </TableCell>
                            )}
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{display: 'contents'}}>
                        {
                            store_adjustment_items.map((item, index) => {
                             
                                return (
                                    <TableRow key={index} sx={{display: 'contents'}}>
                                        <TableCell sx={{
                                            paddingLeft: 0,
                                            position: 'sticky',
                                            zIndex: 1,
                                            display: 'flex',
                                            alignItems: 'center',
                                            left: 0,
                                            background: '#fff'
                                            }}
                                            tabIndex={-1}
                                            >
                                            <Tooltip
                                                title={item.name}
                                                arrow
                                            >
                                                <Box>
                                                    <Typography sx={{ fontWeight: 700 }} fontSize={16} lineHeight="normal">
                                                        {item.name.length > maxName
                                                            ? `${item.name.substring(0, maxName)}...`
                                                            : item.name}
                                                    </Typography>
                                                    <Typography fontSize={11} lineHeight="normal" sx={{ color: '#999' }}>
                                                        SKU #{item.sku}
                                                    </Typography>
                                                </Box>
                                            </Tooltip>
                                            {hasOptions(item)}
                                        </TableCell>
                                    {(checkedStatus || canceledStatus) && (
                                        <TableCell align={titleAlign}>
                                            {InStock(item)}
                                        </TableCell>
                                    )}
                                    {(checkedStatus || canceledStatus) && (
                                        <TableCell align={titleAlign}>
                                            {InStockEpt(item)}
                                        </TableCell>
                                    )}
                                    {(checkedStatus || canceledStatus) && (
                                        <TableCell align={titleAlign}>
                                            {Requested(item)}
                                        </TableCell>
                                    )}
                                    {(requestedStatus || checkedStatus || canceledStatus) && (
                                        <TableCell align={titleAlign}>
                                            {Checked(item, index)}
                                        </TableCell>
                                    )}
                                    {(checkedStatus || canceledStatus) && (
                                        <TableCell align={titleAlign}>
                                            {Adjustment(item)}
                                        </TableCell>
                                    )}
                                    {(checkedStatus || canceledStatus) && (
                                        <TableCell align={titleAlign}>
                                            {NetPrice(item)}
                                        </TableCell>
                                    )}
                                    {(checkedStatus || canceledStatus) && (
                                        <TableCell align={titleAlign} sx={{paddingRight: 0}}>
                                            {Subtotal(item)}
                                        </TableCell>
                                    )}
                                    </TableRow>
                                );
                            })
                        }
                        </TableBody>
                    </Table>
                  
                </Grid>
            </Grid>
            <Divider sx={{marginTop: '25px', marginBottom: '30px'}} />
            {Footer()}
        </ContentPaper>
    )
}
//     let tableGrid = '30% 12% 12% 16%'
//     let maxName = 35
//     if (status === 'requested') {
//         maxName = 50
//         tableGrid = '80% 20%'
//     }
//     return (
//         // <ContentPaper elevation={3}> 
//         //     <Grid container spacing={3} direction="column">
//         //         <Grid item container>
//         //             <Grid item xs>
//         //                 <Typography fontWeight={700} variant="h6" lineheight="normal">
//         //                     Informasi Produk
//         //                 </Typography>
//         //             </Grid>
//         //             <Grid item>
//         //                 {status === 'requested' && <Grid item>
//         //                     <Alert severity="error">
//         //                         Dibutuhkan
//         //                     </Alert>
//         //                 </Grid>}
//         //             </Grid>
//         //         </Grid>
//         //         <Grid item>
//         //             <Table sx={{
//         //                 display: 'grid',
//         //                 overflow: 'auto hidden',
//         //                 gridTemplateColumns: tableGrid,
//         //                 }}>
//         //                 <TableHead sx={{display: 'contents'}}>
//         //                     <TableRow sx={{display: 'contents'}}>
//         //                         <TableCell sx={{
//         //                             paddingLeft: 0,
//         //                             position: 'sticky',
//         //                             zIndex: 1,
//         //                             left: 0,
//         //                             background: '#fff',
//         //                             }}>
//         //                            Nama Barang
//         //                     </TableCell>
//         //                     {(checkedStatus || canceledStatus) && (
//         //                         <TableCell align={titleAlign}>
//         //                             Stok Awal
//         //                         </TableCell>
//         //                     )}
//         //                     {(checkedStatus || canceledStatus) && (
//         //                         <TableCell align={titleAlign}>
//         //                             Stok Awal (Ept.)
//         //                         </TableCell>
//         //                     )}
//         //                     {(checkedStatus || canceledStatus) && (
//         //                         <TableCell align={titleAlign}>
//         //                             Diajukan
//         //                         </TableCell>
//         //                     )}
//         //                     {(requestedStatus || checkedStatus || canceledStatus) && (
//         //                         <TableCell align={titleAlign}>
//         //                             Diperiksa
//         //                         </TableCell>
//         //                     )}
//         //                       {(checkedStatus || canceledStatus) && (
//         //                         <TableCell align={titleAlign}>
//         //                             Penyesuaian
//         //                         </TableCell>
//         //                     )}
//         //                     {(checkedStatus || canceledStatus) && (
//         //                         <TableCell align={titleAlign}>
//         //                             Net Price
//         //                         </TableCell>
//         //                     )}
//         //                       {(checkedStatus || canceledStatus) && (
//         //                         <TableCell align={titleAlign} sx={{paddingRight: 0}}>
//         //                             Subtotal
//         //                         </TableCell>
//         //                     )}
//         //                     </TableRow>
//         //                 </TableHead>
                        // <TableBody sx={{display: 'contents'}}>
                        // {
                        //     store_adjustment_items.map((item, index) => {
                             
                        //         return (
                        //             <TableRow key={index} sx={{display: 'contents'}}>
                        //                 <TableCell sx={{
                        //                     paddingLeft: 0,
                        //                     position: 'sticky',
                        //                     zIndex: 1,
                        //                     display: 'flex',
                        //                     alignItems: 'center',
                        //                     left: 0,
                        //                     background: '#fff'
                        //                     }}
                        //                     tabIndex={-1}
                        //                     >
                        //                     <Tooltip
                        //                         title={item.name}
                        //                         arrow
                        //                     >
                        //                         <Box>
                        //                             <Typography sx={{ fontWeight: 700 }} fontSize={16} lineHeight="normal">
                        //                                 {item.name.length > maxName
                        //                                     ? `${item.name.substring(0, maxName)}...`
                        //                                     : item.name}
                        //                             </Typography>
                        //                             <Typography fontSize={11} lineHeight="normal" sx={{ color: '#999' }}>
                        //                                 SKU #{item.sku}
                        //                             </Typography>
                        //                         </Box>
                        //                     </Tooltip>
                        //                     {hasOptions(item)}
                        //                 </TableCell>
                        //             {(checkedStatus || canceledStatus) && (
                        //                 <TableCell align={titleAlign}>
                        //                     {InStock(item)}
                        //                 </TableCell>
                        //             )}
                        //             {(checkedStatus || canceledStatus) && (
                        //                 <TableCell align={titleAlign}>
                        //                     {InStockEpt(item)}
                        //                 </TableCell>
                        //             )}
                        //             {(checkedStatus || canceledStatus) && (
                        //                 <TableCell align={titleAlign}>
                        //                     {Requested(item)}
                        //                 </TableCell>
                        //             )}
                        //             {(requestedStatus || checkedStatus || canceledStatus) && (
                        //                 <TableCell align={titleAlign}>
                        //                     {Checked(item, index)}
                        //                 </TableCell>
                        //             )}
                        //             {(checkedStatus || canceledStatus) && (
                        //                 <TableCell align={titleAlign}>
                        //                     {Adjustment(item)}
                        //                 </TableCell>
                        //             )}
                        //             {(checkedStatus || canceledStatus) && (
                        //                 <TableCell align={titleAlign}>
                        //                     {NetPrice(item)}
                        //                 </TableCell>
                        //             )}
                        //             {(checkedStatus || canceledStatus) && (
                        //                 <TableCell align={titleAlign} sx={{paddingRight: 0}}>
                        //                     {Subtotal(item)}
                        //                 </TableCell>
                        //             )}
                        //             </TableRow>
                        //         );
                        //     })
                        // }
                        // </TableBody>
//         //             </Table>
//         //         </Grid>
//         //     </Grid>
//         //     <Divider sx={{marginTop: '25px', marginBottom: '30px'}} />
//         //     {Footer()}
//         // </ContentPaper>
//     )
// }

const ContentPaper = styled(Paper)(({theme}) => ({
    padding: 35,
    paddingTop: 32,
    marginBottom: 25
}));

const CommentField = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    // [theme.breakpoints.up('sm')]: {
    //   marginLeft: theme.spacing(1),
    //   width: 'auto',
    // },
}));

const IconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.main
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }));

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} sx={{marginLeft: '5px'}} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      width: 325,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
      padding: 15
    },
  }));

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 60,
    height: 34,
    padding: 12,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(22px)',
        '& + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        },
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 20 / 2,
    },
}));

const SwitchBox = styled(Box)(({ theme }) => ({
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    backgroundColor: '#001e3c',
    width: 30,
    height: 30,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}));