import * as types from '../actions/actionTypes';
const defaultState = {
	product: {},
	onConfiguration: false,
	onAdvancedPrice: false,
	onProductHistories: false,
	autoSKU: true,
}

export default function product (state = defaultState, action) {
    function pad(data, size) {
        var s = String(data);
        while (s.length < (size || 2)) {s = "0" + s;}
        return s;
    }

	function validEANBarcode(barcode){
		let s = 0;
		for (let i = 0; i < 12; i++) {
			let c = parseInt(barcode.charAt(i));
			s += c * ( i%2 == 0? 1: 3);
		}
		s = (10 - s % 10) % 10;
		// barcode += s;
		// console.log(barcode)
		return barcode.slice(0, -1) + s;
        // let result = 0;

        // let i = 1;
        // for (let counter = s.length-1; counter >=0; counter--){
        //     result = result + parseInt(s.charAt(counter)) * (1+(2*(i % 2)));
        //     i++;
        // }
        // return (10 - (result % 10)) % 10;
    }


	function generateSKU() {
		let currentDate = new Date();
		let date = currentDate.getDate().toString().padStart(2, "0");
		let month = currentDate.getMonth().toString().padStart(2, "0");
		let year = currentDate.getUTCFullYear().toString().substr(-2);
		let dateSKU = date + month + year;
		let minutes = currentDate.getMinutes().toString().padStart(2, "0");
		let seconds = currentDate.getSeconds().toString().padStart(2, "0");
		let milliSeconds = currentDate.getMilliseconds().toString().padStart(3, "0");
		let timeSKU = minutes + seconds + milliSeconds;
		return Number(dateSKU.concat(timeSKU)).toString().padEnd(13, "0");
	}

	function itemSKU(sku, i) {
		return ((Number(sku) + (Number(i + 1) * 5)) + Number(i * 100)).toString().padEnd(13, "0")
	}


	function getDestroyedImages(images) {
		return images.filter(el => {
	    	return el._destroy === true;
	  	}).length;
	}

	function getEmptyImages(images) {
		const newImages = [];
        const totalImages = images.filter(el => {
            return el.image && !el._destroy;
        }).length;

        const imagesCount = 5 - totalImages;
        for (let i = 0; i < imagesCount; i++) {
            newImages.push({image: ''})
        };
		
		return newImages;
	}

	let productImages;
	let currentRemove;
	let emptyImages;
	let newVariants;
	let variantImages;
	switch(action.type) {
		case types.PRODUCTS_LOADED:
			return {
				...state,
				products: action.error ? null : action.payload.products,
				products_count: action.error ? null : action.payload.products_count,
            };
        case types.NEW_PRODUCT_LOADED:
            return {
                ...state,
                categories: action.error ? null : action.payload.categories,
                last_id: action.error ? null : action.payload.last_id,
				onEditConfirm: false,
				onModifier: false,
				onCategories: false,
                product: {
                    product_type: 'simple',
                    name: '',
					category_ids: [],
                    sku: validEANBarcode(generateSKU()), 
                    price: '', 
					uom: '',
                    description: '',
                    status: false,
                    weight_type: 'kg',
                    weight: '',
                    product_images: [
                        {image: ''}
                    ],
					product_price_modifiers: [],
					product_specifications: [],
                    product_option: {title: "", product_option_entities: []},
					// product_options: [],
                    product_configurations: [],
					product_variants: [],
                    product_prices: []
                },
				emptyImages: [
					{image: ''},
					{image: ''},
					{image: ''},
					{image: ''},
					{image: ''}
				],
                errors: [],
			};
		case types.EDIT_PRODUCT_LOADED:
			productImages = [...action.payload.product.product_images];
			emptyImages = productImages.length - getDestroyedImages(productImages);
			if (emptyImages === 0) {
				productImages.push({image: ''})
			}
			return {
				...state,
				categories: action.payload ? action.payload.categories : null,
				product: action.payload ? {...state.product, ...action.payload.product} : null,
				emptyImages: action.payload ? getEmptyImages(productImages) : state.emptyImages,
				errors: [],
				onEditConfirm: false
			};
        case types.PRODUCT_FIELD_UPDATED:
            return {
                ...state,
                product: {...state.product, [action.key]: action.value}
			};
		case types.PRODUCT_OPTION_FIELD_UPDATED:
            return {
                ...state,
                product: {...state.product, product_option: {...state.product.product_option, title: action.value}}
			};
		case types.PRODUCT_IMAGES_UPDATED:
			return {
				...state,
				product: {...state.product, product_images: action.images}
			};
		case types.PRODUCT_NAME_UPDATED:
			if (state.product.product_variants.length) {
				state.product.product_variants.map((variant, index) => {
					const values = []
					Object.values(variant.variant_attributes).map((v) => {
						return values.push(v)	
					})
					var variantName = action.value.toString().concat(" ", values.join(' '));
					const newVariants = { ...variant, name: variantName }
					return state.product.product_variants.splice(index, 1, newVariants)
				})
			}
			return {
				...state,
				product: { ...state.product, [action.key]: action.value }
			};
		case types.PRODUCT_CATEGORIES_TOGGLED:
			return {
				...state,
				onCategories: action.onCategories,
				product: {...state.product}
			};
		case types.PRODUCT_CATEGORIES_SELECTED:
			return {
				...state,
				categories: action.newCategories,
				// To Edit Product Categories if has product variants 
				product: {...state.product, category_ids: action.newCategoryIds},
				onCategories: false
				// product: { ...state.product, category_ids: [action.value] }
            };
		case types.PRODUCT_SKU_TOGGLED: {
				var productSKU = validEANBarcode(generateSKU());
				if (!state.autoSKU) {
					// if (state.product._id) {
					// 	productSKU = state.product.sku;
					// } else {
					// 	productSKU = generateSKU;
					// }
					if (state.product.product_variants.length !== 0) {
						const variants = state.product.product_variants.filter(el => {
							return !el._destroy;
						})
						if (variants.length !== 0) {
							variants.map((variant, i) => {
								if (!variant._id) {
									variant.sku = validEANBarcode(itemSKU(productSKU, i));
								}
							})
						}
					}
				
					if (state.product.product_option.product_option_entities.length !== 0) {
						const options = state.product.product_option.product_option_entities.filter(el => {
							return !el._destroy;
						})
						if (options.length !== 0) {
							options.map((option, i) => {
								if (!option._id) {
									option.sku = validEANBarcode(itemSKU(productSKU, i));
								}
							})
						}
					}
				} else {
					productSKU = state.product.sku;
				}
 				
			return {
                ...state,
                autoSKU: action.value,
				product: { ...state.product, sku: state.product._id ? state.product.sku : productSKU }
            };
		};
        case types.PRODUCT_SPECIFICATIONS_UPDATED:
            return {
                ...state,
                product: { ...state.product, product_specifications: action.newSpecifications }
            };
		// case types.PRODUCT_OPTION_ADDED:
		// 	return {
		// 		...state,
		// 		product: { ...state.product, product_option: { title: "", product_option_entities: action.newOptions }, product_type: 'options' }
		// 		// product: { ...state.product, product_options: action.newOptions, option_title: '',product_type: 'options' }
		// 	};
        case types.PRODUCT_OPTIONS_ADDED:
            return {
                ...state,
				// product: { ...state.product, product_option: {title: "", product_type: 'options', ...state.product.product_option, product_option_entities: action.newOptions} }
				product: { ...state.product, product_option: { title: "", product_option_entities: action.newOptions }, product_type: 'options' }
                // product: { ...state.product, product_options: action.newOptions, option_title: '',product_type: 'options' }
            };
        case types.PRODUCT_OPTIONS_UPDATED:
			return {
				...state,
				product: { ...state.product, product_option: {...state.product.product_option, product_option_entities: action.newOptions} }
				// product: { ...state.product, product_options: action.newOptions }
            };
        case types.PRODUCT_OPTIONS_REMOVED:
			delete state.product.option_title;
            return {
                ...state,
                product: { ...state.product, product_option: {...state.product.product_option, _destroy: true, product_option_entities: action.newOptions}, product_type: 'simple' }
				// product: { ...state.product, product_options: action.newOptions, product_type: 'simple', option_title: null }
            };
        case types.PRODUCT_CONFIGURATION_SHOWED:
            return {
                ...state,
				onConfiguration: true,
				configurationsErrors: []
            };
        case types.PRODUCT_CONFIGURATION_HIDED:
            return {
                ...state,
				onConfiguration: false
            };
		// case types.PRODUCT_VARIANTS_REMOVED:
		// 	return {
		// 		...state,
		// 		onConfiguration: false,
		// 		product: { ...state.product, product_variants: action.newVariants, product_type: 'simple' }
		// 	};
        case types.PRODUCT_CONFIGURATIONS_DONE:
			const configurations = action.product_configurations;
			
            const configArray = [];
			for (let i = 0; i < configurations.length ; i++) {
				if (!configurations[i]._destroy) {
					configArray.push(configurations[i])
				}
			};
			const newArray = []
			const variantArray = []
			function generateConfig(cArray, nArray, vArray) {
				if (cArray.length === 0) {
					return vArray;
				} else {
					nArray.splice(1, 0, cArray[0]);
					cArray.shift();
					return arrayMatrix(cArray, nArray, vArray);
				}
			}

			function arrayMatrix(cArray, nArray, vArray) {
				var matrix = []
				for (let i = 0; i < nArray.length ; i++) {
					matrix[i] = []
					for ( var x = 0; x < nArray[i].product_configuration_options.length; x++ ) {
						if (!nArray[i].product_configuration_options[x]._destroy) {
							matrix[i][x] = {}
							matrix[i][x] = nArray[i].product_configuration_options[x].value;
						}
					}
				};
				
				if (vArray.length === 0) {
					for(var j = 0; j < matrix.length; j++) {
						const name = nArray[j].name.toLowerCase();
						var optionJ = matrix[j];
					
						for(var k = 0; k < optionJ.length; k++) {
							vArray.push({[name]: optionJ[k]})
						}
					}
					nArray.shift();
					return generateConfig(cArray, nArray, vArray)
				} else {
					const newVariantArray = []
					for(var m = 0; m < vArray.length; m++) {
						const name = nArray[0].name.toLowerCase()
						var optionM = matrix[0];
						for(var n = 0; n < optionM.length; n++) {
							newVariantArray.push({...vArray[m], [name]: optionM[n]})
						}
					}
					nArray.shift();
					return generateConfig(cArray, nArray, newVariantArray)
				}
			}

			
		
			const variantAttributes = generateConfig(configArray, newArray, variantArray)
				
			function getValueName(attribute, attributes) {
				const values = []
				Object.values(attributes).map((v) => {
					return values.push(v)	
				})
				return attribute.toString().concat(" ", values.join(' '))
			}

			function isDestroyed(attributes) {
				var _destroy = false;
				Object.values(attributes).map((v) => {
					if (!v) {
						_destroy = true;
					}	
				})
				return _destroy
			}
   
   

			// function createAndSortVariants(newVariants, initialVariants) {
			// 	for(var i=0; i<newVariants.length; ++i) {
			// 		for(var j=0; j<initialVariants.length; ++j) {
			// 			if( !newVariants[i]._destroy && (JSON.stringify(newVariants[i].variant_attributes) === JSON.stringify(initialVariants[j].variant_attributes)) )
			// 			newVariants[i] = initialVariants[j];
			// 		}
			// 	}

				
			// 	const newOptions = [...newVariants]
			// 	newOptions.map((option, o) => {
			// 		let updateOption = { ...option, sort_order: o}     
			// 		return newOptions.splice(o, 1, updateOption);
			// 	})
			// 	return newOptions;
			// }

			function uniqueVariant(data) {
                var a = data.concat();
				for(var i=0; i<a.length; ++i) {
					for(var j=i+1; j<a.length; ++j) {
						console.log(a[j])
						if( a[j] && !a[j]._destroy && (JSON.stringify(a[i].variant_attributes) === JSON.stringify(a[j].variant_attributes)) )
							a.splice(i, 1, a[j]);
					}
				}

				for(var i=0; i<a.length; ++i) {
					if (isDestroyed(a[i].variant_attributes))
						a.splice(i, 1);
					for(var j=i+1; j<a.length; ++j) {
						console.log(a[j])
						if( a[j] && !a[j]._destroy && (JSON.stringify(a[i].variant_attributes) === JSON.stringify(a[j].variant_attributes)) )
							a.splice(j--, 1);

					}
				}
				return a;
			}

			// function uniqueVariant(data) {
            //     var a = data.concat();
			// 	for(var i=0; i<a.length; ++i) {
			// 		if (isDestroyed(a[i].variant_attributes))
			// 			a.splice(i, 1);
			// 		for(var j=i+1; j<a.length; ++j) {
						
			// 			if( !a[i]._destroy && (JSON.stringify(a[i].variant_attributes) === JSON.stringify(a[j].variant_attributes)) )
			// 			console.log(a)
			// 				a.splice(j, 1);
			// 				// a.splice(j--, 1);
			// 		}
			// 	}
			// 	const newOptions = [...a]
				// newOptions.map((option, o) => {
				// 	let updateOption = { ...option, sort_order: o}     
				// 	return newOptions.splice(o, 1, updateOption);
				// })
			// 	return newOptions;
			// }


			
			function getNumberConfigurations() {
				return configurations.filter(el => {
				  return !el._destroy;
				}).length;
			}

			const generateVariants = [];

			var variantSKU;
			if (getNumberConfigurations() === state.product.product_configurations.length) {
				console.log('1')
				variantAttributes.map((attr, i) => {
					if (!state.autoSKU) {
						variantSKU = ""
					} else {
						variantSKU = validEANBarcode(itemSKU(generateSKU(), i));
						// variantSKU = Number(generateSKU()) + Number(i + 1)
					}
					const productVariant = {
						product_images: [
							{image: ''}
						],
						name: getValueName(state.product.name, attr),
						sku: variantSKU, 
						product_type: 'variant_item',
						price: 0, 
						uom: '',
						weight: 0, 
						status: true,
						variant_attributes: attr,
						sort_order: i
					}
					return generateVariants.push(productVariant)
				})
			} else {
				console.log('2')
				// state.product.product_variants.map((v) => {
				// 	return v._destroy = true;
				// })
				variantAttributes.map((attr, i) => {
					if (!state.autoSKU) {
						variantSKU = ""
					} else {
						variantSKU = validEANBarcode(itemSKU(generateSKU(), i));
						// variantSKU = ((Number(generateSKU()) + Number(i + 1)) + Number(i * 100))
					}
					return generateVariants.push({
						product_images: [
							{image: ''}
						],
						name: getValueName(state.product.name, attr),
						sku: variantSKU, 
						product_type: 'variant_item',
						price: 0, 
						uom: '',
						weight: 0, 
						status: true,
						variant_attributes: attr,
						sort_order: i
					})
				})
			}

			
			

			// console.log(generateVariants)
			// const createNewVariants = uniqueVariant(state.product.product_variants.concat(generateVariants));
		

			// state.product.product_variants.map((variant, i) => {
			// 	const hasVariant = generateVariants.find(v => JSON.stringify(variant.variant_attributes) === JSON.stringify(v.variant_attributes))
			// 	if (hasVariant) {
			// 		variant.sort_order = i;
			// 	} 
			// })
			const createNewVariants = uniqueVariant(generateVariants.concat(state.product.product_variants));
			// const createNewVariants = uniqueVariant(state.product.product_variants.concat(generateVariants));

			// console.log(createNewVariants)

			const createdVariants = [];		
			// Find deleted variant
			createNewVariants.map((variant, i) => {
				const hasVariant = variantAttributes.find(attr => JSON.stringify(variant.variant_attributes) === JSON.stringify(attr))
		
				if (!hasVariant && variant._id) {
					createdVariants.push({...variant, _destroy: true});
				} else if (hasVariant) {
					createdVariants.push({...variant, name: getValueName(state.product.name, hasVariant)});
				}
			})

			createdVariants.filter(el => !el._destroy).map((variant, i) => {
				const hasVariant = generateVariants.find(v => JSON.stringify(variant.variant_attributes) === JSON.stringify(v.variant_attributes))
				if (hasVariant) {
					variant.sort_order = i;
				} 
			})
			

			// const sortedVariants = createAndSortVariants(generateVariants, createNewVariants)

	

			// // // console.log(state.product.product_variants)
			// console.log(sortedVariants)

			const getDestroyedVariants = action.product_configurations.filter(el => {
				return !el._destroy;
			  }).length;
		  
			let type;
			if (getDestroyedVariants === 0) {
				type = 'simple';
			} else {
				type = 'variants';
			}

            return {
                ...state,
                onConfiguration: false,
				product: { ...state.product, product_type: type, product_variants: createdVariants, product_configurations: action.product_configurations},
				newConfigurations: getNumberConfigurations() !== state.product.product_configurations.length,
                configurationsErrors: null
            };  
        case types.PRODUCT_VARIANTS_UPDATED: 
            return {
                ...state,
                product: { ...state.product, product_variants: action.newVariants }
            };
		case types.PRODUCT_MODIFIER_TOGGLED: 
			return {
				...state,
				onModifier: !state.onModifier,
				// product: { ...state.product, product_price_modifiers: state.product.product_price_modifiers.length !== 0 ? state.product.product_price_modifiers : []}
			};
		case types.PRODUCT_MODIFIERS_DELETED: 
			const newProductPriceModifiers = [...state.product.product_price_modifiers];
			newProductPriceModifiers.map(item => {
				return item._destroy = true;
			}) 
			return {
				...state,
				onModifier: false,
				product: { ...state.product, product_price_modifiers: newProductPriceModifiers}
			};
		case types.PRODUCT_MODIFIER_UPDATED: 
			return {
				...state,
				product: { ...state.product, product_price_modifiers: action.newProductPriceModifiers}
			};
        case types.PRODUCT_ADVANCED_PRICE_SHOWED:
            return {
                ...state,
				onAdvancedPrice: true,
				pricesErrors: []
            };
        case types.PRODUCT_PRICES_LOADED:
		
			return {
				...state,
				customer_groups: action.payload ? action.payload.customer_groups : null
            };
        case types.PRODUCT_PRICES_DONE:
            return {
                ...state,
                product: {...state.product, product_prices: action.product_prices},
                onAdvancedPrice: false,
                pricesErrors: null
			};
		case types.PRODUCT_ADVANCED_PRICE_HIDED:

			return {
				...state,
				onAdvancedPrice: false,
				customer_groups: null
			};
		case types.PRODUCT_CREATED:
		case types.PRODUCT_EDITED:
			console.log(action.payload)
			return { 
				...state,
				inProgress: false,
				errors: action.error ? action.payload.errors : null,
				onEditConfirm: action.payload ? action.payload.edit_confirm : false,				
			};
		case types.CONFIGUATIONS_VALIDATION:
			return {
				...state,				
				configurationsErrors: action.errors
			};
		case types.PRICES_VALIDATION:
			return {
				...state,				
				pricesErrors: action.errors
			};
        // case types.FORM_VALIDATION:
		// 	return {
		// 		...state,				
		// 		errors: {...state.errors, ...action.errors}
		// 	};
		// case types.PRODUCT_EDIT_SHOWED:
		// 	return {
		// 		...state,				
		// 		onEditConfirm: true
		// 	};
		case types.PRODUCT_CONFIRM_HIDED:
			return {
				...state,				
				onEditConfirm: false
			};
		case types.PRODUCT_IMAGE_UPLOAD_REQUESTED:
			productImages = [...state.product.product_images];
			productImages.push({image: action.image, onUpload: true})
			return {
				...state,
				product: {...state.product, product_images: productImages}
			};
		case types.PRODUCT_IMAGE_UPLOADING:
			productImages = [...state.product.product_images];
			productImages[action.index] = action.image;
			return {
				...state,
				product: {...state.product, product_images: productImages}
			};
		case types.PRODUCT_IMAGE_UPLOADED:
			productImages = [...state.product.product_images];
			productImages[action.index] = action.payload;
			return {
				...state,
				product: action.payload ? {...state.product, product_images: productImages} : state.product
			};
		case types.PRODUCT_IMAGE_REMOVE_REQUESTED:
			productImages = [...state.product.product_images];
			currentRemove = productImages[action.index];
			currentRemove.onRemove = true;
			return {
				...state,
				product: {...state.product, product_images: productImages}
			};
		case types.PRODUCT_IMAGE_REMOVED:
			productImages = [...state.product.product_images];
			if (action.payload._id) {
				productImages[action.index] = action.payload;
			} else {
				productImages.splice(action.index, 1)
			}
			emptyImages = productImages.length - getDestroyedImages(productImages);
			if (emptyImages === 0) {
				productImages.push({image: ''})
			}
			return {
				...state,
				product: action.payload ? {...state.product, product_images: productImages} : state.product,
				emptyImages: getEmptyImages(productImages)
			};
		case types.PRODUCT_VARIANT_IMAGE_UPLOADING:
			newVariants = [...state.product.product_variants];	
			variantImages = [...newVariants[action.index].product_images]
			variantImages[variantImages.length - 1] = action.image;
			newVariants[action.index].product_images = variantImages;
			return {
				...state,
				product: {...state.product, product_variants: newVariants}
			};
		case types.PRODUCT_VARIANT_IMAGE_UPLOADED:
			newVariants = [...state.product.product_variants];	
			variantImages = [...newVariants[action.index].product_images]
			variantImages[variantImages.length - 1] = action.payload;
			newVariants[action.index].product_images = variantImages;
			return {
				...state,
				product: action.payload ? {...state.product, product_variants: newVariants} : state.product
			};
		case types.PRODUCT_VARIANT_IMAGE_REMOVED:
			newVariants = [...state.product.product_variants];	
			variantImages = [...newVariants[action.index].product_images]
			variantImages[action.imageIndex] = action.payload;
			if (action.payload._id) {
				variantImages[action.imageIndex] = action.payload;
			} else {
				variantImages.splice(action.imageIndex, 1)
			}
			newVariants[action.index].product_images = variantImages;
			return {
				...state,
				product: action.payload ? {...state.product, product_variants: newVariants} : state.product
			};
		case types.PRODUCT_FORM_UNLOADED:
			return {
				...state,
				categories: null,
                last_id: null,
                onConfiguration: false,
				onAdvancedPrice: false,
				newConfigurations: false,
				onModifier: false,
				onEditConfirm: false,
                product: null,
				errors: [],
				inProgress: false,
				customer_groups: null
			};
		case types.ASYNC_START:
            if ( action.subtype === types.PRODUCT_CREATED ||
                 action.subtype === types.PRODUCT_EDITED
                ) {
                return { 
                    ...state, 
                    inProgress: true };
            };
            return state;
		default:
			return state;
	}
}