import React from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import {    onLoadStoreInventories, 
            onHandleInventories,
            onUpdateStoreAdjustment } from '../../../../../../actions/storeAdjustmentActions';
import { 
        Typography,
        Paper,
        Grid,
        TextField,
        Autocomplete,
        Alert,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export default function Information() {
    const dispatch = useDispatch();
    const {store_adjustment, stores, errors} = useSelector(state => ({
        ...state.store_adjustment
    }), shallowEqual); 

    const { store_id, store_adjustment_items, note } = store_adjustment;

    function onSelectStore(value) {
        if (store_adjustment_items[0].product_id && store_adjustment_items.length >= 1) {
            dispatch(onHandleInventories(value));
		} else {
            dispatch(onLoadStoreInventories(value));
        }
    }

    function onUpdateField(event) {
		const key = event.target.name;
        const value = event.target.value;
        const newStoreAdjustment = {...store_adjustment};
        newStoreAdjustment[key] = value;
        dispatch(onUpdateStoreAdjustment(newStoreAdjustment));
    }
 
    function error(key) {
        if (errors != null && errors[`store_adjustment.${key}`] != null) {
            return errors[`store_adjustment.${key}`].msg
        }
        return null;
    }

    const storeOptions = stores.map((option) => {
        const firstLetter = option.name[0].toUpperCase();
        return {
          firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
          ...option,
        };
    });

    const storeValue = stores.find(t => t._id === store_id);

    return (
        <ContentPaper elevation={3}>
            <Grid container spacing={6} direction="column">
                <Grid item container>
                    <Grid item xs>
                        <Typography fontWeight={700} variant="h6" lineheight="normal">
                            Informasi Mutasi
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Alert severity="error">
                            Dibutuhkan
                        </Alert>
                    </Grid>
                </Grid>
                <Grid item container direction="column" spacing={5}>
                    <Grid item container spacing={8}>
                        <Grid item xs={4}>
                            <Typography lineheight="normal" fontSize={16} fontWeight={700} component="div">
                                Cabang
                            </Typography>
                            <Typography variant="body2" gutterBottom color="textSecondary">
                                Silahkan pilih cabang yang akan melakukan mutasi stok.
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Autocomplete
                                options={storeOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                                groupBy={(option) => option.firstLetter}
                                getOptionLabel={(option) => option.name}
                                selectOnFocus
                                clearOnBlur
                                filterSelectedOptions
                                disableClearable
                                onChange={(event, option) => {
                                    onSelectStore(option._id)
                                }}
                                value={storeValue ? storeValue : null}
                                isOptionEqualToValue={(option, value) => option._id === value._id}
                                renderInput={(params) => <TextField {...params} error={!!error('store_id')} helperText={error('store_id')} placeholder="Pilih cabang" variant="outlined" />}
                            />
                        </Grid> 
                    </Grid>
                    <Grid item container spacing={8}>
                        <Grid item xs={4}>
                            <Typography lineheight="normal" fontSize={16} fontWeight={700} component="div">
                                Catatan
                            </Typography>
                            <Typography variant="body2" gutterBottom color="textSecondary">
                                Masukan catatan mutasi.
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                fullWidth
                                rows={6}
                                multiline
                                name="note"
                                placeholder="Catatan"
                                value={note}
                                variant="outlined"
                                onChange={onUpdateField}
                            />
                        </Grid> 
                    </Grid>
                </Grid>
            </Grid>
        </ContentPaper>
    )
}

const ContentPaper = styled(Paper)(({theme}) => ({
    padding: 35,
    paddingTop: 32,
    marginBottom: 25
}));