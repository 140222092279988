import * as types from '../actions/actionTypes';

const defaultState = {
}

export default function employee_job_position (state = defaultState, action) {
	switch(action.type) {
		case types.EMPLOYEE_JOB_POSITIONS_LOADED:
			return {
				...state,
				employee_job_positions: action.error ? null : action.payload.employee_job_positions
			};
		case types.NEW_EMPLOYEE_JOB_POSITION_LOADED:
			return {
				...state,
				parent_job_position:  action.payload ? action.payload.parent_job_position : null, 
                employee_organizations:  action.payload ? action.payload.employee_organizations : null,
				employee_job_position: action.payload ? action.payload.employee_job_position : null,
			};
		case types.EMPLOYEE_JOB_POSITION_FIELD_UPDATED:
			return {
				...state,
				employee_job_position: {...state.employee_job_position, [action.key]: action.value}
			};
		case types.EMPLOYEE_JOB_DESCRIPTIONS_UPDATED:
			return {
				...state,
				employee_job_position: {...state.employee_job_position, job_descriptions: action.newDescriptions}
			};
		case types.EDIT_EMPLOYEE_JOB_POSITION_LOADED:
			return {
				...state,
                employee_organizations: action.payload ? action.payload.employee_organizations : null,
				employee_job_position:  action.payload ? action.payload.employee_job_position : null,
				parent_job_position: action.payload.parent_job_position
			};
		// case types.EMPLOYEE_JOB_POSITION_UPDATED:
		// 	return {
		// 		...state,
		// 		employee_job_position: action.employee_job_position
		// 	};
		// case types.EMPLOYEE_JOB_POSITION_FORM_UNLOADED:
		// 	return {
		// 		...state,
		// 		employee_job_position: null,
		// 		errors: [],
		// 		inProgress: false,
		// 		parent_id: null
		// 	};
		case types.EMPLOYEE_JOB_POSITION_CREATED:
		case types.EMPLOYEE_JOB_POSITION_EDITED:
			return {
				...state,
				inProgress: false,
				errors: action.error ? action.payload.errors : null
			};
		case types.EMPLOYEE_JOB_POSITION_FORM_UNLOADED:
			return {
				...state,
				employee_job_position: null,
				errors: [],
				inProgress: false,
				parent_id: null
			};
		case types.ASYNC_START:
			if ( action.subtype === types.EMPLOYEE_JOB_POSITION_CREATED ||
					action.subtype === types.EMPLOYEE_JOB_POSITION_EDITED
				) {
				return { 
					...state, 
					inProgress: true };
			};
			return state;
		default:
			return state;
	}
}