import * as types from './actionTypes';
import { axiosClient } from '../api';

export const onLoadStoreAdjustments = (query) => {
	const fetch = axiosClient.get(`/store-adjustments`,
	{
		params: query
	});
	return {
		type: types.STORE_ADJUSTMENTS_LOADED,
		payload: fetch
	}
}

export const onNew = () => {
	const fetch = axiosClient.get(`/store-adjustments/new`);
	return {
		type: types.NEW_STORE_ADJUSTMENT_LOADED,
		payload: fetch
	}
}

export const onEdit = (id) => {
	const fetch = axiosClient.get(`/store-adjustments/${id}/edit`);
	return {
		type: types.EDIT_STORE_ADJUSTMENT_LOADED,
		payload: fetch
	}
}

export const onSelectProduct = (product_id) => {
	const fetch = axiosClient.get(`/store-adjustments/${product_id}/product`);
	return {
		type: types.STORE_ADJUSTMENT_PRODUCT_SELECTED, 
		payload: fetch
	}
}


export const onUnload = () => {
	return {
		type: types.STORE_ADJUSTMENT_UNLOADED
	}
}

export const onLoadStoreInventories = (id) => {
	const fetch = axiosClient.get(`/stores/${id}/inventories`);
	return {
		type: types.STORE_ADJUSTMENT_INVENTORIES_LOADED,
        payload: fetch,
        id
	}
}

export const onUpdateStoreAdjustment = (store_adjustment) => {
	return {
		type: types.STORE_ADJUSTMENT_UPDATED,
		store_adjustment
	}
}

export const onHandleInventories = (id) => {
	const fetch = axiosClient.get(`/stores/${id}/inventories`);
	return {
		type: types.STORE_ADJUSTMENT_INVENTORIES_HANDLED,
        payload: fetch,
        id
	}
}

export const onSelectedProductOptions = (new_adjustment_items, id) => {
	return {
		type: types.STORE_ADJUSTMENT_ITEMS_SELECTED,
		new_adjustment_items,
		id
	}
}

export const onRestoreProductOptions = (options, id, sku) => {
	return {
		type: types.RESTORE_ADJUSTMENT_PRODUCT_OPTIONS_HANDLED,
		options,
		id,
		sku
	}
}

export const onHandleVariants = (id) => {
	return {
		type: types.STORE_ADJUSTMENT_VARIANTS_SHOWED,
		id
	}
}


export const onHideVariants = () => {
	return {
		type: types.STORE_ADJUSTMENT_VARIANTS_HIDED
	}
}

export const onSelectVariant = (variantProduct, label, value) => {
	return {
		type: types.STORE_ADJUSTMENT_VARIANT_SELECTED,
		variantProduct,
		label,
		value
	}
}

export const onHandleProductVariant = (variantProduct, label, value) => {
	return {
		type: types.STORE_ADJUSTMENT_PRODUCT_VARIANT_SELECTED,
		variantProduct,
        label,
		value
	}
}

export const onCreate = (store_adjustment) => {
	const fetch = axiosClient.post(`/store-adjustments`, {store_adjustment});
	return {
		type: types.STORE_ADJUSTMENT_CREATED, 
		payload: fetch
	}
	
}

export const onShowActivities = () => {
	return {
		type: types.STORE_ADJUSTMENT_ACTIVITIES_SHOWED
	}
}

export const onHideActivities = () => {
	return {
		type: types.STORE_ADJUSTMENT_ACTIVITIES_HIDED
	}
}

export const onShowConfirm = (action_type) => {
	return {
		type: types.STORE_ADJUSTMENT_SHOWED,
		action_type
	}
}

export const onHideConfirm = () => {
	return {
		type: types.STORE_ADJUSTMENT_HIDED
	}
}

export const onCanceled = (id, store_adjustment) => {
	const fetch = axiosClient.put(`/store-adjustments/${id}/canceled`, {store_adjustment});
	return {
		type: types.STORE_ADJUSTMENT_EDITED, 
		payload: fetch
	}
}
export const onChecked = (id, store_adjustment) => {
	const fetch = axiosClient.put(`/store-adjustments/${id}/checked`, {store_adjustment});
	return {
		type: types.STORE_ADJUSTMENT_EDITED, 
		payload: fetch
	}
}