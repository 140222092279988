import React, { useState, useEffect, useRef } from 'react';
import { 
        Typography,
        Paper,
        Box,
        Grid,
        TextField,
        FormGroup,
        FormControlLabel,
        Switch,
        Alert,
        Chip
} from '@mui/material';
import { styled } from '@mui/material/styles';

export default function Manage({product, autoSKU, onChange, onToggleChange, onUpdateUOM, onToggle, error}) {
    const unitRef = useRef(null); 
    const { sku, status, uom, product_option, product_variants } = product;
    const { product_option_entities } = product_option 

    function onPressEnter(event) {
        const value = event.target.value;
        if(event.keyCode == 13 && !product.uom){
            onUpdateUOM(value.toUpperCase());
        }
    }

    function onBlur(event) {
        const value = event.target.value;
        if (!product.uom) {
            onUpdateUOM(value.toUpperCase());
        }
    }

    function onDeleteUOM() {
        onUpdateUOM("");
        setTimeout(function() {
            unitRef.current.focus()
        }, 100);
    }

    function getDestroyedOptions() {
		return product_option_entities.filter(el => {
	    	return el._destroy !== true;
	  	}).length;
	}

    function getDestroyedVariants() {
        return product_variants.filter(el => {
          return el._destroy !== true;
        }).length;
    }

    function skuField() {
        if (getDestroyedVariants() !== 0 || getDestroyedOptions() !== 0) {
            return null
        } else {
            return (
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        error={!!error('sku')}
                        name="sku"
                        placeholder="SKU"
                        value={sku}
                        onChange={onChange.bind(this)}
                        helperText={error('sku')}
                        variant="outlined"
                        disabled={autoSKU}
                    />
                </Grid>
            )
        }
    }

    function unitField() {
        if (getDestroyedVariants() !== 0 || getDestroyedOptions() !== 0) {
            return null
        } else {
            return (
                <Grid item container spacing={8}>
                    <Grid item xs={4} container>
                        <Grid item xs>
                            <Typography fontSize={16} fontWeight={700} component="div">
                                Satuan
                            </Typography>
                            <Typography variant="body2" gutterBottom color="textSecondary">
                                Masukan satuan barang seperti PCS / BTG dan sebagainya.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={8}>
                        {
                            !!uom ? <Chip
                            tabIndex={-1}
                            size="medium"
                            label={uom}
                            sx={{marginRight: '5px', marginTop: '10px'}}
                            onDelete={onDeleteUOM}
                            variant="outlined"
                            /> :  
                            <TextField
                                error={!!error('uom')}
                                placeholder="Satuan"
                                inputRef={unitRef}
                                helperText={error('uom')}
                                variant="outlined"
                                onKeyDown={onPressEnter}
                                onBlur={onBlur}
                            />
                        }
                       
                    </Grid> 
                </Grid>
            )
        }
    }

    function statusField() {
        if (getDestroyedVariants() !== 0 || getDestroyedOptions() !== 0) {
            return null
        } else {
            return (
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            <Box fontSize={16} fontWeight={700}>
                                Status Produk
                            </Box>
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Aktifkan produk agar dapat dicari oleh calon pembeli.
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                <Switch
                                    checked={status}
                                    onChange={onToggleChange.bind(this, !status)}
                                    name="status"
                                    color="primary"
                                />
                                }
                                label={status ? 'Aktif' : 'Tidak Aktif'}
                            />
                        </FormGroup>
                    </Grid> 
                </Grid>
            )
        }
    }
    return (
        <ContentPaper elevation={3}>
            <Grid container spacing={6} direction="column">
                <Grid item container>
                    <Grid item xs>
                        <Typography variant="h6" fontWeight={700}>
                            Pengelolaan Produk
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Alert severity="error">
                            Wajib
                        </Alert>
                    </Grid>
                </Grid>
                <Grid item container direction="column" spacing={5}>
                    {unitField()}
                    <Grid item container spacing={8}>
                        <Grid item xs={4} container>
                            <Grid item xs>
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    SKU (Stock Keeping Unit)
                                </Typography>
                                <Typography variant="body2" gutterBottom color="textSecondary">
                                    Auto Generate ketika kategori produk dipilih. SKU ini akan di cetak pada label produk dengan barcode QR Code.
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={8} container spacing={3}>
                            { skuField() }
                            {!product.id && <Grid item xs={6}>
                                <FormGroup row sx={{paddingTop: '10px'}}>
                                    <FormControlLabel
                                        control={
                                        <Switch
                                            checked={autoSKU}
                                            onChange={onToggle.bind(this, !autoSKU)}
                                            name="autoSKU"
                                            color="primary"
                                        />
                                        }
                                        label={autoSKU ? 'Auto SKU' : 'Manual'}
                                    />
                                </FormGroup>
                            </Grid>}
                        </Grid> 
                    </Grid>
                    {statusField()}
                </Grid>
            </Grid>
        </ContentPaper>
    )
}


const ContentPaper = styled(Paper)(({theme}) => ({
    padding: 35,
    paddingTop: 32,
    marginBottom: 25
}));