import React from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as purchaseOrderActions from '../../../../../../actions/purchaseOrderActions';
import { 
        Typography,
        Box,
        Paper,
        Grid,
        TextField,
        Divider
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { PublishedWithChangesTwoTone, AccessTimeTwoTone, LocalShippingTwoTone } from '@mui/icons-material';
import { green, red, grey } from '@mui/material/colors';
import moment from 'moment';
import 'moment/locale/id' 
import NumberFormat from 'react-number-format';
import { useTheme } from '@mui/material/styles';



export default function Information() {    
    const dispatch = useDispatch();
    const {store_adjustment} = useSelector(state => ({
        ...state.store_adjustment
    }), shallowEqual); 
    const theme = useTheme();
    const { increment_id, 
            total_adjustment_qty,
            adjustment_type,
            store_name,
            created_at, 
            grand_total,
            status,
            status_title } = store_adjustment;
            
    return (
        <ContentPaper elevation={3}>
            <Box sx={{display: 'flex', padding: '35px', paddingTop: '25px', paddingBottom: '25px'}}>
                <Typography fontWeight={700} sx={{display: 'flex'}}>
                <PublishedWithChangesTwoTone style={{ fontSize: 18, marginRight: '5px', marginTop: '3px' }} color="primary" /> {status_title}
                </Typography>
                <Slash>
                    /
                </Slash>
                <Typography fontWeight={700} color="primary">
                    {increment_id}
                </Typography>
                <Slash>
                    /
                </Slash>
                <Typography sx={{display: 'flex'}}>
                    <AccessTimeTwoTone style={{ fontSize: 18, marginRight: '5px', marginTop: '3px' }} color="primary" /> {moment(created_at).format('LL')}, {moment(created_at).format('LT')} WIB
                </Typography>
            </Box>
            <Box>
                <Divider />
            </Box>
            <Box sx={{padding: '35px'}}>
                <Grid container spacing={3}>
                    <Grid item xs={3} container direction="column">
                        <Grid item>
                            <Typography color={grey[500]} fontWeight={500} fontSize={13} variant="body1">
                                Jenis Penyesuaian
                            </Typography>
                        </Grid>
                        <Grid item>
                            {status === "requested" ? (
                                    <Typography fontWeight={700} fontSize={16} variant="h6">
                                    -
                                    </Typography>
                                ) : (
                                    <Typography fontWeight={700} fontSize={16} variant="h6"
                                    color={adjustment_type === "gain" ? theme.palette.success.main : theme.palette.error.main}>
                                       {adjustment_type === "gain" ? "Gain" : "Loss"}
                                    </Typography>
                                )}
                        </Grid>
                    </Grid>
                    <Grid item xs={3} container direction="column">
                        <Grid item>
                            <Typography color={grey[500]} fontWeight={500} fontSize={13} variant="body1">
                                Total Gain / Loss
                            </Typography>
                        </Grid>
                        <Grid item>
                                {status === "requested" ? (
                                    <Typography fontWeight={700} fontSize={16} variant="h6">
                                    -
                                    </Typography>
                                ) : (
                                    <NumberFormat
                                        value={grand_total}
                                        displayType="text"
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        prefix="Rp "
                                        isNumericString
                                        decimalScale={2}
                                        renderText={(value) => (
                                            <Typography fontWeight={700} fontSize={16} variant="h6"
                                                color={adjustment_type === "gain" ? theme.palette.success.main : theme.palette.error.main}>
                                                {value}
                                            </Typography>
                                        )}
                                    />
                                )}
                        </Grid>
                    </Grid>
                    <Grid item xs={3} container direction="column">
                        <Grid item>
                            <Typography color={grey[500]} fontWeight={500} fontSize={13} variant="body1">
                                Cabang
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography fontWeight={700} sx={{display: 'flex'}}>
                                {store_name}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={3} container direction="column">
                        <Grid item>
                            <Typography color={grey[500]} fontWeight={500} fontSize={13} variant="body1">
                                Aktivitas
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography gutterBottom variant="h6" color="primary" fontSize={14} style={{textTransform: 'capitalize', cursor: 'pointer'}}>
                                Lihat Aktivitas
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </ContentPaper>
    )
}

const ContentPaper = styled(Paper)(({theme}) => ({
    marginBottom: 25
}));


const Slash = styled(Typography)(({theme}) => ({
    margin: '0 6px',
    color: 'var(--N75, #E5E7E9)'
}));