import React, { useRef, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as orderCanceledActions from '../../../../../../actions/orderCanceledActions';
import { 
        InputBase,
        Typography,
        Box,
        Paper,
        Grid,
        TextField,
        InputAdornment,
        Alert,
        Divider,
        Button,
        Tooltip,
        Switch,
        Table,
        TableHead,
        TableBody,
        TableRow,
        TableCell
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { MoveDownTwoTone, ReceiptTwoTone, StickyNote2TwoTone, HelpCenterTwoTone, HelpTwoTone, PercentTwoTone, NumbersTwoTone } from '@mui/icons-material';
import NumberFormat from 'react-number-format';
import { tooltipClasses } from '@mui/material/Tooltip';

const QuantityFormat = React.forwardRef((props, ref) => (
    <NumberFormat
        {...props.other}
        onValueChange={(values) => {
          props.onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        disabled={props.disabled}
        customInput={TextField}
        placeholder={props.placeholder}
        value={props.value}
        helperText={props.helperText}
        error={props.error}
        onFocus={props.onFocus}
        decimalScale={2}
        fullWidth={props.fullWidth}
        onBlur={props.onBlur}
        thousandSeparator={"."}
        decimalSeparator={","}
        allowNegative={false}
        allowedDecimalSeparators={false}
        isNumericString
        InputProps={props.InputProps}
      />
));


const CurrencyFormat = React.forwardRef((props, ref) => (
    <NumberFormat
        {...props.other}
        customInput={TextField}
        InputProps={{
            startAdornment: <InputAdornment position="start">
                <Box fontWeight={700}>
                    Rp
                </Box>
            </InputAdornment>,
            inputProps: {
                style: {
                    textAlign: 'right', // Align text to the right
                },
            },
        }}
        placeholder="0"
        disabled={props.disabled}
        helperText={props.helperText}
        error={props.error}
        value={props.value}
        onFocus={props.onFocus}
        decimalScale={2}
        fullWidth={props.fullWidth}
        onBlur={props.onBlur}
        thousandSeparator={"."}
        decimalSeparator={","}
        allowNegative={false}
        allowedDecimalSeparators={false}
        isNumericString
      />
));

const PercentageFormat = React.forwardRef((props, ref) => (
    <NumberFormat
        {...props.other}
        onValueChange={(values) => {
          props.onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        customInput={TextField}
        InputProps={{
            startAdornment: <InputAdornment position="start">
                <Box fontWeight={700}>
                    {props.checked ? '%' : 'Rp' }
                </Box>
            </InputAdornment>,
            inputProps: {
              style: {
                textAlign: 'right', // Align text to the right
              },
            },
          }}
        disabled={props.disabled}
        placeholder="0"
        fullWidth={props.fullWidth}
        helperText={props.helperText}
        error={props.error}
        value={props.value}
        onFocus={props.onFocus}
        decimalScale={2}
        onBlur={props.onBlur}
        thousandSeparator={"."}
        decimalSeparator={","}
        allowNegative={false}
        allowedDecimalSeparators={false}
        isNumericString
      />
));

export default function Product({error}) {   
    const dispatch = useDispatch();
    const {order_canceled, errors} = useSelector(state => ({
        ...state.order_canceled
    }), shallowEqual); 
    const [totalOpen, setTotalOpen] = React.useState(false);
    const [currentFocus, setCurrentFocus] = useState("");

    
    const { status, 
        increment_id, 
        store, 
        created_at, 
        status_title,
        note,
        reason_title,
        rounding,
        rounding_type,
        subtotal,
        grand_total, 
        order_canceled_items, 
    } = order_canceled;

    const handleTotalClose = () => {
        setTotalOpen(false);
    };
    
    const handleTotalOpen = () => {
        setTotalOpen(true);
    };
    function onUpdateField(index, event) {
        if (currentFocus === "canceled") {
            const key = event.target.name;
            const value = event.target.value;
            const newOrderCanceled = {...order_canceled};
            const currentItem = newOrderCanceled.order_canceled_items[index];
            if (value <= currentItem.quantity) {
                currentItem[key] = value;
                currentItem.row_total = Number(currentItem.net_price) * Number(currentItem.canceled_qty);
            }
        
            onUpdateTotal(newOrderCanceled)
        }
    }

    function onUpdateTotal(orderCanceled) {
        var { order, order_canceled_items, due_total, net_paid, net_grand_total, canceled_total, refunded_total } = orderCanceled;
        const orderedQty = order.ordered_qty - order.canceled_qty
        const cancelableQty = order_canceled_items.reduce((prev,next) => Number(prev) + Number(next.canceled_qty),0);
        const subtotal = getSubtotal(orderCanceled)
        orderCanceled.subtotal = subtotal;
        if ((orderedQty - cancelableQty) === 0) {
            if (order.subtotal === (subtotal + refunded_total)) {
                orderCanceled.rounding = order.rounding
                orderCanceled.rounding_type = order.rounding_type
                if (order.rounding_type == "loss") {
                    orderCanceled.grand_total = subtotal - order.rounding
                } else {
                    orderCanceled.grand_total = subtotal + order.rounding
                }
            } else {
                orderCanceled.rounding_type = ""
                orderCanceled.rounding = 0
                orderCanceled.grand_total = subtotal
            }
        } else {
            orderCanceled.rounding_type = ""
            orderCanceled.rounding = 0
            orderCanceled.grand_total = subtotal
        }
        dispatch(orderCanceledActions.onUpdateOrderCanceled(orderCanceled));
    }

    function onUpdateNoteField(event) {
        if (currentFocus === "note") {
            const key = event.target.name;
            const value = event.target.value;
            const newOrderCanceled = {...order_canceled};
            newOrderCanceled[key] = value;
            dispatch(orderCanceledActions.onUpdateOrderCanceled(newOrderCanceled));
        }
    }

    function onFocus(key) {
        setCurrentFocus(key)
    }

    function onBlur(key) {
        setCurrentFocus(null)
    }

    function getSubtotal(orderCanceled) {
        var { order, order_canceled_items, due_total, net_paid, net_grand_total, canceled_total, refunded_total } = orderCanceled;
        var subtotal = 0.0
        if (order.order_type === "invoice") {
            const newCanceledTotal = Number(order_canceled_items.reduce((prev,next) => Number(prev) + Number(next.row_total),0));
            
            var canceledTotal = (Number(newCanceledTotal) + Number(canceled_total));
            if (order.rounding_type === "loss") {
                canceledTotal = (Number(newCanceledTotal) + Number(canceled_total) - order.rounding)
            } else if (order.rounding_type === "gain") {
                canceledTotal = (Number(newCanceledTotal) + Number(canceled_total) + order.rounding)
            } 

            const refundedTotal = Number(refunded_total)
            const dueTotal = Number(due_total);
            const netPaid = Number(net_paid);
            const netGrandTotal = Number(net_grand_total);
            const netTotal = netGrandTotal - canceledTotal;

            if (dueTotal >= canceledTotal) {
                console.log("1")
                subtotal = 0
            } else if (dueTotal == 0.0 && !(netTotal === 0.0)) {
                console.log("2")
                subtotal = newCanceledTotal;
            } else if (dueTotal <= canceledTotal && !(netTotal === 0.0)) {
                console.log("333")
                if ((canceledTotal + netPaid - netGrandTotal) < 0.0) {
                    console.log("3")
                    subtotal = 0
                } else {
                    console.log("4")
                    subtotal = canceledTotal + netPaid - netGrandTotal;
                }
            } else if (dueTotal <= canceledTotal && netTotal === 0.0) {
                console.log("5")
                subtotal = netPaid - refundedTotal;
            } else if (canceledTotal === netGrandTotal) {
                console.log("6")
                subtotal = netPaid;
            } else {
                console.log("7")
                subtotal = newCanceledTotal;
            }

        } else {
            subtotal = order_canceled_items.reduce((prev,next) => Number(prev) + Number(next.row_total),0);
        }

        return subtotal
    }

    function Requested(item) {
        if (status === 'processing') {
            return (
                <QuantityFormat
                    fullWidth
                    name="quantity"
                    value={item.quantity}
                    disabled
                    variant="outlined"
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{item.uom}</InputAdornment>,
                        inputProps: {
                          style: {
                            textAlign: 'right', // Align text to the right
                          },
                        },
                    }}
                />
            )
        }

        return (
            <NumberFormat
                value={item.quantity}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator={","}
                isNumericString
                decimalScale={2}
                renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                {value} {item.uom}
                </Typography>}
            />
        )
    }

    function Canceled(item, index) {
        if (status === 'processing') {
            return (
                <QuantityFormat
                    fullWidth
                    error={!!error(`order_canceled_items[${index}]`)}
                    // helperText={error(`order_canceled_items[${index}]`)}
                    name="canceled_qty"
                    value={item.canceled_qty}
                    onFocus={onFocus.bind(this, "canceled")}
                    onBlur={onBlur.bind(this)}
                    variant="outlined"
                    onChange={onUpdateField.bind(this, index)}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{item.uom}</InputAdornment>,
                        inputProps: {
                          style: {
                            textAlign: 'right', // Align text to the right
                          },
                        },
                    }}
                />
            )
        }

        return (
            <NumberFormat
                value={item.canceled_qty}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator={","}
                isNumericString
                decimalScale={2}
                renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                    {value ? value : 0} {item.uom}
                </Typography>}
            />
        )
    }

    function UnitPrice(item) {
        if (status === 'processing') {
            return (
                <CurrencyFormat
                    name="unit_price"
                    fullWidth
                    disabled
                    value={item.unit_price}
                    inputProps={{min: 0, style: { textAlign: 'right' }}}
                    variant="outlined"
                />
            )
        }

        return (
            <NumberFormat
                value={item.unit_price}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix="Rp "
                isNumericString
                decimalScale={2}
                renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                {value}
                </Typography>}
            />
        )
    }

    function Discount(item) {
        if (status === 'processing') {
            return (
                <PercentageFormat
                    fullWidth
                    disabled
                    value={item.discount_type === 'percent' ? item.discount_percent : item.discount_amount}
                    inputProps={{min: 0, style: { textAlign: 'right' }}}
                    variant="outlined"
                />
            )
        }

        return (
            <NumberFormat
                value={item.discount_type === 'percent' ? item.discount_percent : item.discount_amount}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix={item.discount_type === 'percent' ? "" : "Rp "}
                suffix={item.discount_type === 'percent' ? "%" : ""}
                isNumericString
                decimalScale={2}
                renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                {value}
                </Typography>}
            />
        )
    }

    function NetPrice(item) {
        if (status === 'processing') {
            return (
                <CurrencyFormat
                    fullWidth
                    name="net_price"
                    value={item.net_price}
                    disabled
                    variant="outlined"
                />
            )
        }

        return (
            <NumberFormat
                value={item.net_price}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix="Rp "
                isNumericString
                decimalScale={2}
                renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                {value}
                </Typography>}
            />
        )
    }

    function Subtotal(item) {
        if (status === 'processing') {
            return (
                <CurrencyFormat
                    fullWidth
                    disabled
                    name="row_total"
                    value={item.row_total}
                    inputProps={{min: 0, style: { textAlign: 'right' }}}
                    variant="outlined"
                />
            )
        }

        return (
            <NumberFormat
                value={item.row_total}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix="Rp "
                isNumericString
                decimalScale={2}
                renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                {value}
                </Typography>}
            />
        )
    }

    function total() {
        return (
            <Box>
                <Grid container spacing={1} direction="column">
                    <Grid item container justifyContent="flex-end">
                        <Grid item xs>
                            <Typography fontWeight={500}>
                                Subtotal
                            </Typography>
                        </Grid>
                        <Grid item>
                            <NumberFormat
                                value={subtotal}
                                displayType="text"
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix="Rp "
                                isNumericString
                                decimalScale={2}
                                renderText={(value) => <Typography fontWeight={500}>
                                {value}
                            </Typography>}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container justifyContent="flex-end">
                        <Grid item xs>
                            <Typography fontWeight={500}>
                                Biaya Lainnya
                            </Typography>
                        </Grid>
                        <Grid item>
                            <NumberFormat
                                value={rounding}
                                displayType="text"
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix="Rp "
                                isNumericString
                                decimalScale={2}
                                renderText={(value) => <Typography fontWeight={500}>
                                {rounding_type === "loss" ? "-" + value : value}
                            </Typography>}
                            />
                        </Grid>
                    </Grid>
                    <Grid item >
                        <Divider />
                    </Grid>
                    <Grid item container justifyContent="flex-end">
                        <Grid item xs>
                            <Typography fontWeight={700}>
                                Total Pengembalian
                            </Typography>
                        </Grid>
                        <Grid item>
                            <NumberFormat
                                value={grand_total}
                                displayType="text"
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix="Rp "
                                isNumericString
                                decimalScale={2}
                                renderText={(value) => <Typography fontWeight={700}>
                                {value}
                            </Typography>}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    function Footer() {
        return (
            <Grid container spacing={3} justifyContent="flex-end">
                <Grid item>
                    <CommentField
                        value={note}
                        onChange={onUpdateNoteField}
                        >
                        <IconWrapper>
                            <StickyNote2TwoTone />
                        </IconWrapper>
                        <StyledInputBase
                            name="note"
                            disabled={status === 'canceled' || status === 'completed'}
                            placeholder="Catatan…"/>
                    </CommentField>
                </Grid>
                <Grid item container xs spacing={3} alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <Typography sx={{display: 'flex'}} lineheight="normal" fontSize={16} fontWeight={700}>
                            Total Pengembalian
                            <LightTooltip open={totalOpen} placement='right' onClose={handleTotalClose} onOpen={handleTotalOpen} title={total()}>
                                <HelpTwoTone color="primary" />
                            </LightTooltip>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <NumberFormat
                            value={grand_total}
                            displayType="text"
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix="Rp "
                            isNumericString
                            decimalScale={2}
                            renderText={(value) => <Typography lineheight="normal" fontSize={16} fontWeight={700}>
                            {value}
                        </Typography>}
                        />
                    </Grid>
                </Grid>
            </Grid>
        )
    }

   

    let titleAlign = 'right'

    let processingStatus = status === 'processing';
    // let orderedStatus = status === 'ordered';
    // let receivedStatus = status === 'received';
    // // let approvedStatus = status === 'approved';
    // let onDueStatus = status === 'on_due';
    // let canceledStatus = status === 'canceled';
    // let paidStatus = status === 'paid';
    // let partiallyPaidStatus = status === 'partially_paid';

    // let tableGrid = '30% 12% 12% 12% 12% 12% 12% 12% 12% 15%'
    let tableGrid = '30% 12% 12% 15% 12% 17% 17%'
    let maxName = 35
    if (processingStatus) {
        maxName = 40
        tableGrid = '30% 13% 13% 16% 16% 16% 19%'
       
    } 
        
    return (
        <ContentPaper elevation={3}> 
            <Grid container spacing={3} direction="column">
                <Grid item container>
                    <Grid item xs>
                        <Typography fontWeight={700} variant="h6" lineheight="normal">
                            Informasi Produk
                        </Typography>
                    </Grid>
                    <Grid item>
                        {/* {requestedStatus || orderedStatus && <Grid item>
                            <Alert severity="error">
                                Dibutuhkan
                            </Alert>
                        </Grid>} */}
                    </Grid>
                </Grid>
                <Grid item>
                    <Table sx={{
                        display: 'grid',
                        overflow: 'auto hidden',
                        gridTemplateColumns: tableGrid,
                        }}>
                        <TableHead sx={{display: 'contents'}}>
                            <TableRow sx={{display: 'contents'}}>
                                <TableCell sx={{
                                    paddingLeft: 0,
                                    position: 'sticky',
                                    zIndex: 1,
                                    left: 0,
                                    background: '#fff',
                                    }}>
                                   Nama Barang
                            </TableCell>
                            <TableCell align={titleAlign}>
                                Diajukan
                            </TableCell>
                            <TableCell align={titleAlign}>
                                Dibatalkan
                            </TableCell>
                            <TableCell align={titleAlign}>
                                Harga Jual
                            </TableCell>
                            <TableCell align={titleAlign}>
                                Diskon
                            </TableCell>
                            <TableCell align={titleAlign}>
                                Harga Netto
                            </TableCell>
                            <TableCell align={titleAlign} sx={{paddingRight: 0}}>
                                Subtotal
                            </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{display: 'contents'}}>
                        {
                            order_canceled_items.map((item, index) => {
                                return (
                                    <TableRow key={index} sx={{display: 'contents'}}>
                                        <TableCell sx={{
                                            paddingLeft: 0,
                                            position: 'sticky',
                                            zIndex: 1,
                                            display: 'flex',
                                            alignItems: 'center',
                                            left: 0,
                                            background: '#fff'
                                            }}
                                            tabIndex={-1}
                                            >
                                            <Tooltip
                                                title={item.name}
                                                arrow
                                            >
                                                <Box>
                                                    <Typography sx={{ fontWeight: 700 }} fontSize={16} lineHeight="normal">
                                                        {item.name.length > maxName
                                                            ? `${item.name.substring(0, maxName)}...`
                                                            : item.name}
                                                    </Typography>
                                                    <Typography fontSize={11} lineHeight="normal" sx={{ color: '#999' }}>
                                                        SKU #{item.sku}
                                                    </Typography>
                                                </Box>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align={titleAlign}>
                                            {Requested(item)}
                                        </TableCell>
                                        <TableCell align={titleAlign}>
                                            {Canceled(item, index)}
                                        </TableCell>
                                        <TableCell align={titleAlign}>
                                            {UnitPrice(item)}
                                        </TableCell>
                                        <TableCell align={titleAlign}>
                                            {Discount(item)}
                                        </TableCell>
                                        <TableCell align={titleAlign}>
                                            {NetPrice(item)}
                                        </TableCell>
                                        <TableCell align={titleAlign} sx={{paddingRight: 0}}>
                                            {Subtotal(item)}
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        }
                        </TableBody>
                    </Table>
                  
                </Grid>
            </Grid>
            <Divider sx={{marginTop: '25px', marginBottom: '30px'}} />
            {Footer()}
        </ContentPaper>
    )
}

const ContentPaper = styled(Paper)(({theme}) => ({
    padding: 35,
    paddingTop: 32,
    marginBottom: 25
}));

const CommentField = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    // [theme.breakpoints.up('sm')]: {
    //   marginLeft: theme.spacing(1),
    //   width: 'auto',
    // },
}));

const IconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.main
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }));

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} sx={{marginLeft: '5px'}} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      width: 325,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
      padding: 15
    },
  }));

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 60,
    height: 34,
    padding: 12,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(22px)',
        '& + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        },
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 20 / 2,
    },
}));

const SwitchBox = styled(Box)(({ theme }) => ({
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    backgroundColor: '#001e3c',
    width: 30,
    height: 30,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}));