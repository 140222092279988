import * as types from './actionTypes';
import { axiosClient } from '../api';



// export const onLoadUserInvoice = (id) => {
// 	const fetch = axiosClient.get(`/user_configurations/${id}/invoice`);
// 	return {
// 		type: types.USER_CONFIGURATION_LOADED,
// 		payload: fetch
// 	}
// }

export const onLoadUserCashier = (id) => {
	const fetch = axiosClient.get(`/user_configurations/${id}/cashier`);
	return {
		type: types.USER_CONFIGURATION_LOADED,
		payload: fetch
	}
}

export const onUpdateUserConfiguration = (user_configuration) => {
	return {
		type: types.USER_CONFIGURATION_UPDATED,
		user_configuration
	}
}

export const onUnloadUserConfiguration = () => {
	return {
        type: types.USER_CONFIGURATION_UNLOADED
	}
}

export const onEditUserConfigurationCashier = (id, user_configuration) => {
	const fetch = axiosClient.put(`/user_configurations/${id}/cashier`, {user_configuration});
	return {
		type: types.USER_CONFIGURATION_EDITED,
		payload: fetch
	}
}

export const onLoadUserInvoice = (id) => {
	const fetch = axiosClient.get(`/user_configurations/${id}/invoice`);
	return {
		type: types.USER_CONFIGURATION_LOADED,
		payload: fetch
	}
}

export const onEditUserConfigurationInvoice = (id, user_configuration) => {
	const fetch = axiosClient.put(`/user_configurations/${id}/invoice`, {user_configuration});
	return {
		type: types.USER_CONFIGURATION_EDITED,
		payload: fetch
	}
}

export const onLoadUserDelivery = (id) => {
	const fetch = axiosClient.get(`/user_configurations/${id}/delivery`);
	return {
		type: types.USER_CONFIGURATION_POSITION_LOADED,
		payload: fetch
	}
}

export const onSelectEmployeeOrganization = (user_configuration) => {
	const fetch = axiosClient.get(`/user_configuration/job-positions`, {
		params: {id: user_configuration.user_configuration_delivery.employee_organization_id}
	});
	return {
		type: types.USER_CONFIGURATION_ORGANIZATION_SELECTED,
		user_configuration,
        payload: fetch
	}
}

export const onEditUserConfigurationDelivery = (id, user_configuration) => {
	const fetch = axiosClient.put(`/user_configurations/${id}/delivery`, {user_configuration});
	return {
		type: types.USER_CONFIGURATION_EDITED,
		payload: fetch
	}
}



export const onHideNotice = () => {
	return {
        type: types.NOTICE_HIDED
	}
}
