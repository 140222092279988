import React, {useState, useEffect} from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as productActions from '../../../../../../actions/productActions';
import { 
        Alert,
        Typography,
        Paper,
        Box,
        Grid,
        IconButton,
        CircularProgress,
        LinearProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {ImageRounded, DeleteForeverRounded} from '@mui/icons-material';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";


const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};
  
const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    
    left: 0,
    margin: 0,
    padding: 0,
    // styles we need to apply on draggables
    ...draggableStyle
});


export default function Image({product, error}) {
    const dispatch = useDispatch();
    const { product_images } = product;
    const {emptyImages} = useSelector(state => ({
        ...state.product
    }), shallowEqual); 
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        if (product_images) {
            
            product_images.map((image, index) => {
                if (image.onUpload) {
                    image.onUpload = false;
                    dispatch(productActions.onUpload(image, index, (event) => {
                        image.progress = Math.round((event.loaded * 100) / event.total);
                        image.onUploading = true;
                        dispatch(productActions.onUploading(image, index))
                    }));
                }

                if (image.onRemove) {
                    dispatch(productActions.onRemove(image, index));
                }
            })
        }
     },[product_images]);
   

    function onAttachImages(e) {
        e.preventDefault();
        const files = Array.from(e.target.files);
        

        const uploadedLength = getNumberImages() + files.length;
        const uploadFiles = 5 - getNumberImages();
        if (uploadedLength > 5) {
            setHasError(true);
            files.splice(uploadFiles, files.length)
        }
        
        for (let i = 0; i < files.length; i++) {
            let file = files[i]
            const reader = new FileReader();
            
            const emptyData = product_images.find(el => !el.image);
            if (emptyData) {
                product_images.pop();
            }
            reader.onloadend = () => {
                emptyImages.shift();
                dispatch(productActions.onUploadRequest(reader.result));
            }
            reader.readAsDataURL(file);
        
        }
    }

    function onRemoveImage(data, index) {        
        dispatch(productActions.onRemoveRequest(data, index));
    }
      

    function getNumberImages() {
        return product_images.filter(el => {
          return el.image && !el._destroy;
        }).length;
    }

    function getDestroyedImages() {
		return product_images.filter(el => {
	    	return el._destroy === true;
	  	}).length;
	}

    function onDragEnd(result) {
		
		// dropped outside the list
		if (!result.destination) {
		  return;
		}
	
		const imagesReorder = reorder(
            product_images,
		    result.source.index,
		    result.destination.index
		);
		
		const newImages = [];
        imagesReorder.map((image, index)=> {
            
			const newImage = {...image, sort_order: index};
			return newImages.push(newImage)
		})

        dispatch(productActions.onUpdateImages(newImages));
    }

 
    let imgClass = 'imageEmpty';
    let error_class = 'hasError';
    if (!!error('product.product_images')) {
        imgClass = imgClass.concat(' ', error_class)
    }

    return (
        <ContentPaper elevation={3}>
            <Grid container spacing={6} direction="column">
                <Grid item container>
                    <Grid item xs>
                        <Typography fontWeight={700} variant="h6">
                                Foto Produk
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            Format gambar .jpg .jpeg .png dan ukuran minimum 300 x 300px (Untuk gambar optimal gunakan ukuran minimum 700 x 700 px)
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Alert severity="error">
                            Wajib
                        </Alert>
                    </Grid>
                </Grid>
                <Grid item container spacing={5} direction="column">
                    <Grid item>
                        <Box display="flex" justifyContent="center">
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable" direction="horizontal">
                                {(provided, snapshot) => (
                                    <Box display="flex"
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        >
                                    {
                                        product_images.sort((a, b) => a.sort_order - b.sort_order).map((data, index) => {
                                            if (data._destroy) {
                                                return null;
                                            }
                                            if (data.onRemove) {
                                                return (
                                                    <Draggable key={index} draggableId={index.toString()} isDragDisabled={true}  index={index}>
                                                        {(provided, snapshot) => (
                                                            <ImageBox 
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={getItemStyle(
                                                                    snapshot.isDragging,
                                                                    provided.draggableProps.style
                                                                )}>
                                                                <ImageAttachedPaper>
                                                                    <ImagePreviewBox>
                                                                        <Box component="img" alt={data.image} src={data.image} />
                                                                        <Box sx={{
                                                                            position: 'absolute', 
                                                                            display: 'flex',
                                                                            justifyContent: 'center',
                                                                            alignItems: 'center',
                                                                            width: '100%',
                                                                            height: '100%'}}>
                                                                            <Box sx={{
                                                                                position: 'absolute',
                                                                                background: '#fff',
                                                                                opacity: 0.45,
                                                                                width: '100%',
                                                                                height: '100%'
                                                                            }} />
                                                                            <Box sx={{ display: 'flex' }}>
                                                                                <CircularProgress />
                                                                            </Box>
                                                                        </Box>
                                                                    </ImagePreviewBox>
                                                                </ImageAttachedPaper>

                                                            </ImageBox>
                                                        )}
                                                    </Draggable>
                                                )
                                            }
                                            if (data.onUploading) {
                                                return (
                                                    <Draggable key={index} draggableId={index.toString()} isDragDisabled={true}  index={index}>
                                                        {(provided, snapshot) => (
                                                            <ImageBox 
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={getItemStyle(
                                                                    snapshot.isDragging,
                                                                    provided.draggableProps.style
                                                                )}>
                                                                <ImageAttachedPaper>
                                                                    <ImagePreviewBox>
                                                                        <Box component="img" alt={data.image} src={data.image} />
                                                                        <Box sx={{ width: '80%', position: 'absolute', bottom: '15px' }}>
                                                                            <LinearProgress sx={{height: '8px', borderRadius: '35px'}} variant="determinate" value={data.progress || 0} />
                                                                        </Box>
                                                                    </ImagePreviewBox>
                                                                </ImageAttachedPaper>

                                                            </ImageBox>
                                                        )}
                                                    </Draggable>
                                                )
                                            }
                                            if (data.image) {
                                                return (
                                                    
                                                    <Draggable key={index} draggableId={index.toString()} index={index}>
                                                        {(provided, snapshot) => (
                                                            <ImageBox 
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={getItemStyle(
                                                                    snapshot.isDragging,
                                                                    provided.draggableProps.style
                                                                )}
                                                            >
                                                                <ImageAttachedPaper>
                                                                    <ImagePreviewBox>
                                                                        <DeleteIconButton size="small" 
                                                                            color="inherit" aria-label="menu" onClick={() => {
                                                                            onRemoveImage(data, index);
                                                                        }} >
                                                                            <DeleteForeverRounded style={{ color: '#999' }} />
                                                                        </DeleteIconButton>
                                                                        <Box component="img" alt={data.image} src={data.image} />
                                                                        {index === getDestroyedImages() && <PrimaryTypography fontWeight={500} fontSize={12} variant="h6" >
                                                                                Utama
                                                                        </PrimaryTypography>}
                                                                    </ImagePreviewBox>
                                                                </ImageAttachedPaper>
                                                            </ImageBox>
                                                        )}
                                                    </Draggable>
                                                    
                                                )
                                            }
                                            return null;
                                        })
                                    }
                                    {provided.placeholder}
                                    </Box>
                                )}
                                </Droppable>
                            </DragDropContext>
                            {
                                emptyImages.map((data, index) => {
                                    return (
                                        <ImageBox key={index}>
                                            <ImagePaper >
                                                <ImageEmptyBox error={!!error('product_images')}>
                                                    <ImageRounded
                                                        style={{ color: '#999', fontSize: 50 }} />
                                                        <input type="file" multiple={true} accept="image/*" onChange={onAttachImages}/>
                                                </ImageEmptyBox>
                                            </ImagePaper>
                                        </ImageBox>
                                    )
                                })
                            }
                        </Box>
                    </Grid>
                    <Grid item>
                        { hasError && <Alert 
                        severity="warning">Maksimal foto produk adalah 5.</Alert> }
                    </Grid>
                    <Grid item>
                        { !!error('product_images') && <Alert
                        severity="error">Gambar dibutuhkan.</Alert>}
                    </Grid>
                </Grid>
            </Grid>
        </ContentPaper>
    )
}

const ContentPaper = styled(Paper)(({theme}) => ({
    padding: 35,
    paddingTop: 32,
    marginBottom: 25
}));

const ImagePaper = styled(Paper)(({theme}) => ({
    padding: 10,
    width: 127,
    height: 127,
    position: 'relative'
}));

const ImageBox = styled(Box)(({theme}) => ({
    marginLeft: '10px !important' ,
    marginRight: '10px !important'
}));

const ImageAttachedPaper = styled(Paper)(({theme}) => ({
    width: 141,
    height: 141,
    padding: 3,
    position: 'relative',
    '&:hover': {
        "& button": {
            visibility: 'visible'
        }
    }
}));

const DeleteIconButton = styled(IconButton)(({theme}) => ({
    background: '#ddd',
    position: 'absolute',
    right: 10,
    top: 10,
    visibility: 'hidden'
}));

const PrimaryTypography = styled(Typography)(({theme}) => ({
    color: theme.palette.primary.main,
    background: '#d0b0ff',
    borderRadius: 4,
    padding: '2px 10px 2px 10px',
    position: 'absolute',
    bottom: 10,
    left: 10
}));


const ImagePreviewBox = styled(Box)(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    '& img': {
        width: '100%'
    }
}));

const ImageEmptyBox = styled(Box, {
    shouldForwardProp: (prop) => prop !== "error",
  })(({ theme, error }) => ({
    border: error ? '1px dashed #f44336' : '1px dashed #999',
    height: 125,
    width: 125,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 3,
    position: 'relative',
    "& input[type='file']": {
        bottom: 0,
        cursor: 'pointer',
        fontSize: '10em',
        height: '100%',
        left: 0,
        opacity: 0,
        position: 'absolute',
        right: 0,
        top: 0,
        width: '100%',
        zIndex: 3
    },

}));
