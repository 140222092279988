import React, {useState, useEffect} from 'react';
import { Tabs,
         Tab,
         Paper,
         Typography,
         Box,
        } from '@mui/material';
import { styled } from '@mui/material/styles';
import Driver from './Driver';



function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}
  
export default function JobPosition() {
    const [innerTabValue, setInnerTabValue] = useState(0);

    const handleChange = (event, newValue) => {
        setInnerTabValue(newValue);
    };


    useEffect(() => {
      return () => {
        setInnerTabValue(0);
      };
    },[]);
    

    return (
        <Paper elevation={0} 
            sx={{ margin: '-25px', marginLeft: '-24px', bgcolor: 'background.paper', display: 'flex' }}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={innerTabValue}
                onChange={handleChange}
                sx={{ minWidth: '195px', borderRight: 1, borderColor: 'divider' }}>
                <Tab label="Pengiriman" {...a11yProps(0)} />
                {/* <Tab label="Faktur" {...a11yProps(1)} />
                <Tab label="Jabatan" {...a11yProps(1)} /> */}
            </Tabs>
            <Panel value={innerTabValue} index={0}>
                <Driver />
            </Panel>
            {/* <Panel value={value} index={22}>
                Test2
            </Panel>
            <Panel value={value} index={33}>
                Test3
            </Panel> */}
        </Paper>
    )
}

const Panel = styled(TabPanel)(({theme}) => ({
    position: 'relative',
    width: '100%'
}));
