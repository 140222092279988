import React, { useEffect } from 'react';
import { 
    Typography,
    Grid,
    Paper
} from '@mui/material';
import Chart from 'chart.js/auto';
import { 
    green, 
    red,
    pink, 
    purple,
    blue,
    orange } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
export default function DailyChart({data}) {
   
    useEffect(() => {
        var dailyChart = new Chart(document.getElementById("customer-chart"), {
            type: 'line',
            data: {
              labels: data.map(d => d.label),
              datasets: [
                { 
                    data: data.map(d => d.gross_profit),
                    label: "Laba Kotor",
                    borderColor: green[700],
                    backgroundColor: green[500],
                    fill: false
                },
                { 
                    data: data.map(d => d.ordered),
                    label: "Penjualan",
                    borderColor: purple[900],
                    backgroundColor: purple[700],
                    fill: false
                },
                { 
                    data: data.map(d => d.refunded),
                    label: "Pengembalian",
                    borderColor: pink[600],
                    backgroundColor: pink[400],
                    fill: false
                },
                { 
                    data: data.map(d => d.canceled),
                    label: "Pembatalan",
                    borderColor: red[700],
                    backgroundColor: red[500],
                    fill: false
                },
                { 
                    data: data.map(d => d.total_cash_in),
                    label: "Kas Masuk",
                    borderColor: blue[700],
                    backgroundColor: blue[500],
                    fill: false
                },
                { 
                    data: data.map(d => d.total_cash_out),
                    label: "Kas Keluar",
                    borderColor: orange[700],
                    backgroundColor: orange[500],
                    fill: false
                },
              ]
            },
            options: {
                interaction: {
                    intersect: false,
                    mode: 'index'
                },
                scales: {
                    y: {
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function(value, index, ticks) {
                                return "Rp " + Number(value).toFixed().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
                            }
                        }
                    }
                },
                plugins: {
                    legend: {
                        display: false,
                        labels: {
                            color: 'rgb(255, 99, 132)'
                        }
                    },
                    tooltip: {
                        enabled: true,
                        intersect: false,
                        mode: 'index',
                        position: 'nearest',
                        callbacks: {
                            title: function(context) {
                                return null
                            },
                            label: function(context) {
                                let label = context.dataset.label || '';
                                if (label) {
                                    label += ': ';
                                }
                                if (context.parsed !== null) {
                                    label += new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(context.parsed.y);
                                }
                                return label;
                            },
                            
                        }
                    },
                    elements: {
                        point:{
                            radius: 2
                        }
                    },
                }
            },
            
            
        });

        return () => {
            dailyChart.destroy()
        };
    },[]);

    return (
        <ContentPaper elevation={3}>
            <Grid container spacing={6} direction="column">
                <Grid item container>
                    <Grid item xs>
                        <Typography variant="body2" fontSize={16} fontWeight={500} color="#444">
                            Statistik Harian Kasir
                        </Typography>
                    </Grid>
                    <Grid item>
                    </Grid>
                </Grid>
                <Grid item>
                     <canvas id="customer-chart" width="100%"></canvas>
                </Grid>
            </Grid>
        </ContentPaper>
       
    )
}

const ContentPaper = styled(Paper)(({theme}) => ({
    padding: '35px 25px',
    paddingTop: 32,
    marginBottom: 25
}));