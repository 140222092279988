import * as types from '../actions/actionTypes';

const defaultState = {
	inventories: [],
	inventory_histories: [],
	onPurchaseOrderHistories: false,
	onInventoryHistories: false
}

export default function (state = defaultState, action) {
	switch(action.type) {
		case types.INVENTORIES_LOADED:
			return {
				...state,
				stores: action.payload ? action.payload.stores : null,
				inventories: [
					...(state.inventories || []),  // Ensure it's always an array
					...(action.payload?.inventories || []), // Safely spread new inventories
				  ],
				hasMore: action.payload ? action.payload.hasMore : true,
				onPurchaseOrderHistories: false,
				onInventoryHistories: false,
            };
		case types.INVENTORIES_SEARCHED:
			return {
				...state,
				inventories: action.payload ? action.payload.inventories : true,
				hasMore: action.payload ? action.payload.hasMore : true,
			};
		case types.INVENTORIES_SUMMARY_LOADED:
			return {
				...state,
				inventories_summary: action.payload ? action.payload.inventories_summary : null,
            };
		case types.INVENTORIES_COLLAPSED:
			return {
				...state,
				inventories: action.inventories
			}; 
		case types.PURCHASE_ORDER_HISTORIES_SHOWED:
			return {
				...state,
				onPurchaseOrderHistories: true,
				purchase_order_histories: null,
				inventory: action.inventory
			};
		case types.PURCHASE_ORDER_HISTORIES_HIDED:
			return {
				...state,
				onPurchaseOrderHistories: false,
				purchase_order_histories: null,
				inventory: null
			}; 
		case types.INVENTORY_HISTORIES_SHOWED:
			return {
				...state,
				onInventoryHistories: true,
				inventory_histories: null,
				inventory: action.inventory,
				store_id: action.store_id
			};
		case types.INVENTORY_HISTORIES_HIDED:
			return {
				...state,
				onInventoryHistories: false,
				inventory_histories_histories: null,
				inventory: null,
				store_id: null
			}; 
		case types.INVENTORY_HISTORIES_LOADED:
			return {
				...state,
				// stores: action.payload ? action.payload.stores : null,
				inventory_histories: action.payload ? action.payload.inventory_histories : null,
				inventory_histories_count: action.payload ? action.payload.inventory_histories_count : null,				
				inventory_histories_report: action.payload ? action.payload.inventory_histories_report : null,
            };
		case types.PURCHASE_ORDER_HISTORIES_LOADED:
			return {
				...state,
				// stores: action.payload ? action.payload.stores : null,
				purchase_order_histories: action.payload ? action.payload.purchase_order_histories : null,
				purchase_order_histories_count: action.payload ? action.payload.purchase_order_histories_count : null,				
            };
		default:
			return state;
	}
}