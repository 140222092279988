import * as types from './actionTypes';
import { axiosClient } from '../api';
import { validate } from '../components/Helper/Validation';

export const onMigrations = () => {
	const fetch = axiosClient.get(`/products-migration`);
	// return {
	// 	type: types.EDIT_PRODUCT_LOADED,
	// 	payload: fetch
	// }
}




export const onLoadProducts = (query) => {
	const fetch = axiosClient.get(`/products`,
	{
		params: query
	});
	return {
		type: types.PRODUCTS_LOADED,
		payload: fetch
	}
}

export const onUnloadProducts = () => {
	return {
		type: types.PRODUCTS_UNLOADED
	}
}

export const onProductNew = () => {
	const fetch = axiosClient.get(`/products/new`);
	return {
		type: types.NEW_PRODUCT_LOADED,
		payload: fetch
	}
}

export const onProductEdit = (id) => {
	const fetch = axiosClient.get(`/products/${id}/edit`);
	return {
		type: types.EDIT_PRODUCT_LOADED,
		payload: fetch
	}
}

export const onUpdateField = (key, value) => {
	return {
        type: types.PRODUCT_FIELD_UPDATED,
        key,
        value
	}
}

export const onUpdateOptionField = (value) => {
	return {
        type: types.PRODUCT_OPTION_FIELD_UPDATED,
        value
	}
}

export const onToggleAutoSKU = (value) => {
	return {
        type: types.PRODUCT_SKU_TOGGLED,
        value
	}
}

export const onUpdateName = (key, value) => {
	return {
		type: types.PRODUCT_NAME_UPDATED,
		key,
		value

	}
}

export const onToggleCategories = (onCategories) => {
	return {
		type: types.PRODUCT_CATEGORIES_TOGGLED,
		onCategories
	}
}

export const onSelectedCategory = (newCategoryIds, newCategories) => {
	return {
		type: types.PRODUCT_CATEGORIES_SELECTED,
		newCategoryIds,
		newCategories
	}
}


export const onUpdateSpecifications = (newSpecifications) => {
	return {
		type: types.PRODUCT_SPECIFICATIONS_UPDATED,
		newSpecifications
	}
}

// export const onAddProductOption = (newOption) => {
// 	return {
// 		type: types.PRODUCT_OPTION_ADDED,
// 		newOption
// 	}
// }

export const onAddOptions = (newOptions) => {
	return {
		type: types.PRODUCT_OPTIONS_ADDED,
		newOptions
	}
}


export const onRemoveOptions = (newOptions) => {
	return {
		type: types.PRODUCT_OPTIONS_REMOVED,
		newOptions
	}
}


export const onUpdateOptions = (newOptions) => {
	return {
		type: types.PRODUCT_OPTIONS_UPDATED,
		newOptions
	}
}


export const onShowConfiguration = (product) => {
	// const { errors, isValid } = validate(product, 'product')
	// if (isValid) {
		return {
			type: types.PRODUCT_CONFIGURATION_SHOWED
		}
	// } else {
	// 	return {
	// 		type: types.FORM_VALIDATION,
	// 		errors
	// 	}
	// }
	
}


export const onHideConfiguration = () => {
	return {
		type: types.PRODUCT_CONFIGURATION_HIDED
	}
}

export const onShowAdvancedPrice = () => {
	return {
		type: types.PRODUCT_ADVANCED_PRICE_SHOWED
	}
}

export const onHideAdvancedPrice = () => {
	return {
		type: types.PRODUCT_ADVANCED_PRICE_HIDED
	}
}


export const onLoadProductPrices = (id) => {
	const fetch = axiosClient.get(`/products/${id}/product-prices`);
	return {
		type: types.PRODUCT_PRICES_LOADED,
		payload: fetch
	}
}

export const onDoneAdvancedPrice = (product_prices) => {
	const { errors, isValid } = validate(product_prices, 'product_price')
	if (isValid) {
		return {
			type: types.PRODUCT_PRICES_DONE,
			product_prices
		}
	} else {
		return {
			type: types.PRICES_VALIDATION, 
			errors
		}
	}
}

export const onDoneConfiguration = (product_configurations) => {
	const { errors, isValid } = validate(product_configurations, 'product_configuration')
	if (isValid) {
		return {
			type: types.PRODUCT_CONFIGURATIONS_DONE,
			product_configurations
		}
	} else {
		return {
			type: types.CONFIGUATIONS_VALIDATION, 
			errors
		}
	}
}

export const onUpdateVariants = (newVariants) => {
	return {
		type: types.PRODUCT_VARIANTS_UPDATED,
		newVariants
	}
}

export const onRemoveVariants = (newVariants) => {
	return {
		type: types.PRODUCT_VARIANTS_REMOVED,
		newVariants
	}
}


// export const onEditConfirm = () => {
// 	return {
// 		type: types.PRODUCT_EDIT_SHOWED
// 	}
// }

export const onHideConfirm = () => {
	return {
		type: types.PRODUCT_CONFIRM_HIDED
	}
}


export const onCreate = (product) => {
	const fetch = axiosClient.post(`/products`, {product});
	return {
		type: types.PRODUCT_CREATED, 
		payload: fetch
	}
}

export const onEdit = (productId, product) => {
	const fetch = axiosClient.put(`/products/${productId}`, {product});
	return {
		type: types.PRODUCT_EDITED, 
		payload: fetch
	}
}

export const onUnloadForm = () => {
	return {
		type: types.PRODUCT_FORM_UNLOADED
	}
}

export const onToggleModifier = () => {
	return {
		type: types.PRODUCT_MODIFIER_TOGGLED
	}
}

export const onDeleteModifiers = () => {
	return {
		type: types.PRODUCT_MODIFIERS_DELETED
	}
}

export const onUpdateProductModifier = (newProductPriceModifiers) => {
	return {
		type: types.PRODUCT_MODIFIER_UPDATED,
		newProductPriceModifiers
	}
}

export const onUploadRequest = (image) => {
	return {
		type: types.PRODUCT_IMAGE_UPLOAD_REQUESTED,
		image
	}
}

export const onUpload = (file, index, progressCallback) => {
	const fetch = axiosClient.post(`/products/upload`, {file}, {onUploadProgress: progressCallback});
	return {
		type: types.PRODUCT_IMAGE_UPLOADED,
		payload: fetch,
		index
	}
}

export const onUploading = (image, index) => {
	return {
		type: types.PRODUCT_IMAGE_UPLOADING,
		image,
		index
	}
}

export const onRemoveRequest = (image, index) => {
	return {
		type: types.PRODUCT_IMAGE_REMOVE_REQUESTED,
		image,
		index
	}
}

export const onRemove = (file, index) => {
	const fetch = axiosClient.post(`/products/remove`, {file});
	return {
		type: types.PRODUCT_IMAGE_REMOVED,
		payload: fetch,
		index
	}
}

export const onUploadSwatch = (file, progressCallback) => {
	const fetch = axiosClient.post(`/products/upload-swatch`, {file}, {onUploadProgress: progressCallback});
	return fetch;
}


export const onRemoveSwatch = (file) => {
	const fetch = axiosClient.post(`/products/remove-swatch`, {file});
	return fetch;
}

export const onUploadVariant = (index, file, progressCallback) => {
	const fetch = axiosClient.post(`/products/upload`, {file}, {onUploadProgress: progressCallback});
	return {
		type: types.PRODUCT_VARIANT_IMAGE_UPLOADED,
		payload: fetch,
		index
	}
}

export const onUploadingVariant = (index, image) => {
	return {
		type: types.PRODUCT_VARIANT_IMAGE_UPLOADING,
		image,
		index,
	}
}

export const onRemoveVariant = (index, file, imageIndex) => {
	const fetch = axiosClient.post(`/products/remove`, {file});
	return {
		type: types.PRODUCT_VARIANT_IMAGE_REMOVED,
		payload: fetch,
		index,
		imageIndex
	}
}

export const onUpdateImages = (images) => {
	return {
		type: types.PRODUCT_IMAGES_UPDATED,
		images
	}
}