import React, { useState, useRef, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as productActions from '../../../../../../../../actions/productActions';
import { 
        Typography,
        Paper,
        Box,
        Grid,
        Button,
        Checkbox,
        TextField,
        InputAdornment,
        Switch,
        Popper,
        Grow,
        ClickAwayListener,
        List,
        ListItem,
        ListItemText,
        Collapse,
        ListItemIcon,
        ButtonGroup,
        Chip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {ImageRounded, ExpandMoreRounded, ExpandLessRounded, ArrowDropDown} from '@mui/icons-material';
import NumberFormat from 'react-number-format';


const CurrencyFormat = React.forwardRef((props, ref) => (
    <NumberFormat
        {...props.other}
        onValueChange={(values) => {
          props.onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        fullWidth={props.fullWidth}
        customInput={TextField}
        InputProps={{
            startAdornment: <InputAdornment position="start">
                <Box fontWeight={700}>
                    Rp
                </Box>
            </InputAdornment>,
        }}
        disabled={props.disabled}
        value={props.value}
        thousandSeparator={"."}
        decimalSeparator={","}
        allowNegative={false}
        decimalScale={0}
        isNumericString
      />
));

const WeightFormat = React.forwardRef((props, ref) => (
    <NumberFormat
        {...props.other}
        onValueChange={(values) => {
          props.onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        fullWidth={props.fullWidth}
        customInput={TextField}
        value={props.value}
        disabled={props.disabled}
        thousandSeparator={"."}
        decimalSeparator={","}
        allowNegative={false}
        allowedDecimalSeparators={false}
        isNumericString
      />
));


export default function Bulk({onChecked, selected, setSelected}) {
    const dispatch = useDispatch();
    const unitRef = useRef(null); 
    const anchorRef = useRef(null);
    const anchorActionRef = useRef(null);
    const {product} = useSelector(state => ({
        ...state.product
    }), shallowEqual);
    const { product_variants } = product;
    
    const [attributes, setAttributes] = useState([]);
    
    const [open, setOpen] = useState(false);
    const [actionOpen, setActionOpen] = useState(false);
    const [collapsed, setCollapsed] = useState("");
    // const [applied, setApplied] = useState(['price', 'weight', 'photo', 'uom', 'status']);
    const [applied, setApplied] = useState(['price', 'weight', 'uom', 'status']);
    const [bulkValue, setBulkValue] = useState({price: 0, weight: 0, image: '', uom: '', status: true});
    const prevOpen = useRef(open);
    const prevActionOpen = useRef(actionOpen);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    useEffect(() => {
        if (prevActionOpen.current === true && actionOpen === false) {
            anchorActionRef.current.focus();
        }
        prevActionOpen.current = actionOpen;
    }, [actionOpen]);

    useEffect(() => {
        const attributesKeys = {}
        const variants = product_variants.filter(v => !v._destroy);
        Object.keys(variants[0].variant_attributes).map((key) => {
            return attributesKeys[key] = []
        })

        for(var i = 0; i < variants.length; i++) {
            const variantAttr = variants[i].variant_attributes;
            Object.keys(variantAttr).map((key) => {
                if (!JSON.stringify(attributesKeys[key]).includes(JSON.stringify(variantAttr[key]))) {
                    return attributesKeys[key].push({value: variantAttr[key], checked: false});
                }
                return null;
            })
        }
    
        var variantsAttributes = []
        
        Object.keys(attributesKeys).map((key, i) => {
            const options = []
            attributesKeys[key].map(attr => {
                if (!JSON.stringify(options).includes(JSON.stringify(attr))) {
                    return options.push(attr)
                }
                return attr;
            })
            return variantsAttributes.push({label: key, options})
            
    
        })

        setAttributes(variantsAttributes)
    }, [product_variants]);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
        }

        setOpen(false);
        setCollapsed('');
        
        const newVariantsAttributes = [...attributes];
        newVariantsAttributes.map(attribute => {
            const newAttribute = {...attribute};
            return newAttribute.options.map(option => {
                return option.checked = false;
            })
        })

        setAttributes(newVariantsAttributes);
      
    };

    const onToggleAction = () => {
        setActionOpen((prevActionOpen) => !prevActionOpen);
    };

    const onCloseAction = (event) => {
        if (anchorActionRef.current && anchorActionRef.current.contains(event.target)) {
        return;
        }

        setActionOpen(false);
    };

    function onCollapse(label) {
        if (collapsed === label) {
            setCollapsed("");
        } else {
            setCollapsed(label);
        }
        const newVariantsAttributes = [...attributes];
        newVariantsAttributes.map(attribute => {
            const newAttribute = {...attribute};
            return newAttribute.options.map(option => {
                return option.checked = false;
            })
        })
        setAttributes(newVariantsAttributes);
    }


    function onCheckBox(attr, option) {
        
        const newVariantsAttributes = [...attributes];
        newVariantsAttributes.map(attribute => {
            const newAttribute = {...attribute};
            return newAttribute.options.map(o => {
                if (JSON.stringify(o) === JSON.stringify(option)) {
                    return o.checked = !option.checked;
                }
                return option;
            })
        })
        setAttributes(newVariantsAttributes);

        const variants = product_variants.filter(v => !v._destroy);
        var newSelected = [...selected];
        variants.map(variant => {
            if (option.value === variant.variant_attributes[attr.label]) {
                if (!selected.includes(variant.sku) && option.checked) {
                    return newSelected.push(variant.sku);
                } else if (selected.includes(variant.sku) && !option.checked) {
                    const itemIndex = newSelected.findIndex(o => o === variant.sku)
                    newSelected.splice(itemIndex, 1);
                }    
            }
            return variant;
            
        })
        setSelected(newSelected);
    }

    function onChangeBulk(event) {
        const key = event.target.name;
        const value = event.target.value;
	    setBulkValue({ ...bulkValue, [key]: value});
    }

    function onPressEnter(event) {
        const value = event.target.value;
        if(event.keyCode == 13 && !bulkValue.uom){
            setBulkValue({ ...bulkValue, uom: value.toUpperCase()});
        }
    }

    function onBlurUnit(event) {
        const value = event.target.value;
        if (!bulkValue.uom) {
            setBulkValue({ ...bulkValue, uom: value.toUpperCase()});
        }
    }


    function onDeleteUOM() {
        setBulkValue({ ...bulkValue, uom: ''});
        setTimeout(function() {
            unitRef.current.focus()
        }, 100);
    }
      
    function onToggledBulk() {
	    setBulkValue({ ...bulkValue, status: !bulkValue.status});
    }


    function onSelectBulkImage(event) {
        event.preventDefault();
        const files = event.target.files;
        
        let file = files[0]
        const reader = new FileReader();
        reader.onloadend = () => {
            setBulkValue({...bulkValue, image: reader.result})
        }
        reader.readAsDataURL(file);
    }

    function onSetBulk() {
        product_variants.map((variant) => {
            if (selected.includes(variant.sku)) {
                if (applied.includes('price')) {
                    variant.price = bulkValue.price;
                }
                if (applied.includes('weight')) {
                    variant.weight = bulkValue.weight;
                }
                // if (applied.includes('photo')) {
                //     variant.product_images.map(image => {
                //         image.onRemove = true;
                //     })
                //     variant.product_images.push({image: bulkValue.image, onUpload: true});
                // }
                if (applied.includes('uom')) {
                    variant.uom = bulkValue.uom;
                }
                if (applied.includes('status')) {
                    variant.status = bulkValue.status;
                }
            }
        })
        dispatch(productActions.onUpdateVariants(product_variants));
        setBulkValue({price: 0, weight: 0, image: '', status: true});
        setActionOpen(false);
        setApplied(['price', 'weight', 'uom', 'status'])
    }
    
    function onHandleAll() {
        // if (product_variants.filter(v => !v._destroy).length === selected.length) {
        //     setCollapsed('');
        //     const newVariantsAttributes = [...attributes];
        //     newVariantsAttributes.map(attribute => {
        //         const newAttribute = {...attribute};
        //         return newAttribute.options.map(o => {
        //             return o.checked = false;
        //         })
        //     })
        //     setAttributes(newVariantsAttributes);
        //     onChecked()
        // } else {
        //     setCollapsed('');
        //     const newVariantsAttributes = [...attributes];
        //     newVariantsAttributes.map(attribute => {
        //         const newAttribute = {...attribute};
        //         return newAttribute.options.map(o => {
        //                 return o.checked = true;
        //         })
        //     })
        //     setAttributes(newVariantsAttributes);
        //     onChecked()
        // }

        setCollapsed('');
        onChecked()
    }

    function onHandleApplied(key, event) {
        var newApplied = [...applied];
        if (applied.find(o => o === key)) {
            const itemIndex = applied.findIndex(o => o === key);
            newApplied.splice(itemIndex, 1);
        } else {
            newApplied.push(key);
        }
        setApplied(newApplied)
    }

    function variantCount() {
        if (selected.length === 0) {
            return (
                <Typography lineheight="normal" component="div">
                    <Box fontSize={14} fontWeight={500}>
                        Pilih varian
                    </Box>
                </Typography>
            )
        }

        return (
            <Typography lineheight="normal" component="div" style={{display: 'flex'}}>
                <Box fontSize={14} fontWeight={700} style={{marginRight: 10}}>
                    {selected.length}
                </Box>
                <Box fontSize={14} fontWeight={500}>
                     Varian dipilih
                </Box>
            </Typography>
        )
    }
    
    
    function variantImage(image, index) {
        if (!image) {
            // let imgClass = classes.imageEmpty;
            // let error_class = classes.error;
            // if (!!error(`product_variants[${index}].product_variant_images.image`)) {
            //     imgClass = imgClass.concat(' ', error_class)
            // }
            return (
                <ImgBox>
                    <ImageRounded
                        style={{ color: '#999', fontSize: 20, opacity: applied.includes('photo') ? '1' : 0.65 }} />
                </ImgBox>
            )
        }

        return (
            <ImagedBox component="img" alt={image} src={image} />
        )
    }

   
    return (
       
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={1} container sx={{position: 'relative'}}>
                <Grid item>
                    <SelectCheckbox
                        onChange={onChecked}
                        checked={selected.length !== 0}
                        color="primary"
                    />  
                </Grid>
                <Grid item>
                    <SelectButton 
                        color="inherit" 
                        ref={anchorRef}
                        endIcon={open ? <ExpandLessRounded />  : <ExpandMoreRounded />}
                        onClick={handleToggle}>
                    </SelectButton>
                    <Popper open={open} placement={'bottom-start'} anchorEl={anchorRef.current} role={undefined} style={{zIndex: 999}} transition disablePortal>
                        {({ TransitionProps, placement }) => (
                            <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                            >
                            <DropdownPaper >
                                <ClickAwayListener onClickAway={handleClose}>
                                <List
                                    component="nav">
                                    <ListItem button onClick={onHandleAll}>
                                        <ListItemText primary={product_variants.filter(v => !v._destroy).length === selected.length ? "Batal Semua" : "Pilih Semua"} />
                                    </ListItem>
                                    {
                                        attributes.map((attr, index) => {
                                            return (
                                                [<ListItem key={index} button 
                                                    onClick={onCollapse.bind(this, attr.label)} 
                                                    >
                                                    <ListItemText primary={attr.label.charAt(0).toUpperCase() + attr.label.slice(1)} />
                                                    {collapsed === attr.label ? <ExpandLessRounded /> : <ExpandMoreRounded />}
                                                </ListItem>,
                                                <Collapse key={index + attributes.length} in={collapsed === attr.label} timeout="auto" unmountOnExit>
                                                    <List component="div" disablePadding>
                                                        {
                                                            attr.options.map((option, index) => {
                                                                return (
                                                                    <ListItem key={index} button 
                                                                        onClick={onCheckBox.bind(this, attr, option)}>
                                                                        <ListItemIcon>
                                                                            <Checkbox
                                                                                edge="start"
                                                                                checked={option.checked}
                                                                                tabIndex={-1}
                                                                                disableRipple
                                                                            />
                                                                        </ListItemIcon>
                                                                        <ListItemText primary={option.value} />
                                                                    </ListItem>
                                                                )
                                                            })
                                                        }
                                                    </List>
                                                </Collapse>]
                                            )
                                        })
                                    }
                                    </List>
                                </ClickAwayListener>
                            </DropdownPaper>
                            </Grow>
                        )}
                        </Popper>
                </Grid>
            </Grid>
            <Grid item xs={2} >
                {variantCount()}
            </Grid>
            { selected.length !== 0 && 
                
                [ <Grid key={1} item xs style={{paddingLeft: '20px'}}>
                        <CurrencyFormat
                            fullWidth
                            id="price"
                            name="price"
                            placeholder="0"
                            value={bulkValue.price}
                            variant="outlined"
                            disabled={!applied.includes('price')}
                            onChange={onChangeBulk}
                        />
                    </Grid>,
                     <Grid key={2} item xs={1} style={{paddingLeft: '25px'}}>
                        <WeightFormat
                            id="weight"
                            name="weight"
                            placeholder="Masukan Berat"
                            value={bulkValue.weight}
                            variant="outlined"
                            disabled={!applied.includes('weight')}
                            onChange={onChangeBulk}
                        />
                    </Grid>,
                    // <Grid key={3} item xs={1}>
                    //     <ImgPaper>
                    //         {variantImage(bulkValue.image)}
                    //         <input  disabled={!applied.includes('photo')} type="file" multiple={true} accept="image/*" onChange={onSelectBulkImage} />
                    //     </ImgPaper>
                    // </Grid>,
                    <Grid key={3} item xs={2} style={{paddingLeft: '27px'}}>
                            {
                                !!bulkValue.uom ? <Chip
                                tabIndex={-1}
                                size="medium"
                                label={bulkValue.uom}
                                sx={{marginRight: '5px'}}
                                onDelete={onDeleteUOM}
                                variant="outlined"
                                /> : <TextField
                                        fullWidth
                                        placeholder="Satuan"
                                        inputRef={unitRef}
                                        variant="outlined"
                                        disabled={!applied.includes('uom')}
                                        onKeyDown={onPressEnter}
                                        onBlur={onBlurUnit}
                                    />
                            }
                    </Grid>,
                    <Grid key={4} item xs={1} style={{paddingLeft: '28px'}}>
                        <Switch
                            disabled={!applied.includes('status')}
                            checked={bulkValue.status}
                            onChange={onToggledBulk}
                            />
                    </Grid>,
                   <Grid key={5} item xs={2} style={{paddingLeft: '30px'}}>
                        <ButtonGroup disableElevation variant="contained" ref={anchorActionRef} aria-label="split button">
                            <ActionButton 
                                fullWidth 
                                size="large"
                                onClick={onSetBulk}>Set Bulk</ActionButton>
                            <Button
                                onClick={onToggleAction}>
                                   {actionOpen ? <ExpandLessRounded />  : <ExpandMoreRounded />}
                            </Button>
                        </ButtonGroup>
                        <Popper
                            open={actionOpen}
                            anchorEl={anchorActionRef.current}
                            role={undefined}
                            transition
                            placement={'bottom-end'}
                            disablePortal
                            sx={{zIndex: 10}}
                        >
                            {({ TransitionProps, placement }) => (
                                <Grow
                                {...TransitionProps}
                                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                >
                                <DropdownPaper >
                                    <ClickAwayListener onClickAway={onCloseAction}>
                                    <List
                                        component="nav">
                                       <ListItem button 
                                        onClick={onHandleApplied.bind(this, "price")}>
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={applied.includes('price')}
                                                    tabIndex={-1}
                                                    disableRipple
                                                />
                                            </ListItemIcon>
                                            <ListItemText primary="Price" />
                                        </ListItem>
                                        <ListItem button 
                                        onClick={onHandleApplied.bind(this, "weight")}>
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={applied.includes('weight')}
                                                    tabIndex={-1}
                                                    disableRipple
                                                />
                                            </ListItemIcon>
                                            <ListItemText primary="Weight" />
                                        </ListItem>
                                        {/* <ListItem button 
                                        onClick={onHandleApplied.bind(this, "photo")}>
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={applied.includes('photo')}
                                                    tabIndex={-1}
                                                    disableRipple
                                                />
                                            </ListItemIcon>
                                            <ListItemText primary="Photo" />
                                        </ListItem> */}
                                        <ListItem button 
                                        onClick={onHandleApplied.bind(this, "uom")}>
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={applied.includes('uom')}
                                                    tabIndex={-1}
                                                    disableRipple
                                                />
                                            </ListItemIcon>
                                            <ListItemText primary="UOM" />
                                        </ListItem>
                                        <ListItem button 
                                        onClick={onHandleApplied.bind(this, "status")}>
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={applied.includes('status')}
                                                    tabIndex={-1}
                                                    disableRipple
                                                />
                                            </ListItemIcon>
                                            <ListItemText primary="Status" />
                                        </ListItem>
                                        </List>
                                    </ClickAwayListener>
                                </DropdownPaper>
                                </Grow>
                            )}
                        </Popper>
                    </Grid>]

            }
        </Grid>
    )
}

const SelectCheckbox = styled(Checkbox)(({theme}) => ({
    position: 'absolute',
    top: 23
}));

const SelectButton = styled(Button)(({theme}) => ({
    minHeight: 56,
    paddingLeft: 38,
    paddingRight: 15
}));

const DropdownPaper = styled(Paper)(({theme}) => ({
    minWidth: 195
}));

const ImgPaper = styled(Paper)(({theme}) => ({
    padding: 5,
    width: 46,
    height: 46,
    position: 'relative',
    '& input[type="file"]': {
        bottom: 0,
        cursor: 'pointer',
        fontSize: '10em',
        height: '100%',
        left: 0,
        opacity: 0,
        position: 'absolute',
        right: 0,
        top: 0,
        width: '100%',
        zIndex: 3
    }
}));

const ImgBox = styled(Box)(({theme}) => ({
    border: '1px dashed #999',
    height: 44,
    width: 44,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 3,
    position: 'relative',
}));

const ImagedBox = styled(Box)(({theme}) => ({
    width: 42,
    height: 42,
    padding: 2,
    position: 'relative'
}));

const ActionButton = styled(Button)(({theme}) => ({
    color: theme.palette.primary.main,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: `1px solid ${theme.palette.primary.main}`
}));
