import React, {useRef, useState, useEffect, useCallback } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as inventoryActions from '../../../../../actions/inventoryActions';
import { Box, Grid, Paper, InputBase, Typography, Stack,
    Card,
    CardMedia,
    CardContent,
    IconButton,
    TablePagination,
    Table,
    Collapse,
    TableContainer,
    Divider,
    TableRow,
    TableCell,
    TableHead,
    TableSortLabel,
    TableBody,
    Tooltip,
    Chip,
    ListItem,
    ListItemButton,
    ListItemText
} from '@mui/material';
import NumberFormat from 'react-number-format';
import Header from './Header';
import { styled, alpha } from '@mui/material/styles';
import { Inventory2TwoTone, PaymentsTwoTone, SellTwoTone, AssessmentTwoTone, RequestQuoteTwoTone, Search, KeyboardArrowDown, KeyboardArrowUp, AccessTimeTwoTone } from '@mui/icons-material';
import { green, red, grey } from '@mui/material/colors';
import DataEmpty from '../../../../Helper/DataEmpty';
import { visuallyHidden } from '@mui/utils';
import Loader from '../../../../Helper/Loader';
import moment from 'moment';
import 'moment/locale/id';
import { List, AutoSizer } from "react-virtualized";
import PurchaseOrderHistory from './PurchaseOrderHistory';
import InventoryHistory from './InventoryHistory';
import { useTheme } from '@mui/material/styles';


function formatNumber(num, precision = 2) {
    const map = [
      { suffix: 'KT', threshold: 1e15 },
      { suffix: 'T', threshold: 1e12 },
      { suffix: 'M', threshold: 1e9 },
      { suffix: 'K', threshold: 1e6 },
      { suffix: '', threshold: 1e3 },
    ];

  
    const found = map.find((x) => Math.abs(num) >= x.threshold);
    if (found) {
      const value = ((num / found.threshold) * 1000).toFixed(precision)
    //    + found.suffix;
      return  (
            <NumberFormat
                value={value}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator={","}
                isNumericString
                prefix="Rp "
                suffix={found.suffix}
                decimalScale={0}
                renderText={(value) => <Typography variant="h6" fontSize={16} fontWeight={700} color="#444">
                {value}
            </Typography>}/>
        )
    }
  
    return num;
}

const headCells = [
    { id: 'increment_id', numeric: false, sortable: true, disablePadding: false, label: 'ID Cabang' },
    { id: 'store_name', numeric: false, sortable: true, disablePadding: false, label: 'Nama Cabang' },
    { id: 'unit_price', numeric: true, sortable: true, disablePadding: false, label: 'Harga' },
    { id: 'average_price', numeric: true, sortable: true, disablePadding: false, label: 'Harga Rata-Rata' },
    { id: 'quantity', numeric: true, sortable: true, disablePadding: false, label: 'Stok Tersedia' },
    { id: 'status', numeric: true, sortable: true, disablePadding: false, label: 'Status' },
    { id: 'low_stock_notify', numeric: true, sortable: true, disablePadding: false, label: 'Stok Min.' },
    { id: 'history', numeric: true, sortable: false, disablePadding: false, label: 'Kartu Stok' }
  ];
 
  
function EnhancedTableHead({ order, orderBy, onRequestSort }) {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => {
                    if (headCell.sortable) {
                        return (
                            <TableCell
                                key={headCell.id}
                                sx={{fontWeight: 700}}
                                align={headCell.numeric ? 'right' : 'left'}
                                padding={headCell.disablePadding ? 'none' : 'normal'}
                                sortDirection={orderBy === headCell.id ? order : false}
                            >
                                <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                                >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                                </TableSortLabel>
                            </TableCell>
                        )
                    }
                    return (
                        <TableCell
                        key={headCell.id}
                        sx={{fontWeight: 700}}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        >
                            {headCell.label}
                        </TableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    );
}


export default function Inventory() {
    const dispatch = useDispatch();
    const theme = useTheme();
    const {inventories, inventories_summary, hasMore, stores} = useSelector(state => ({
        ...state.inventory
    }), shallowEqual); 
    const listRef = useRef();
    const contentRefs = useRef([]);
    const collapsedHeight = 180; 
    const margin = 15; 
    const [query, setQuery] = useState({ page: 0, limit: 20, search: '', store_id: '' });
    const [search, setSearch] = useState(query.search);
    const [isLoading, setIsLoading] = useState(false);
    // const [rowHeights, setRowHeights] = useState([]);
    const [expandedIndexes, setExpandedIndexes] = useState({});
    const [expandedHeights, setExpandedHeights] = useState([]);  

    useEffect(() => {
        dispatch(inventoryActions.onLoadInventories(query))
        dispatch(inventoryActions.onLoadInventoriesSummary(query))
    },[dispatch]);

    useEffect(() => {
        // Update rowHeights when contentRef scrollHeight changes
        const updatedHeights = inventories.map((_, index) => {
            const contentHeight = contentRefs.current[index]?.scrollHeight || collapsedHeight;
            return { index, height: contentHeight };
        });

        setExpandedHeights(updatedHeights);

        if (listRef.current) {
            // const totalExpanded = Object.values(expandedIndexes).filter(value => value === true).length;
            // console.log(totalExpanded)
            // if (totalExpanded === 0 ) {
            //     setTimeout(() => {
            //         listRef.current.recomputeRowHeights();
            //         listRef.current.forceUpdateGrid();
            //     }, 300); // Match the transition duration in CSS (0.3s)
            // } else {
                listRef.current.recomputeRowHeights();
                listRef.current.forceUpdateGrid();
            // }
            // // console.log(expandedIndexes)
          
        }
    }, [expandedIndexes]); //

    function onSearch(e) {
        var enterKey = 13;
        if (e.which === enterKey) {
            e.target.blur(); 
            const newQuery = {...query};
            newQuery.page = 0;
            newQuery.search = search;
            setQuery(newQuery)
            dispatch(inventoryActions.onSearchInventories(newQuery))
        }
    }



    function onShowPurchaseOrderHistories(inventory) {
        dispatch(inventoryActions.onShowPurchaseOrderHistories(inventory));
    }

    function onShowInventoryHistories(inventory, store_id) {
        dispatch(inventoryActions.onShowInventoryHistories(inventory, store_id));
    }

    function percentage(value) {
        if (parseFloat(value) <= 0) {
            return (
                <LossTypography fontWeight={500} variant="body2">
                    {value}%
                </LossTypography>
            ) 
        }


        return (
            <GainTypography fontWeight={500} variant="body2">
                {value}%
            </GainTypography>
        ) 
    }


    if (!inventories) { 
        return <Loader />
    }

    const loadMoreRows = ({ stopIndex }) => {
        if (stopIndex >= inventories.length - 20 && hasMore && !isLoading) {
            setIsLoading(true)
            const nextPage = query.page + 1; // Increment the page
            const newQuery = { ...query, page: nextPage };
            setQuery(newQuery)
            dispatch(inventoryActions.onLoadInventories(newQuery, (event) => {
                setIsLoading(false)            
            }));
        }
    };


    
    
    // Measure the height of the collapse content

    const toggleExpand = (index) => {
        setExpandedIndexes((prev) => ({
            ...prev,
            [index]: !prev[index], // Toggle expanded state
        }));
    };

    const calculateRowHeights = () => {
        let cumulativeHeight = 0;
        return inventories.map((item, index) => {
            const isExpanded = !!expandedIndexes[index];
            const rowHeight = isExpanded ? expandedHeights[index]?.height + collapsedHeight : collapsedHeight;
            const top = cumulativeHeight;
            cumulativeHeight += rowHeight;
            return { index, top, height: rowHeight };
        });
    };

    const rowHeights = calculateRowHeights();

    const rowRenderer = ({ index, key, style }) => {
        const item = inventories[index];
        if (!item) return <div key={key} style={style}>Loading...</div>;
    
        const { image, name, sku, uom, inventories_value, inventories_quantity, average_order, expanded, lowest_est_margins, lowest_est_margins_percentage, highest_est_margins, highest_est_margins_percentage } = item;
        
        return (
       
            <div
            key={key}
            style={{
            ...style,
            top: `${(parseFloat(rowHeights[index].top))}px`,
            height: `${rowHeights[index]?.height - 4 * margin}px`, // Adjust height
            transition: 'height 0.4s ease-out, top 0.4s ease-out', // Animate both height and top
            }}>
                <div style={{ padding: `2px`, height: "100%", boxSizing: 'border-box' }}>
                    <Item
                        elevation={3}
                        style={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        padding: '24px',
                        }}
                    >
                        <Grid container direction="column">
                            <Grid item container direction="column" spacing={3}>
                                <Grid item xs>
                                    <Box sx={{display: 'flex', paddingBottom: '15px'}}>
                                        <Typography fontWeight={700} fontSize={16} color="#444" variant="h6" sx={{display: 'flex'}}>
                                            <Tooltip title="Kuantitas Barang" placement="top" arrow>
                                                <Inventory2TwoTone style={{ fontSize: 20, marginRight: '10px', marginTop: '3px' }} color="primary" /> 
                                            </Tooltip> {inventories_quantity} {uom}
                                        </Typography>
                                        <Slash>/</Slash>
                                        <Typography fontWeight={700} fontSize={16} color="#444" variant="h6" sx={{display: 'flex'}}>
                                            <Tooltip title="Nilai Persediaan" placement="top" arrow>
                                                <PaymentsTwoTone style={{ fontSize: 20, marginRight: '10px', marginTop: '3px' }} color="primary" /> 
                                            </Tooltip> 
                                            <NumberFormat
                                                value={inventories_value}
                                                displayType="text"
                                                thousandSeparator={"."}
                                                decimalSeparator={","}
                                                prefix="Rp "
                                                isNumericString
                                                decimalScale={2}
                                                renderText={(value) => <Typography fontWeight={700} fontSize={16} component="div" color="#444" variant="h6">
                                                {value}
                                                </Typography>}
                                            />
                                        </Typography>
                                        <Slash>/</Slash>
                                        <Typography fontWeight={700} fontSize={16} color="#444" variant="h6" sx={{display: 'flex'}}>
                                            <Tooltip title="Rata-Rata Penjualan / Bulan" placement="top" arrow>
                                                <SellTwoTone style={{ fontSize: 20, marginRight: '10px', marginTop: '3px' }} color="primary" /> 
                                            </Tooltip> 
                                            <NumberFormat
                                                value={average_order}
                                                displayType="text"
                                                thousandSeparator={"."}
                                                decimalSeparator={","}
                                                prefix="Rp "
                                                isNumericString
                                                decimalScale={2}
                                                renderText={(value) => <Typography fontWeight={700} fontSize={16} color="#444" component="div" variant="h6">
                                                {value}
                                                </Typography>}
                                            />
                                        </Typography>
                                        <Slash>/</Slash>
                                        <Typography fontWeight={700} fontSize={16} color="#444" variant="h6" sx={{display: 'flex'}}>
                                            <Tooltip title="Est. Margin" placement="top" arrow>
                                                <RequestQuoteTwoTone style={{ fontSize: 20, marginRight: '10px', marginTop: '3px' }} color="primary" /> 
                                            </Tooltip> 
                                            <NumberFormat
                                                value={lowest_est_margins}
                                                displayType="text"
                                                thousandSeparator={"."}
                                                decimalSeparator={","}
                                                prefix="Rp "
                                                isNumericString
                                                decimalScale={2}
                                                renderText={(value) => <Typography fontWeight={700} fontSize={16} color="#444" component="div" variant="h6">
                                                {value}
                                                </Typography>}
                                            />
                                            <NumberFormat
                                                value={lowest_est_margins_percentage}
                                                displayType="text"
                                                thousandSeparator={"."}
                                                decimalSeparator={","}
                                                isNumericString
                                                decimalScale={2}
                                                renderText={(value) => <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                                    ({percentage(value)})
                                                </Box>}
                                            />
                                            <Box sx={{margin: "0px 5px"}}>-</Box> 
                                            <NumberFormat
                                                value={highest_est_margins}
                                                displayType="text"
                                                thousandSeparator={"."}
                                                decimalSeparator={","}
                                                prefix="Rp "
                                                isNumericString
                                                decimalScale={2}
                                                renderText={(value) => <Typography fontWeight={700} fontSize={16} color="#444" component="div" variant="h6">
                                                {value}
                                                </Typography>}
                                            />
                                            <NumberFormat
                                                value={highest_est_margins_percentage}
                                                displayType="text"
                                                thousandSeparator={"."}
                                                decimalSeparator={","}
                                                isNumericString
                                                decimalScale={2}
                                                renderText={(value) => <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                                    ({percentage(value)})
                                                </Box>}
                                            />
                                        </Typography>
                                        <Slash>/</Slash>
                                        <Typography style={{display: 'flex'}} onClick={onShowPurchaseOrderHistories.bind(this, item)} component="div">
                                            <Tooltip title="Riwayat Pembelian" placement="top" arrow>
                                                <AccessTimeTwoTone style={{ fontSize: 20, marginRight: '10px', marginTop: '3px' }} color="primary" /> 
                                            </Tooltip> 
                                            <Typography color="primary" fontWeight={500} fontSize={14} variant="body2" style={{textTransform: 'capitalize', cursor: 'pointer', marginTop: '3px'}}>
                                                Riwayat pembelian
                                            </Typography>
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item container alignItems="center" justifyContent="flex-end">
                                    <Grid item xs>
                                        <Card variant="outlined" sx={{ display: 'flex', background: "none", border: 0 }}>
                                            <CardMedia
                                                component="img"
                                                sx={{ width: '45px', marginRight: '15px', height: '45px' }}
                                                image={image}
                                                alt={name}
                                            />
                                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                <CardContent sx={{ flex: '1 0 auto', padding: '0px !important' }}>
                                                    <Typography fontWeight={700} variant="subtitle1">
                                                    {name}
                                                    </Typography>
                                                    <Typography fontSize={9} variant="body2" color="textSecondary" component='div' gutterBottom>
                                                        SKU: {sku}
                                                    </Typography>
                                                </CardContent>
                                            </Box>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton
                                            sx={{float: "right"}}
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() => toggleExpand(index)}
                                        >
                                            {expandedIndexes[index] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Collapse ref={(el) => (contentRefs.current[index] = el)} in={expandedIndexes[index]} timeout="auto" unmountOnExit>
                                    <Divider sx={{padding: '15px'}} />
                                    <TableContainer>
                                        <Table sx={{width: '100%'}}>
                                            <EnhancedTableHead />
                                            <TableBody>
                                            {
                                                item.store_inventories.map((inventory, index) => {
                                                    const { store, unit_price, average_price, quantity, status, low_stock_notify, store_id } = inventory;
                                                    return (
                                                        <TableRow
                                                            hover
                                                            tabIndex={-1}
                                                            key={index} >
                                                            <ItemTableCell>
                                                                {store.increment_id}
                                                            </ItemTableCell>
                                                            <ItemTableCell>
                                                                {store.name}
                                                            </ItemTableCell>
                                                            <ItemTableCell align="right">
                                                                <NumberFormat
                                                                    value={unit_price}
                                                                    displayType="text"
                                                                    thousandSeparator={"."}
                                                                    decimalSeparator={","}
                                                                    prefix="Rp "
                                                                    isNumericString
                                                                    decimalScale={2}
                                                                
                                                                />
                                                            </ItemTableCell>
                                                            <ItemTableCell align="right">
                                                                <NumberFormat
                                                                    value={average_price}
                                                                    displayType="text"
                                                                    thousandSeparator={"."}
                                                                    decimalSeparator={","}
                                                                    prefix="Rp "
                                                                    isNumericString
                                                                    decimalScale={2}
                                                                
                                                                />
                                                            </ItemTableCell>
                                                            <ItemTableCell align="right">
                                                                <NumberFormat
                                                                    value={quantity}
                                                                    displayType="text"
                                                                    thousandSeparator={"."}
                                                                    decimalSeparator={","}
                                                                    isNumericString
                                                                    decimalScale={2}
                                                                />  {uom}
                                                            </ItemTableCell>
                                                            <ItemTableCell align="right">
                                                            {status ? (
                                                                <Chip
                                                                    label={status}
                                                                    size="small"
                                                                    sx={{
                                                                        backgroundColor: 
                                                                            status === "Habis" ? theme.palette.error.main :
                                                                            status === "Tersedia" ? theme.palette.success.main :
                                                                            status === "Menipis" ? theme.palette.warning.main :
                                                                            "default", // Warna default
                                                                        color: "#fff", // Warna teks putih untuk semua status
                                                                    }}
                                                                />
                                                            ) : null}
                                                            </ItemTableCell>
                                                            <ItemTableCell align="right">
                                                                <NumberFormat
                                                                    value={low_stock_notify}
                                                                    displayType="text"
                                                                    thousandSeparator={"."}
                                                                    decimalSeparator={","}
                                                                    isNumericString
                                                                    decimalScale={2}
                                                                />  {uom}
                                                            </ItemTableCell>
                                                            
                                                            
                                                            <ItemTableCell align="right">
                                                                <Typography style={{display: 'flex', justifyContent: "flex-end"}} onClick={onShowInventoryHistories.bind(this, item, store_id )} component="div">
                                                                    <Typography color="primary" fontWeight={500} fontSize={14} variant="body2" style={{textTransform: 'capitalize', cursor: 'pointer', marginTop: '3px'}}>
                                                                        Lihat
                                                                    </Typography>
                                                                </Typography>
                                                            </ItemTableCell>
                                                        </TableRow>
                                                    );
                                                })
                                            }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Collapse>
                            </Grid>
                        </Grid>
                    </Item>
                </div>
            </div>
        );
    };
    function Content() {
        if (inventories.length === 0) {
            return (
                <Grid item xs={12}>
                    <Box sx={{padding: "150px 35px"}}>
                        <Grid container alignItems="center" direction="column" justifyContent="space-between">
                            <Grid item xs>
                                <AssessmentTwoTone color="primary" style={{fontSize: 120}} />
                            </Grid>
                            <Grid item>
                                <Typography fontWeight={700} variant="h6" lineheight="normal">
                                    Tidak ada data.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            )
        }


        return (
            <Grid item xs={12}>
                <div style={{ flex: 1, left: '-2px', height: "82vh" }}>
                    <AutoSizer>
                    {({ height, width }) => (
                        <List
                        ref={listRef}
                        height={height}
                        width={width}
                        rowCount={inventories.length }
                        rowHeight={({ index }) => rowHeights[index].height || collapsedHeight}
                        onRowsRendered={({ stopIndex }) => {
                            loadMoreRows({ stopIndex });
                        }}
                        rowRenderer={rowRenderer}
                        overscanRowCount={5}
                        />
                    )}
                    </AutoSizer>
                </div>
            </Grid>
        )
    }

    return (
        <ContentBox>
            {/* <Header
                query={query}
                setQuery={setQuery}
                stores={stores} /> */}
            <Grid container spacing={3}>
                <Grid item xs={12} container spacing={3}>
                    <Grid item xs>
                        <ContentPaper elevation={3}>
                            <Typography sx={{marginBottom: '15px'}} variant="body2" fontSize={14} fontWeight={500} color="#444">
                                Est. Laba Penjualan
                            </Typography>
                            <Typography sx={{display: 'flex'}} variant="h6" component="div" fontSize={16} fontWeight={700} color="#444">
                                {inventories_summary ? formatNumber(inventories_summary.lowest_est_margins) : "Loading"} <Box sx={{margin: "0px 5px"}}>-</Box> {inventories_summary ? formatNumber(inventories_summary.highest_est_margins) : "Loading" }
                            </Typography>
                        </ContentPaper>                      
                    </Grid>
                    <Grid item xs>
                        <ContentPaper elevation={3}>
                            <Typography sx={{marginBottom: '15px'}} variant="body2" fontSize={14} fontWeight={500} color="#444">
                                Nilai Persediaan
                            </Typography>
                            {inventories_summary ? formatNumber(inventories_summary.inventories_value) : "Loading"}
                        </ContentPaper>                      
                    </Grid>
                    <Grid item xs>
                        <ContentPaper elevation={3}>
                            <Typography sx={{marginBottom: '15px'}} variant="body2" fontSize={14} fontWeight={500} color="#444">
                                Jumlah Kuantitas
                            </Typography>
                            {inventories_summary ? <NumberFormat
                                value={inventories_summary.inventories_quantity}
                                displayType="text"
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                isNumericString
                                decimalScale={2}
                                renderText={(value) => <Typography variant="h6" fontSize={16} fontWeight={700} color="#444">
                                {value}
                            </Typography>}/> : "Loading"}
                        </ContentPaper>                      
                    </Grid>
                    <Grid item xs>
                        <ContentPaper elevation={3}>
                            <Typography sx={{marginBottom: '15px'}} variant="body2" fontSize={14} fontWeight={500} color="#444">
                                Jumlah Jenis
                            </Typography>
                            {inventories_summary ? <NumberFormat
                                value={inventories_summary.inventories_size}
                                displayType="text"
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                isNumericString
                                decimalScale={0}
                                renderText={(value) => <Typography variant="h6" fontSize={16} fontWeight={700} color="#444">
                                {value}
                            </Typography>}/> : "Loading"}
                        </ContentPaper>                      
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Paper sx={{padding: "15px"}} elevation={3}>
                        <Grid container alignItems="center" justifyContent="space-between">
                            <Grid item>
                                <SearchField
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    onKeyDown={onSearch}>
                                    <SearchIconWrapper>
                                        <Search />
                                    </SearchIconWrapper>
                                    <StyledInputBase
                                    placeholder="Cari…"/>
                                </SearchField>
                            </Grid>
                            <Grid item>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                {Content()}
            </Grid>
            <PurchaseOrderHistory />
            <InventoryHistory />
            {/* <Paper sx={{ width: '100%', mb: 2, mt: '15px' }} elevation={3}>
                {Content()}
            </Paper> */}
        </ContentBox>
    )
}

const ContentBox = styled(Box)(({theme}) => ({
    padding: '25px 0',
    [theme.breakpoints.down('sm')]: {
        padding: '15px 15px 0 15px',
    },
    [theme.breakpoints.down('md')]: {
        padding: '15px 15px 0 15px',
    },
}));

const ContentPaper = styled(Paper)(({theme}) => ({
    padding: '25px',   
    display: 'flex',
    flexDirection: 'column'
}));

const SearchField = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    marginLeft: 0,
    width: '100%',
}));
  
const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));
  
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }));


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(3),
    // margin: 10,
    color: theme.palette.text.secondary,
}));

const ItemTableCell = styled(TableCell)(({theme}) => ({
    padding: '15px 16px'
}));


const Slash = styled(Typography)(({theme}) => ({
    margin: '0 10px',
    color: 'var(--N75, #E5E7E9)'
}));

const LossTypography = styled(Typography)(({theme}) => ({
    color: '#d50000',
    display: 'flex',
    alignItems: 'center'
}));

const GainTypography = styled(Typography)(({theme}) => ({
    color: '#008000',
    display: 'flex',
    alignItems: 'center'
}));