import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as orderCanceledActions from '../../../../../../actions/orderCanceledActions';
import { 
        Typography,
        Box,
        Grid,
        Paper,
        Divider,
        List,
        ListSubheader,
        TableRow,
        TableCell,
        TableHead,
        TableSortLabel,
        InputAdornment,
        ListItem,
        Tooltip,
        TextField,
        Button,
        InputBase
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { useNavigate, useParams } from "react-router-dom";
import Loader from '../../../../../Helper/Loader';
import { StickyNote2TwoTone, PublishedWithChangesTwoTone, CurrencyExchangeTwoTone, PaymentsTwoTone, AssignmentIndTwoTone, ReceiptTwoTone, MoveDownTwoTone, HelpTwoTone, AccessTimeTwoTone, LocalShippingTwoTone } from '@mui/icons-material';
import { green, red, grey } from '@mui/material/colors';
import { visuallyHidden } from '@mui/utils';
import { tooltipClasses } from '@mui/material/Tooltip';
import { keyframes } from '@emotion/react'
import NumberFormat from 'react-number-format';
import moment from 'moment';
import 'moment/locale/id';
import Confirm from './Confirm';
import Information from './Information';
import Product from './Product';

const QuantityFormat = React.forwardRef((props, ref) => (
    <NumberFormat
        {...props.other}
        onValueChange={(values) => {
          props.onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        disabled={props.disabled}
        customInput={TextField}
        placeholder={props.placeholder}
        value={props.value}
        helperText={props.helperText}
        error={props.error}
        onFocus={props.onFocus}
        decimalScale={2}
        fullWidth={props.fullWidth}
        onBlur={props.onBlur}
        thousandSeparator={"."}
        decimalSeparator={","}
        allowNegative={false}
        allowedDecimalSeparators={false}
        isNumericString
        InputProps={props.InputProps}
      />
));


const CurrencyFormat = React.forwardRef((props, ref) => (
    <NumberFormat
        {...props.other}
        customInput={TextField}
        InputProps={{
            startAdornment: <InputAdornment position="start">
                <Box fontWeight={700}>
                    Rp
                </Box>
            </InputAdornment>,
        }}
        placeholder="0"
        disabled={props.disabled}
        helperText={props.helperText}
        error={props.error}
        value={props.value}
        onFocus={props.onFocus}
        decimalScale={2}
        fullWidth={props.fullWidth}
        onBlur={props.onBlur}
        thousandSeparator={"."}
        decimalSeparator={","}
        allowNegative={false}
        allowedDecimalSeparators={false}
        isNumericString
      />
));

const PercentageFormat = React.forwardRef((props, ref) => (
    <NumberFormat
        {...props.other}
        onValueChange={(values) => {
          props.onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        customInput={TextField}
        InputProps={{
            startAdornment: <InputAdornment position="start">
                <Box fontWeight={700}>
                    {props.checked ? '%' : 'Rp' }
                </Box>
            </InputAdornment>
        }}
        disabled={props.disabled}
        placeholder="0"
        fullWidth={props.fullWidth}
        helperText={props.helperText}
        error={props.error}
        value={props.value}
        onFocus={props.onFocus}
        decimalScale={2}
        onBlur={props.onBlur}
        thousandSeparator={"."}
        decimalSeparator={","}
        allowNegative={false}
        allowedDecimalSeparators={false}
        isNumericString
      />
));

export default function Show() {    
    let navigate = useNavigate();
    let { id } = useParams();
    const dispatch = useDispatch();
    const {order_canceled, errors} = useSelector(state => ({
        ...state.order_canceled
    }), shallowEqual); 

    const [totalOpen, setTotalOpen] = React.useState(false);
    const [currentFocus, setCurrentFocus] = useState("");

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    },[errors]);

    useEffect(() => {
        dispatch(orderCanceledActions.onLoadOrderCanceled(id));
        return () => {
            dispatch(orderCanceledActions.onUnloadOrderCanceled())
        };
    },[]);

    const handleTotalClose = () => {
        setTotalOpen(false);
    };
    
    const handleTotalOpen = () => {
        setTotalOpen(true);
    };

    function onUpdateField(index, event) {

        if (currentFocus === "canceled") {
            const key = event.target.name;
            const value = event.target.value;
            const newOrderCanceled = {...order_canceled};
            const currentItem = newOrderCanceled.order_canceled_items[index];
            if (value <= currentItem.quantity) {
                currentItem[key] = value;
                currentItem.row_total = Number(currentItem.net_price) * Number(currentItem.canceled_qty);
            }
        
            onUpdateTotal(newOrderCanceled)
        }
    }

    function onUpdateNoteField(event) {
		// const key = event.target.name;
        // const value = event.target.value;
        // const newPurchaseOrder = {...purchase_order};
        // newPurchaseOrder[key] = value;
        // dispatch(purchaseOrderActions.onUpdatePurchaseOrder(newPurchaseOrder));
    }

    function onUpdateTotal(orderCanceled) {
        var { order, order_canceled_items, due_total, net_paid, net_grand_total, canceled_total, refunded_total } = orderCanceled;
        const orderedQty = order.ordered_qty - order.canceled_qty
        const cancelableQty = order_canceled_items.reduce((prev,next) => Number(prev) + Number(next.canceled_qty),0);
        const subtotal = getSubtotal(orderCanceled)
        // if (order.order_type === "invoice") {
            orderCanceled.subtotal = subtotal;
            if ((orderedQty - cancelableQty) === 0) {
                if (order.subtotal === (subtotal + refunded_total)) {
                    orderCanceled.rounding = order.rounding
                    orderCanceled.rounding_type = order.rounding_type
                    if (order.rounding_type == "loss") {
                        orderCanceled.grand_total = subtotal - order.rounding
                    } else {
                        orderCanceled.grand_total = subtotal + order.rounding
                    }
                } else {
                    orderCanceled.rounding_type = ""
                    orderCanceled.rounding = 0
                    orderCanceled.grand_total = subtotal
                }
            } else {
                orderCanceled.rounding_type = ""
                orderCanceled.rounding = 0
                orderCanceled.grand_total = subtotal
            }
        // } else {
        //     orderCanceled.subtotal = subtotal;
        //     if ((orderedQty - cancelableQty) === 0) {
        //         console.log("1")
        //         orderCanceled.rounding = order.rounding
        //         orderCanceled.rounding_type = order.rounding_type
        //         if (order.rounding_type === "loss") {
        //             orderCanceled.grand_total = subtotal - order.rounding
        //         } else {
        //             orderCanceled.grand_total = subtotal + order.rounding
        //         }
        //     } else {
        //         console.log("2")
        //         orderCanceled.rounding_type = ""
        //         orderCanceled.rounding = 0
        //         orderCanceled.grand_total = subtotal
        //     }
        // }
        dispatch(orderCanceledActions.onUpdateOrderCanceled(orderCanceled));

        // if ((totalOrderQty.minus(totalCanceledQty)).minus(totalCancelableQty).equals(0.0)) {
        //     rounding = order.rounding!!
        //     roundingType = order.rounding_type
        //     if (roundingType.equals("loss")) {
        //         grandTotal = subtotal.minus(rounding)
        //     } else {
        //         grandTotal = subtotal.plus(rounding)
        //     }
        // } else {
        //     grandTotal = subtotal
        // }
    }

    function getSubtotal(orderCanceled) {
        var { order, order_canceled_items, due_total, net_paid, net_grand_total, canceled_total, refunded_total } = orderCanceled;
        var subtotal = 0.0
        if (order.order_type === "invoice") {
            const newCanceledTotal = Number(order_canceled_items.reduce((prev,next) => Number(prev) + Number(next.row_total),0));
            
            var canceledTotal = (Number(newCanceledTotal) + Number(canceled_total));
            if (order.rounding_type === "loss") {
                canceledTotal = (Number(newCanceledTotal) + Number(canceled_total) - order.rounding)
            } else if (order.rounding_type === "gain") {
                canceledTotal = (Number(newCanceledTotal) + Number(canceled_total) + order.rounding)
            } 

            const refundedTotal = Number(refunded_total)
            const dueTotal = Number(due_total);
            const netPaid = Number(net_paid);
            const netGrandTotal = Number(net_grand_total);
            const netTotal = netGrandTotal - canceledTotal;

            if (dueTotal >= canceledTotal) {
                console.log("1")
                subtotal = 0
            } else if (dueTotal == 0.0 && !(netTotal === 0.0)) {
                console.log("2")
                subtotal = newCanceledTotal;
            } else if (dueTotal <= canceledTotal && !(netTotal === 0.0)) {
                console.log("333")
                if ((canceledTotal + netPaid - netGrandTotal) < 0.0) {
                    console.log("3")
                    subtotal = 0
                } else {
                    console.log("4")
                    subtotal = canceledTotal + netPaid - netGrandTotal;
                }
            } else if (dueTotal <= canceledTotal && netTotal === 0.0) {
                console.log("5")
                subtotal = netPaid - refundedTotal;
            } else if (canceledTotal === netGrandTotal) {
                console.log("6")
                subtotal = netPaid;
            } else {
                console.log("7")
                subtotal = newCanceledTotal;
            }

        } else {
            subtotal = order_canceled_items.reduce((prev,next) => Number(prev) + Number(next.row_total),0);
        }

        return subtotal
    }

    function onFocus(key) {
        setCurrentFocus(key)
    }

    function onBlur(key) {
        setCurrentFocus(null)
    }

    function onSubmit() {
        const { status } = order_canceled;
        if (status === 'processing') {
            dispatch(orderCanceledActions.onShowConfirm("received"))
        }
    }

    function error(key) {
        if (errors != null && errors[`order_canceled.${key}`] != null) {
            return errors[`order_canceled.${key}`].msg
        }
        return null;
    }

   
    if (!order_canceled) {
        return (
            <Loader />
        )
    }
    const { status, 
        increment_id, 
        store, 
        created_at, 
        status_title,
        note,
        reason_title,
        rounding,
        rounding_type,
        subtotal,
        grand_total, 
        order_canceled_items, 
    } = order_canceled;

    function Requested(item) {
        if (status === 'processing') {
            return (
                <QuantityFormat
                    fullWidth
                    name="quantity"
                    value={item.quantity}
                    disabled
                    variant="outlined"
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{item.uom}</InputAdornment>,
                    }}
                />
            )
        }

        return (
            <NumberFormat
                value={item.quantity}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator={","}
                isNumericString
                decimalScale={2}
                renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                {value} {item.uom}
                </Typography>}
            />
        )
    }

    function Canceled(item, index) {
        if (status === 'processing') {
            return (
                <QuantityFormat
                    fullWidth
                    error={!!error(`order_canceled_items[${index}]`)}
                    // helperText={error(`order_canceled_items[${index}]`)}
                    name="canceled_qty"
                    value={item.canceled_qty}
                    onFocus={onFocus.bind(this, "canceled")}
                    onBlur={onBlur.bind(this)}
                    variant="outlined"
                    onChange={onUpdateField.bind(this, index)}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{item.uom}</InputAdornment>,
                    }}
                />
            )
        }

        return (
            <NumberFormat
                value={item.canceled_qty}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator={","}
                isNumericString
                decimalScale={2}
                renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                    {value ? value : 0} {item.uom}
                </Typography>}
            />
        )
    }

    function UnitPrice(item) {
        if (status === 'processing') {
            return (
                <CurrencyFormat
                    name="unit_price"
                    fullWidth
                    disabled
                    value={item.unit_price}
                    inputProps={{min: 0, style: { textAlign: 'right' }}}
                    variant="outlined"
                />
            )
        }

        return (
            <NumberFormat
                value={item.unit_price}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix="Rp "
                isNumericString
                decimalScale={2}
                renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                {value}
                </Typography>}
            />
        )
    }

    function Discount(item) {
        if (status === 'processing') {
            return (
                <PercentageFormat
                    fullWidth
                    disabled
                    value={item.discount_type === 'percent' ? item.discount_percent : item.discount_amount}
                    inputProps={{min: 0, style: { textAlign: 'right' }}}
                    variant="outlined"
                />
            )
        }

        return (
            <NumberFormat
                value={item.discount_type === 'percent' ? item.discount_percent : item.discount_amount}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix={item.discount_type === 'percent' ? "" : "Rp "}
                suffix={item.discount_type === 'percent' ? "%" : ""}
                isNumericString
                decimalScale={2}
                renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                {value}
                </Typography>}
            />
        )
    }

    function NetPrice(item) {
        if (status === 'processing') {
            return (
                <CurrencyFormat
                    fullWidth
                    name="net_price"
                    value={item.net_price}
                    disabled
                    variant="outlined"
                />
            )
        }

        return (
            <NumberFormat
                value={item.net_price}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix="Rp "
                isNumericString
                decimalScale={2}
                renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                {value}
                </Typography>}
            />
        )
    }

    function Subtotal(item) {
        if (status === 'processing') {
            return (
                <CurrencyFormat
                    fullWidth
                    disabled
                    name="row_total"
                    value={item.row_total}
                    inputProps={{min: 0, style: { textAlign: 'right' }}}
                    variant="outlined"
                />
            )
        }

        return (
            <NumberFormat
                value={item.row_total}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix="Rp "
                isNumericString
                decimalScale={2}
                renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                {value}
                </Typography>}
            />
        )
    }

    function Cancel() {
        if (status === 'processing') {
            return (
                <Button 
                    variant="contained"
                    size="large"
                    disableElevation
                    onClick={ () => {dispatch(orderCanceledActions.onShowConfirm("canceled"))}}
                    >
                    Batalkan
                </Button>
            )
        }

        return null;
    }

    let titleAlign = 'right'
    if (status === 'processing') {
        titleAlign = 'left'
    }


    function total() {
        return (
            <Box>
                <Grid container spacing={1} direction="column">
                    <Grid item container justifyContent="flex-end">
                        <Grid item xs>
                            <Typography fontWeight={500}>
                                Subtotal
                            </Typography>
                        </Grid>
                        <Grid item>
                            <NumberFormat
                                value={subtotal}
                                displayType="text"
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix="Rp "
                                isNumericString
                                decimalScale={2}
                                renderText={(value) => <Typography fontWeight={500}>
                                {value}
                            </Typography>}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container justifyContent="flex-end">
                        <Grid item xs>
                            <Typography fontWeight={500}>
                                Biaya Lainnya
                            </Typography>
                        </Grid>
                        <Grid item>
                            <NumberFormat
                                value={rounding}
                                displayType="text"
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix="Rp "
                                isNumericString
                                decimalScale={2}
                                renderText={(value) => <Typography fontWeight={500}>
                                {rounding_type === "loss" ? "-" + value : value}
                            </Typography>}
                            />
                        </Grid>
                    </Grid>
                    <Grid item >
                        <Divider />
                    </Grid>
                    <Grid item container justifyContent="flex-end">
                        <Grid item xs>
                            <Typography fontWeight={700}>
                                Total Pengembalian
                            </Typography>
                        </Grid>
                        <Grid item>
                            <NumberFormat
                                value={grand_total}
                                displayType="text"
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix="Rp "
                                isNumericString
                                decimalScale={2}
                                renderText={(value) => <Typography fontWeight={700}>
                                {value}
                            </Typography>}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    return (
        <ContentBox>
            <HeaderBox>
                <Typography fontWeight={700} variant="h6">
                    Informasi Pembatalan
                </Typography>
            </HeaderBox>
            <Information />
            <Product error={error} />
            <Box>
                <Grid container sx={{marginBottom: '55px'}} justifyContent="space-between" spacing={3}>
                    <Grid item>
                        {Cancel()}
                    </Grid>
                    <Grid item>
                        <Grid container spacing={3}>
                            <Grid item>
                                <BackButton 
                                    variant="contained"
                                    size="large"
                                    disableElevation
                                    onClick={ () => {navigate(`/dashboard/order-canceleds`)}}>
                                    Kembali
                                </BackButton>
                            </Grid>
                            <Grid item>
                                <Button 
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    sx={{minWidth: 195}}
                                    onClick={onSubmit}
                                    disabled={status === 'canceled' || status === 'completed'}
                                    disableElevation>
                                    Simpan
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>  
            </Box>
            <Confirm />
        </ContentBox>
    )
}

const ContentPaper = styled(Paper)(({theme}) => ({
    marginBottom: 25
}));

const ContentBox = styled(Box)(({theme}) => ({
    padding: '25px 0',
    [theme.breakpoints.down('sm')]: {
    padding: '15px 15px 0 15px',
    },
    [theme.breakpoints.down('md')]: {
    padding: '15px 15px 0 15px',
    },
    height: '100%'
}));


const HeaderBox = styled(Box)(({theme}) => ({
    paddingLeft: 0,
    marginBottom: 25,
    [theme.breakpoints.down('sm')]: {
        paddingLeft: 55,
    },
}));

const Content = styled(Box)(({theme}) => ({
    paddingBottom: 15
}));

const Slash = styled(Typography)(({theme}) => ({
    margin: '0 6px',
    color: 'var(--N75, #E5E7E9)'
}));


const TimelineBox = styled(Box)(({theme}) => ({
    display: 'flex',
    minHeight: 60
 }));

const DateBox = styled(Box)(({theme}) => ({
    width: '20%',
    textAlign: 'right'
}));

const LineWrapperBox = styled(Box)(({theme}) => ({
   margin: '5px 16px',
   alignItems: 'center',
   flexDirection: 'column',
   display: 'flex'
}));

const grow = keyframes`
    from {
        opacity: 1;
        transform: scale(0);
    }
    to {
        opacity: 0;
        transform: scale(2);
    }
`;

const CurrentCircleBox = styled(Box)(({theme}) => ({
    width: '15px',
    height: '15px',
    borderRadius: '50%',
    position: 'relative',
    marginBottom: 'auto',
    '&:before': {
        width: 15,
        position: 'absolute',
        height: 15,
        borderRadius: '50%',
        backgroundColor: `var(--G300,#4B00D1)`,
        animationName:  `${grow}`,
        animationDuration: '1400ms',
        animationTimingFunction: 'ease-out',
        animationIterationCount: 'infinite',
        animationFillMode: 'none',
        animationPlayState: 'running',
        content: '""'
    }, 
    '&:after': {
        borderRadius: '50%',
        backgroundColor: 'var(--G500,#6200EE)',
        content: '""',
        position: 'absolute',
        width: 11,
        height: 11,
        left: 2,
        top: 2
    },
 }));


const CircleBox = styled(Box)(({theme}) => ({
    width: '15px',
    height: '15px',
    borderRadius: '50%',
    position: 'relative',
    marginBottom: 'auto',
    backgroundColor: 'var(--N100,#DBDEE2)',
    '&:after': {
        borderRadius: '50%',
        backgroundColor: 'var(--N200,#9FA6B0)',
        content: '""',
        position: 'absolute',
        width: '9px',
        height: '9px',
        left: '3px',
        top: '3px'
    },
 }));

const LineBox = styled(Box, {
    shouldForwardProp: (prop) => prop !== "last",
  })(({ theme, last }) => ({
    display: last ? 'none' : 'block',
    width: 1,
    height: 'calc(100% - 9px)',
    marginBottom: -6,
    backgroundImage: 'linear-gradient(to bottom,var(--N100,#DBDEE2) 55%,rgba(255,255,255,0) 55%)',
    backgroundPosition: 'left center',
    backgroundSize: '1px 12px',
    backgroundRepeat: 'repeat-y'
 }));

 const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} sx={{marginLeft: '5px'}} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      width: 325,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
      padding: 15
    },
  }));


const BackButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: '1px solid #ddd',
    minWidth: 195
}));


const CommentField = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    // [theme.breakpoints.up('sm')]: {
    //   marginLeft: theme.spacing(1),
    //   width: 'auto',
    // },
}));

const IconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.main
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }));