import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import * as productActions from '../../../../../../../../actions/productActions';
import { 
        Typography,
        Paper,
        Box,
        Grid,
        Button,
        Checkbox,
        TextField,
        InputAdornment,
        Switch,
        LinearProgress,
        Chip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {ImageRounded} from '@mui/icons-material';
import NumberFormat from 'react-number-format';
import Bulk from './Bulk';
import { List, AutoSizer } from "react-virtualized";


const CurrencyFormat = React.forwardRef((props, ref) => (
    <NumberFormat
        {...props.other}
        onValueChange={(values) => {
          props.onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        customInput={TextField}
        InputProps={{
            startAdornment: <InputAdornment position="start">
                <Box fontWeight={700}>
                    Rp
                </Box>
            </InputAdornment>,
        }}
        error={props.error}
        helperText={props.helperText}
        fullWidth={props.fullWidth}
        placeholder={props.placeholder}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        value={props.value}
        thousandSeparator={"."}
        decimalSeparator={","}
        allowNegative={false}
        decimalScale={0}
        isNumericString
      />
));

const SKUFormat = React.forwardRef((props, ref) => (
    <NumberFormat
        {...props.other}
        onValueChange={(values) => {
          props.onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        error={props.error}
        helperText={props.helperText}
        fullWidth={props.fullWidth}
        placeholder={props.placeholder}
        customInput={TextField}
        disabled={props.disabled}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        value={props.value}
        allowNegative={false}
        decimalScale={0}
        isNumericString
        allowedDecimalSeparators={false}
      />
));

const WeightFormat = React.forwardRef((props, ref) => (
    <NumberFormat
        {...props.other}
        onValueChange={(values) => {
          props.onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        error={props.error}
        helperText={props.helperText}
        placeholder={props.placeholder}
        value={props.value}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        customInput={TextField}
        thousandSeparator={"."}
        decimalSeparator={","}
        allowNegative={false}
        allowedDecimalSeparators={false}
        isNumericString
      />
));

export default function ProductVariant({product, autoSKU, error}) {
    const dispatch = useDispatch();
    const unitRefs = useRef([]); 
    // const unitRefs = React.useMemo(() => product.product_variants.map(i=> React.createRef()), []);

    const { product_variants } = product;
    const [selected, setSelected] = useState([]);
    const [currentFocus, setCurrentFocus] = useState("");

    useEffect(() => {
       setSelected([]);

    //    product_variants.map((variant, index) => {
    //         variant.product_images.map((image, imageIndex) => {
    //             if (image.image && image.onUpload) {
    //                 image.onUpload = false;
    //                 dispatch(productActions.onUploadVariant(index, image,  (event) => {
    //                     image.progress = Math.round((event.loaded * 100) / event.total);
    //                     image.onUploading = true;
    //                     dispatch(productActions.onUploadingVariant(index, image))
    //                 }));
    //             }
    //             if (image.onRemove) {
    //                 image.onRemove = false;
    //                 dispatch(productActions.onRemoveVariant(index, image, imageIndex));
    //             }
    //         });
    //    });
    }, [product]);

    function onChange(index, event) {
        if (currentFocus === 'sku' || currentFocus === 'price' || currentFocus === 'weight') {
            const key = event.target.name;
            const value = event.target.value;
            const newVariants = { ...product_variants[index], [key]: value};
            product_variants.splice(index, 1, newVariants);
            dispatch(productActions.onUpdateVariants(product_variants));
        }
    }
      
    function onToggled(index) {
        const newVariants = { ...product_variants[index], status: !product_variants[index].status};
	    product_variants.splice(index, 1, newVariants);
        dispatch(productActions.onUpdateVariants(product_variants));
    }

    function onEditConfiguration() {
        dispatch(productActions.onShowConfiguration());
    }

    function onSelect(sku) {
        var newSelected = [...selected];
        if (selected.find(o => o === sku)) {
            const itemIndex = selected.findIndex(o => o === sku)
            newSelected.splice(itemIndex, 1);
        } else {
            newSelected.push(sku);
        }
        
        setSelected(newSelected);
    }

    function onSelectImage(index, event) {
        event.preventDefault();
        var productVariants = [...product_variants];
        const files = event.target.files;
        let file = files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            const emptyData = productVariants[index].product_images.find(el => !el.image);
            if (emptyData) {
                productVariants[index].product_images.pop();
            }
            productVariants[index].product_images.map(image => {
                return image.onRemove = true;
            })
            productVariants[index].product_images.push({image: reader.result, onUpload: true });
            dispatch(productActions.onUpdateVariants(productVariants));
        }
        reader.readAsDataURL(file);
    }

    function onPressEnter(index, event) {
        const value = event.target.value;
        if(event.keyCode == 13 && !product_variants[index].uom){
            const newVariants = { ...product_variants[index], uom: value.toUpperCase()};
            product_variants.splice(index, 1, newVariants);
            dispatch(productActions.onUpdateVariants(product_variants));
        }
    }

    function onBlurUnit(index, event) {
        const value = event.target.value;
        if (!product_variants[index].uom) {
            const newVariants = { ...product_variants[index], uom: value.toUpperCase()};
            product_variants.splice(index, 1, newVariants);
            dispatch(productActions.onUpdateVariants(product_variants));
        }
    }

    function onDeleteUOM(index, event) {
        const newVariants = { ...product_variants[index], uom: ''};
        product_variants.splice(index, 1, newVariants);
        dispatch(productActions.onUpdateVariants(product_variants));
        setTimeout(function() {
            unitRefs.current[index].focus(); 
        }, 100);        
    }


    function onHandleChecked() {
        if (product_variants.filter(v => !v._destroy).length === selected.length) {
            setSelected([]);
        } else {
            var newSelected = [...selected];
            product_variants.filter(v => !v._destroy).map(variant => {
                if (!selected.includes(variant.sku)) {
                    return newSelected.push(variant.sku);
                }
            })
            setSelected(newSelected);
        }
       
    }

    function onFocus(key) {
        setCurrentFocus(key);
    }

    function onBlur(key) {
        setCurrentFocus(null);
    }

    function variantImage(images, index) {
        const data = images[images.length - 1]
        if (!data || !data.image) {
            return (
                <ImgBox error={!!error(`product_variants[${index}].product_images`)}>
                    <ImageRounded
                        style={{ color: '#999', fontSize: 20 }} />
                </ImgBox>
            )
        }
        if (data.onUploading) {
            return (
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                    <ImagedBox component="img" alt={data.image} src={data.image} />
                    <Box sx={{ width: '85%', position: 'absolute', bottom: '5px' }}>
                        <LinearProgress sx={{height: '8px', borderRadius: '35px'}} variant="determinate" value={data.progress || 0} />
                    </Box>
                </Box>
            )
        }

        return (
            <ImagedBox component="img" alt={data.image} src={data.image} />
        )

        
    }
    function variantAttributes(attributes) {
        const variants = []
        Object.keys(attributes).map((key, i) => {
            return variants.push({
                label: key.charAt(0).toUpperCase() + key.slice(1), 
                value: attributes[key].charAt(0).toUpperCase() + attributes[key].slice(1), 
                sort_order: i
            })
        })
        
        return variants.sort((a, b) => a.sort_order - b.sort_order).map((v, i) => {
            if (i === variants.length - 1) {
                return (
                    <Typography key={i} fontSize={14} fontWeight={500} component="div">
                        {v.label}:  {v.value}
                    </Typography>
                )
            } else {
                return (
                    <Typography key={i} fontSize={14} fontWeight={500} component="div">
                        {v.label}:  {v.value}, 
                    </Typography>
                )
            }
        });
    }

    const rowRenderer = ({ index, key, style }) => {
        const variant = product_variants.sort((a, b) => a.sort_order - b.sort_order)[index];
        if (variant._destroy) return null;
    
        return (
          <div key={key} style={{...style, left: '16px'}}>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
              <Grid item xs={1}>
                <Checkbox
                  onChange={() => onSelect(variant.sku)}
                  checked={!!selected.find(o => o === variant.sku)}
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              </Grid>
              <Grid item xs={2}>
                <SKUFormat
                  fullWidth
                  error={!!error(`product_variants[${index}].sku`)}
                  name="sku"
                  placeholder="SKU"
                  value={variant.sku}
                  onFocus={() => onFocus("sku")}
                  onBlur={onBlur}
                  variant="outlined"
                  onChange={(e) => onChange(index, e)}
                  disabled={autoSKU}
                />
              </Grid>
              <Grid item xs>
                <CurrencyFormat
                  fullWidth
                  error={!!error(`product_variants[${index}].price`)}
                  name="price"
                  placeholder="0"
                  value={variant.price}
                  onFocus={() => onFocus("price")}
                  onBlur={onBlur}
                  variant="outlined"
                  onChange={(e) => onChange(index, e)}
                />
              </Grid>
              <Grid item xs={1}>
                <WeightFormat
                  error={!!error(`product_variants[${index}].weight`)}
                  name="weight"
                  placeholder="Berat"
                  value={variant.weight}
                  onFocus={() => onFocus("weight")}
                  onBlur={onBlur}
                  variant="outlined"
                  onChange={(e) => onChange(index, e)}
                />
              </Grid>
              <Grid item xs={2}>
                {!!variant.uom ? (
                  <Chip
                    tabIndex={-1}
                    size="medium"
                    label={variant.uom}
                    sx={{ marginRight: '5px' }}
                    onDelete={() => onDeleteUOM(index)}
                    variant="outlined"
                  />
                ) : (
                  <TextField
                    error={!!error(`product_variants[${index}].uom`)}
                    placeholder="Satuan"
                    fullWidth
                    inputRef={(ref) => (unitRefs.current[index] = ref)}
                    variant="outlined"
                    onKeyDown={(e) => onPressEnter(index, e)}
                    onBlur={(e) => onBlurUnit(index, e)}
                  />
                )}
              </Grid>
              <Grid item xs={1}>
                <Switch
                  checked={variant.status}
                  onChange={() => onToggled(index)}
                />
              </Grid>
              <Grid item xs={2}>
                {variantAttributes(variant.variant_attributes)}
              </Grid>
            </Grid>
          </div>
        );
    };

    const rowHeight = 80
    const height =
    product_variants.length > 12
    ? 950
    : product_variants.length * rowHeight;

    return (
        <ContentPaper elevation={3} direction="column">
            <Grid container spacing={6} direction="column">
                <Grid item container>
                    <Grid item xs>
                        <Typography fontWeight={700} variant="h6">
                            Varian Produk
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            Varian produk yang telah dibuat, masukan gambar, harga, berat, dan ubah status varian produk untuk produk varian yang telah dibuat.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <EditButton 
                            variant="contained"
                            size="large"
                            disableElevation
                            onClick={onEditConfiguration}
                            >
                            Ubah Varian
                        </EditButton>
                    </Grid>
                </Grid>
                <Grid item container direction="column" spacing={5}>
                    <Grid item>
                        <Bulk onChecked={onHandleChecked} selected={selected} setSelected={setSelected} />
                    </Grid>
                    <Grid item container spacing={2} >
                        <Grid item xs={1}>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography fontSize={14} fontWeight={500} component="div">
                                SKU
                            </Typography>
                        </Grid>
                        <Grid item xs style={{paddingLeft: '20px'}}>
                            <Typography fontSize={14} fontWeight={500} component="div">
                                Harga
                            </Typography>
                        </Grid>
                        <Grid item xs={1} style={{paddingLeft: '25px'}}>
                            <Typography fontSize={14} fontWeight={500} component="div">
                                Berat
                            </Typography>
                        </Grid>
                        {/* <Grid item xs={1}>
                            <Typography fontSize={14} fontWeight={500} component="div">
                                Foto
                            </Typography>
                        </Grid> */}
                        <Grid item xs={2} style={{paddingLeft: '27px'}}>
                            <Typography fontSize={14} fontWeight={500} component="div">
                                UOM
                            </Typography>
                        </Grid> 
                        <Grid item xs={1} style={{paddingLeft: '35px'}}>
                            <Typography fontSize={14} fontWeight={500} component="div">
                                Status 
                            </Typography>
                        </Grid> 
                        <Grid item xs={2} style={{paddingLeft: '30px'}}>
                            <Typography fontSize={14} fontWeight={500} component="div">
                                Attributes
                            </Typography>
                        </Grid> 
                    </Grid>
                    <Grid item container spacing={2}>
                  
                        <List
                            width={window.innerWidth}// Adjust width based on your layout
                            height={height} // Adjust height to fit the visible area
                            rowCount={product_variants.length}
                            rowHeight={rowHeight} // Adjust based on the height of each row
                            rowRenderer={rowRenderer}
                            overscanRowCount={5} // Pre-render extra rows for smoother scrolling
                            />
                    </Grid>
                </Grid>
            </Grid>
            
        </ContentPaper>
    )
}

const ContentPaper = styled(Paper)(({theme}) => ({
    padding: 35,
    paddingTop: 32,
    marginBottom: 25
}));

const ImgPaper = styled(Paper)(({theme}) => ({
    padding: 5,
    width: 46,
    height: 46,
    position: 'relative',
    '& input[type="file"]': {
        bottom: 0,
        cursor: 'pointer',
        fontSize: '10em',
        height: '100%',
        left: 0,
        opacity: 0,
        position: 'absolute',
        right: 0,
        top: 0,
        width: '100%',
        zIndex: 3
    }
}));

const ImgBox = styled(Box, {
    shouldForwardProp: (prop) => prop !== "error",
  })(({ theme, error }) => ({
    border: error ? '1px dashed #f44336' : '1px dashed #999',
    height: 44,
    width: 44,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 3,
    position: 'relative',
}));

const ImagedBox = styled(Box)(({theme}) => ({
    width: 42,
    height: 42,
    padding: 2,
    position: 'relative'
}));

const EditButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: `1px solid #ddd`
}));
