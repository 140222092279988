import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as orderDeliveryActions from '../../../../../../actions/orderDeliveryActions';
import { 
        Typography,
        Box,
        Paper,
        Grid,
        Divider,
        TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { PublishedWithChangesTwoTone, AccessTimeTwoTone } from '@mui/icons-material';
import { green, red, grey } from '@mui/material/colors';
import moment from 'moment';
import 'moment/locale/id' 

export default function Information({error}) {    
    const dispatch = useDispatch();
    const {order_delivery} = useSelector(state => ({
        ...state.order_delivery
    }), shallowEqual); 

    const [currentFocus, setCurrentFocus] = useState("");

    const { 
        status,
        increment_id, 
        store, 
        created_at, 
        status_title,
        driver,
        license_plate
    } = order_delivery;


    function onUpdateField(index, event) {
        if (currentFocus === "driver") {
            const key = event.target.name;
            const value = event.target.value;
            const newOrderDelivery = {...order_delivery};
            const currentItem = newOrderDelivery.order_delivery_items[index];
            currentItem[key] = value;
            dispatch(orderDeliveryActions.onUpdateOrderDelivery(newOrderDelivery));
        }
    }


    function onUpdatePlateField(event) {
        if (currentFocus === "plate") {
            const key = event.target.name;
            const value = event.target.value;
            const newOrderDelivery = {...order_delivery};
            newOrderDelivery[key] = value;
            dispatch(orderDeliveryActions.onUpdateOrderDelivery(newOrderDelivery));
        }
    }

    function onFocus(key) {
        setCurrentFocus(key)
    }

    function onBlur(key) {
        setCurrentFocus(null)
    }

    function Driver() {
        if (status === 'on_delivery') {
            return (
                <TextField
                    fullWidth
                    error={!!error('driver')}
                    helperText={error('driver')}
                    name="driver"
                    value={driver}
                    variant="outlined"
                    placeholder="Pengemudi"
                    onFocus={onFocus.bind(this, "driver")}
                    onBlur={onBlur.bind(this)}
                    onChange={onUpdateField}
                />
            )
        }

        return (
            <Box>
                {/* {invoice_id ? invoice_id : "-"} */}
            </Box>
          
        )
    }

    function Plate() {
        if (status === 'on_delivery') {
            return (
                <TextField
                    fullWidth
                    error={!!error('license_plate')}
                    helperText={error('license_plate')}
                    name="license_plate"
                    value={license_plate}
                    variant="outlined"
                    placeholder="Plat No."
                    onFocus={onFocus.bind(this, "plate")}
                    onBlur={onBlur.bind(this)}
                    onChange={onUpdatePlateField}
                />
            )
        }

        return (
            <Box>
                {/* {invoice_id ? invoice_id : "-"} */}
            </Box>
          
        )
    }
 
    return (
        <ContentPaper elevation={3}>
            <Box sx={{display: 'flex', padding: '35px', paddingTop: '25px', paddingBottom: '25px'}}>
                <Typography fontWeight={700} sx={{display: 'flex'}}>
                    <PublishedWithChangesTwoTone style={{ fontSize: 18, marginRight: '5px', marginTop: '3px' }} color="primary" /> {status_title}
                </Typography>
                <Slash>
                    /
                </Slash>
                <Typography fontWeight={700} color="primary">
                    {increment_id}
                </Typography>
                {/* {customer_name && [<Slash key={1}>
                    /
                </Slash>,
                <Typography key={2} fontWeight={700} sx={{display: 'flex'}}>
                    <AssignmentIndTwoTone style={{ fontSize: 18, marginRight: '5px', marginTop: '3px', color: green[600] }} /> {customer_name}
                </Typography>]} */}
                <Slash>
                    /
                </Slash>
                <Typography sx={{display: 'flex'}}>
                    <AccessTimeTwoTone style={{ fontSize: 18, marginRight: '5px', marginTop: '3px' }} color="primary" /> {moment(created_at).format('LL')}, {moment(created_at).format('LT')} WIB
                </Typography>
            </Box>
            <Box>
                <Divider />
            </Box>
            <Box sx={{padding: '35px'}}>
                <Grid container spacing={3}>
                    <Grid item xs={3} container direction="column">
                        <Grid item>
                            <Typography color={grey[500]} fontWeight={500} fontSize={13} variant="body1">
                                Pengemudi
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography fontWeight={700} fontSize={16} variant="h6">
                                {Driver()}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={3} container direction="column">
                        <Grid item>
                            <Typography color={grey[500]} fontWeight={500} fontSize={13} variant="body1">
                                Plat No.
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography fontWeight={700} fontSize={16} variant="h6">
                                {Plate()}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={3} container direction="column">
                        <Grid item>
                            <Typography color={grey[500]} fontWeight={500} fontSize={13} variant="body1">
                                Cabang
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography fontWeight={700} sx={{display: 'flex'}}>
                                {store}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={3} container direction="column">
                        <Grid item>
                            <Typography color={grey[500]} fontWeight={500} fontSize={13} variant="body1">
                                Aktivitas
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography gutterBottom variant="h6" color="primary" fontSize={14} style={{textTransform: 'capitalize', cursor: 'pointer'}}>
                                Lihat Aktivitas
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </ContentPaper>
    )
}

const ContentPaper = styled(Paper)(({theme}) => ({
    marginBottom: 25
}));


const Slash = styled(Typography)(({theme}) => ({
    margin: '0 6px',
    color: 'var(--N75, #E5E7E9)'
}));