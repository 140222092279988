import React, { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as purchaseOrderReturnActions from '../../../../../../actions/purchaseOrderReturnActions';
import { 
        Typography,
        Box,
        Grid,
        Paper,
        Divider
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { PublishedWithChangesTwoTone, AccessTimeTwoTone } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import moment from 'moment';
import 'moment/locale/id' 

export default function Information() { 
    const {purchase_order_return, errors} = useSelector(state => ({
        ...state.purchase_order_return
    }), shallowEqual); 

  
    const { status, increment_id, purchase_order_id, status_title, created_at, store_name, supplier_name } = purchase_order_return;

    return (
        <ContentPaper elevation={3}>
            <Box sx={{display: 'flex', padding: '35px', paddingTop: '25px', paddingBottom: '25px'}}>
                <Typography fontWeight={700} sx={{display: 'flex'}}>
                    <PublishedWithChangesTwoTone style={{ fontSize: 18, marginRight: '5px', marginTop: '3px' }} color="primary" /> {status_title}
                </Typography>
                <Slash>
                    /
                </Slash>
                <Typography fontWeight={700} color="primary">
                    {increment_id}
                </Typography>
                <Slash>
                    /
                </Slash>
                <Typography fontWeight={700}>
                    {supplier_name}
                </Typography>
                <Slash>
                    /
                </Slash>
                <Typography sx={{display: 'flex'}}>
                    <AccessTimeTwoTone style={{ fontSize: 18, marginRight: '5px', marginTop: '3px' }} color="primary" /> {moment(created_at).format('LL')}, {moment(created_at).format('LT')} WIB
                </Typography>
            </Box>
            <Box>
                <Divider />
            </Box>
            <Box sx={{padding: '35px'}}>
                <Grid container spacing={3}>
                    <Grid item xs={3} container direction="column">
                        <Grid item>
                            <Typography color={grey[500]} fontWeight={500} fontSize={13} variant="body1">
                                No. Pembelian
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography fontWeight={700} fontSize={16} variant="h6">
                               {purchase_order_id}
                            </Typography>
                        </Grid>
                    </Grid>
                    {/* <Grid item xs={3} container direction="column">
                        <Grid item>
                            <Typography color={grey[500]} fontWeight={500} fontSize={13} variant="body1">
                                Jatuh Tempo {status === 'ordered' || status === 'received' ? dueDays() : null}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography fontWeight={700} fontSize={16} variant="h6">
                                {DueDate()}
                            </Typography>
                        </Grid>
                    </Grid> */}
                    <Grid item xs={3} container direction="column">
                        <Grid item>
                            <Typography color={grey[500]} fontWeight={500} fontSize={13} variant="body1">
                                Cabang
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography fontWeight={700} sx={{display: 'flex'}}>
                                {store_name}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={3} container direction="column">
                        <Grid item>
                            <Typography color={grey[500]} fontWeight={500} fontSize={13} variant="body1">
                                Aktivitas
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography gutterBottom variant="h6" color="primary" fontSize={14} style={{textTransform: 'capitalize', cursor: 'pointer'}}>
                                Lihat Aktivitas
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </ContentPaper>
    )
}

const ContentPaper = styled(Paper)(({theme}) => ({
    marginBottom: 25
}));


const Slash = styled(Typography)(({theme}) => ({
    margin: '0 6px',
    color: 'var(--N75, #E5E7E9)'
}));