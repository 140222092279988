import React, { useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { 
    Typography,
    Box,
    Grid,
    Paper
} from '@mui/material';
import Chart from 'chart.js/auto';
import NumberFormat from 'react-number-format';
import { styled } from '@mui/material/styles';
import 'chartjs-adapter-date-fns';

export default function HistoryChart({data, item}) { 
    useEffect(() => {
      const timestamps = data.map(d => new Date(d.date).getTime());
      const maxDate = Math.max(...timestamps);
  
      const MIN_BUBBLE_SIZE = 5; // Minimum bubble size

      const BUFFER_FACTOR = 0.20; 

      // Calculate the buffer for positive and negative ranges
      const maxStockIn = Math.max(...data.map(d => d.total_stock_in));
      const minStockOut = Math.min(...data.map(d => -d.total_stock_out));

      const BUFFER_MINUS = Math.abs(minStockOut) * BUFFER_FACTOR; // Buffer for negative values
      const BUFFER_PLUS = maxStockIn * BUFFER_FACTOR;             // Buffer for positive values;

      // Calculate minY (negative values only)
      const minY = Math.min(
          ...data.map(d => -d.total_stock_out),
          ...data.map(d => d.total_adjustment)
      ) - BUFFER_MINUS;

      // Calculate maxY (positive values only)
      const maxY = Math.max(
          ...data.map(d => d.total_stock_in),
          ...data.map(d => d.total_adjustment)
      ) + BUFFER_PLUS;
  
      const historyChart = new Chart(document.getElementById("history-chart"), {
          type: 'bubble',
          data: {
              datasets: [
                  {
                      label: "Stok Masuk",
                      data: data.filter(d => d.total_stock_in !== 0).map(d => ({
                          x: new Date(d.date).getTime(),
                          y: d.total_stock_in,
                          r: Math.min(Math.max(Math.sqrt(Math.abs(d.total_stock_in)), MIN_BUBBLE_SIZE), 20), // Cap the size at 30
                          custom: {
                              total_quantity: d.total_stock_in,
                              date: d.date,
                          },
                      })),
                      backgroundColor: "rgba(0, 105, 255, 0.5)", // Blue color with 20% opacity
                      borderColor: "#0069ff",
                  },
                  {
                      label: "Stok Keluar",
                      data: data.filter(d => d.total_stock_out !== 0).map(d => ({
                          x: new Date(d.date).getTime(),
                          y: -d.total_stock_out, // Use negative values
                          r: Math.min(Math.max(Math.sqrt(Math.abs(d.total_stock_out)), MIN_BUBBLE_SIZE), 20), // Cap the size at 30
                          custom: {
                              total_quantity: -d.total_stock_out, // Keep as negative for tooltip
                              date: d.date,
                          },
                      })),
                      borderColor: "#03AC0E",
                      backgroundColor: "rgba(3, 172, 14, 0.5)", // Green color with 20% opacity
                  },
                  {
                      label: "Penyesuaian",
                      data: data.filter(d => d.total_adjustment !== 0).map(d => ({
                          x: new Date(d.date).getTime(),
                          y: d.total_adjustment,
                          r: Math.min(Math.max(Math.sqrt(Math.abs(d.total_adjustment)), MIN_BUBBLE_SIZE), 20), // Cap the size at 30
                          custom: {
                              total_quantity: d.total_adjustment,
                              date: d.date,
                          },
                      })),
                      borderColor: "#FF8432",
                      backgroundColor: "rgba(255, 132, 50, 0.5)", // Orange color with 20% opacity
                  },
              ],
          },
          options: {
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                  zoom: {
                      pan: {
                          enabled: true,
                          mode: 'x',
                      },
                      zoom: {
                          wheel: { enabled: true },
                          pinch: { enabled: true },
                          mode: 'x',
                      },
                  },
                  tooltip: {
                      callbacks: {
                          label: function (context) {
                              const { custom } = context.raw;
                              const value = custom.total_quantity;
  
                              // Format custom.date ke dd/MM/yyyy
                              const formattedDate = new Intl.DateTimeFormat('id-ID', {
                                  day: '2-digit',
                                  month: '2-digit',
                                  year: 'numeric',
                              }).format(new Date(custom.date));

                              // Tentukan prefix
                              const prefix = value > 0 ? '+' : value < 0 ? '-' : '';
                              const labelText = `${prefix}${Math.abs(value)} ${item.uom}`;

                              return [
                                  `${formattedDate}`, // Gunakan tanggal yang sudah diformat
                                  `Kuantitas: ${labelText}`,
                              ];
                          },
                      },
                  },
              },
              scales: {
                x: {
                    type: 'time',
                    time: {
                        unit: 'month', // Ubah unit ke bulan
                        tooltipFormat: 'MMM yyyy', // Format tooltip untuk menampilkan nama bulan dan tahun
                        displayFormats: {
                            month: 'MMM yyyy', // Format untuk tampilan bulan
                        },
                    },
                    min: new Date(maxDate).setMonth(new Date(maxDate).getMonth() - 6), // 6 bulan sebelumnya
                    max: maxDate, // Tanggal saat ini atau tanggal terakhir dalam data
                },
                y: {
                    min: minY,
                    max: maxY,
                },
            },
          },
      });
  
      return () => {
          historyChart.destroy();
      };
  }, [data]);

  

    return (
        <ContentPaper elevation={3}>
            <Grid container spacing={3} direction="column">
                <Grid item container>
                    <Grid item xs>
                        <Typography variant="body2" fontSize={16} fontWeight={500} color="#444">
                            Statistik Persediaan
                        </Typography>
                    </Grid>
                    <Grid item>
                    </Grid>
                </Grid>
                <Grid item>
                     <canvas id="history-chart" height="300" width="100%"></canvas>
                </Grid>
            </Grid>
        </ContentPaper>
       
    )
}

const ContentPaper = styled(Paper)(({theme}) => ({
    padding: '35px 25px',
    paddingTop: 20,
    // marginBottom: 15
}));