import * as types from '../actions/actionTypes';

const defaultState = {
}

export default function (state = defaultState, action) {
	switch(action.type) {
		case types.ORDERS_LOADED:
			return {
				...state,
				stores: action.payload ? action.payload.stores : null,
				orders: action.payload ? action.payload.orders : null,
				orders_count: action.payload ? action.payload.orders_count : null,
            };
			case types.ORDER_LOADED:
				return {
					...state,
					order: action.payload ? action.payload.order : null,
					onOrderCanceleds: false,
					onOrderPayments: false,
					onOrderDeliveries: false,
					onOrderConfirm: false,
				};
			case types.ORDER_UNLOADED:
				return {
					...state,
					order: null
				};
			case types.ORDER_PAYMENTS_SHOWED:
				return {
					...state,
					onOrderPayments: true
				};
			case types.ORDER_PAYMENTS_HIDED:
				return {
					...state,
					onOrderPayments: false,
					payments: null
				}; 
			case types.ORDER_PAYMENTS_LOADED:
				return {
					...state,
					payments: action.payload ? action.payload.payments : null,
				}; 
			case types.ORDER_PAYMENTS_COLLAPSED:
				return {
					...state,
					payments: action.payments
				}; 
			case types.ORDER_CANCELEDS_SHOWED:
				return {
					...state,
					onOrderCanceleds: true
				};
			case types.ORDER_CANCELEDS_HIDED:
				return {
					...state,
					onOrderCanceleds: false,
					order_canceleds: null
				}; 
			case types.ORDER_CANCELEDS_LOADED:
				return {
					...state,
					order_canceleds: action.payload ? action.payload.order_canceleds : null,
				}; 
			case types.ORDER_CANCELEDS_COLLAPSED:
				return {
					...state,
					order_canceleds: action.order_canceleds
				}; 
			case types.ORDER_DELIVERIES_SHOWED:
				return {
					...state,
					onOrderDeliveries: true
				};
			case types.ORDER_DELIVERIES_HIDED:
				return {
					...state,
					onOrderDeliveries: false,
					rorder_deliveries: null
				}; 
			case types.ORDER_DELIVERIES_LOADED:
				return {
					...state,
					order_deliveries: action.payload ? action.payload.order_deliveries : null,
				}; 
			case types.ORDER_DELIVERIES_COLLAPSED:
				return {
					...state,
					order_deliveries: action.order_deliveries
				}; 
			case types.ORDER_UPDATED:
				return {
					...state,
					order: action.order
				};
			case types.ORDER_CONFIRM_SHOWED:
				return {
					...state,
					onOrderConfirm: true,
					actionType: action.action_type
				};
			case types.ORDER_CONFIRM_HIDED:
				return {
					...state,
					onOrderConfirm: false,
					actionType: null
				};
			case types.ORDER_EDITED:
				return {
					...state,
					errors: action.error ? action.payload.errors : null,
					inProgress: false,
					onOrderConfirm: action.error ? false : true,
				}; 
		default:
			return state;
	}
}