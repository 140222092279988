import React from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { 
        Typography,
        Box,
        Paper,
        Grid,
        Divider,
        Tooltip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { PaymentsTwoTone, AssignmentIndTwoTone, ReceiptTwoTone, AccessTimeTwoTone, LocalShippingTwoTone } from '@mui/icons-material';
import { green, red, grey } from '@mui/material/colors';
import moment from 'moment';
import 'moment/locale/id' 

export default function Information({error}) {    
    const dispatch = useDispatch();
    const {order} = useSelector(state => ({
        ...state.order
    }), shallowEqual); 
    const { status_title, 
        increment_id, 
        store, 
        created_at, 
        due_date, 
        customer, 
        order_type,
    } = order;




    function orderType() {
        if (order_type === 'invoice') {
            return (
                <Typography fontWeight={700} sx={{display: 'flex'}}>
                    <ReceiptTwoTone style={{ fontSize: 18, marginRight: '5px', marginTop: '3px' }} color="primary" /> {status_title}
                </Typography>
            )
        } else if (order_type === 'shipping') {
            return (
                <Typography fontWeight={700} sx={{display: 'flex'}}>
                    <LocalShippingTwoTone style={{ fontSize: 18, marginRight: '5px', marginTop: '3px' }} color="primary" /> {status_title}
                </Typography>
            )
        } else {
            return (
                <Typography fontWeight={700} sx={{display: 'flex'}}>
                    <PaymentsTwoTone style={{ fontSize: 18, marginRight: '5px', marginTop: '3px' }} color="primary" /> {status_title}
                </Typography>
            )
        }
    }
    return (
        <ContentPaper elevation={3}>
            <Box sx={{display: 'flex', padding: '35px', paddingTop: '25px', paddingBottom: '25px'}}>
                {orderType()}
                    <Slash>
                        /
                    </Slash>
                    <Typography fontWeight={700} color="primary">
                        {increment_id}
                    </Typography>
                    {customer && [
                        <Slash key={1}>
                        /
                        </Slash>,
                         <Tooltip
                         key={2}
                         title={
                            <Box>
                                <Typography fontWeight={700}>
                                    {customer.first_name} {customer.last_name}
                                </Typography>
                                <Typography fontWeight={400}>
                                    {customer.phone}
                                </Typography>
                                <Typography fontWeight={400}>
                                    {customer.order_customer_address.address}
                                </Typography>
                                <Typography fontWeight={400}>
                                    {customer.order_customer_address.state}, {customer.order_customer_address.city}, {customer.order_customer_address.district}, {customer.order_customer_address.code_area} 
                                </Typography>
                            </Box>
                         }
                         arrow
                     >
                             <Typography fontWeight={700} sx={{display: 'flex'}}>
                                <AssignmentIndTwoTone style={{ fontSize: 18, marginRight: '5px', marginTop: '3px', color: green[700] }} /> {customer.first_name} {customer.last_name}
                            </Typography>
                        </Tooltip>
                       
                    ]}
                    <Slash>
                        /
                    </Slash>
                    <Typography sx={{display: 'flex'}}>
                        <AccessTimeTwoTone style={{ fontSize: 18, marginRight: '5px', marginTop: '3px' }} color="primary" /> {moment(created_at).format('LL')}, {moment(created_at).format('LT')} WIB
                    </Typography>
            </Box>
            <Box>
                <Divider />
            </Box>
            <Box sx={{padding: '35px'}}>
                <Grid container>
                    {due_date && <Grid item xs={3} container direction="column">
                            <Grid item>
                                <Typography color={grey[500]} fontWeight={500} fontSize={13} variant="body1">
                                    Jatuh  Tempo
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography fontWeight={700} sx={{display: 'flex'}}>
                                    {moment(due_date).format('LL')}
                                </Typography>
                            </Grid>
                        </Grid>}
                    <Grid item xs={3} container direction="column">
                        <Grid item>
                            <Typography color={grey[500]} fontWeight={500} fontSize={13} variant="body1">
                                Cabang
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography fontWeight={700} sx={{display: 'flex'}}>
                                {store}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={3} container direction="column">
                        <Grid item>
                            <Typography color={grey[500]} fontWeight={500} fontSize={13} variant="body1">
                                Aktivitas
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography gutterBottom variant="h6" color="primary" fontSize={14} style={{textTransform: 'capitalize', cursor: 'pointer'}}>
                                Lihat Aktivitas
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </ContentPaper>
    )
}

const ContentPaper = styled(Paper)(({theme}) => ({
    marginBottom: 25
}));


const Slash = styled(Typography)(({theme}) => ({
    margin: '0 6px',
    color: 'var(--N75, #E5E7E9)'
}));